import StandardButton from "components/common/StandardButton";
import Asset from "models/asset";
import CreationPreview from "modules/smart/components/AssetPreview/CreationPreview";
import React, { Suspense, useEffect, useImperativeHandle, useMemo, useState } from "react";
import useAPI, { callAPIProps, ConvertErrorsToMapOfFields } from "services/hooks/useAPI";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import { BaseCreationType, CreationSubtype, CreationType, CreationTypeConfig, CreationTypeConfigByType, CreationTypeFromCreation } from "./CreationConfig";
import { Creation, CreationContent, creationContentSelector, creationSelector, patchCreation, validateCreation } from "./creationsSlice";
import { GetEditorConfigByType } from "./EditorsConfig";
import CreationMainActions from "./editorTools/CreationMainActions";
import ValidationResults from "./editorTools/ValidationResults";
import FormattedMessage from "components/common/FormattedMessage";
import CreationDraftQueue from "./draftQueue/CreationDraftQueue";
import { Drawer, Tooltip } from "antd";
import { creationErrorsSelector } from "./creationHelpers";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { faList } from "@fortawesome/pro-light-svg-icons";
import { AIComments } from "./aiComments/AIComments";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";

type PartialAsset = Partial<Asset>;

export type CreationEditorProps = {
    creationId?: number;
    createCall?: callAPIProps;
    updateCall?: callAPIProps;
    deleteCall?: callAPIProps;
    onAssetCreated?: (asset: Asset) => void;
    onAssetUpdated?: (asset: Asset) => void;
    onAssetDeleted?: (asset: Asset) => void;
    onAssetError?: (asset: Asset, error: any) => void;
    children?: any;
}

export type CreationEditorContext = {
    creation: Creation;
    creationId: any;
    content: CreationContent;
    errors: any;
    type: BaseCreationType | null;
    subtype: CreationSubtype | null;
    updateCreation: (update: any) => void;
    //validationResults: any;
    //setValidationResults: (results: any) => void;
    openCreation: (id: number, queue: string[]) => void;
    showQueue: (show: boolean) => void;
    permissions: string[];
    close: () => void;
    sectionStatuses: any;
    setSectionStatus: (section: string, status: string) => void;
    removeSection: (section: string) => void;
    isReadyToPublish: boolean;
    conifg?: any;
    typeConfig?: CreationTypeConfig;
    queue: string[];
    setQueue: (queue: any[]) => void;
}

export type CreationEditorRef = {
    openCreation: (id: number, queue: string[]) => void;
    close: () => void;
}

const CreationEditorContext = React.createContext({} as CreationEditorContext);
CreationEditorContext.displayName = "Creation Editor Context";
export const useCreationEditor = (): CreationEditorContext => React.useContext(CreationEditorContext);


const CreationEditor = React.forwardRef((props: CreationEditorProps, ref: any) => {

    const [drawerRef, open, close, isOpen] = useStandardDrawer();
    const [creationId, setCreationId] = useState<number | null | undefined>(null);
    const creation = useSelectorWithParams([creationSelector, creationId])
    const creationContent = useSelectorWithParams([creationContentSelector, creationId])
    const creationErrors = useSelectorWithParams([creationErrorsSelector, creationId])
    const allPermissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);
    const [sectionStatuses, setSectionStatuses] = useState<any>({})
    const validateAPI = useAPI(validateCreation(creationId))
    const [showQueue, setShowQueue] = useState<boolean>(false);
    const [queue, setQueue] = useState<string[]>([]);
    const [animEnded, setAnimEnded] = useState<boolean>(false);
    const [s, si, isMobile] = useSimpleBreakpoint();
    
    const permissions: string[] = useMemo(() => allPermissions ? allPermissions.filter((p: string) => p.startsWith("use creations")) : [], [allPermissions])

    const validate = () => {
        if (!creationId) return;
        if (validateAPI.loading) return;
        validateAPI.call(validateCreation(creationId))
    }

    useEffect(() => {
        if (!creationId) return;
        setSectionStatuses({})
        validate();
    }, [creationId]);

    useEffect(() => {
        window.creation=creation;
    }, [creation]);

    const openAfterUpdate = useCallAfterUpdate(() => {
        open();
    })

    const handleEditorClose = () => {
        setShowQueue(false);
        setQueue([]);
        close();
        setCreationId(null);
    }

    const handleUpdateCreation = (update: any) => {
    }

    const handleOpenCreation = (id: number, q: string[]) => {
        setCreationId(id);
        if (q) handleNewQueue(q);
        openAfterUpdate({})
    }


    const setSectionStatus = (section: string, status: string) => {
        setSectionStatuses((s: any) => {
            return {
                ...s,
                [section]: status
            }
        })
    }

    const removeSection = (section: string) => {
        //console.log("removing section", section)
        setSectionStatuses((s: any) => {
            const newStatuses = { ...s };
            delete newStatuses[section];
            return newStatuses;
        })
    }

    const isReadyToPublish = useMemo(() => {

        const sections = Object.keys(sectionStatuses);
        const someNotReady = sections.some((s: string) => sectionStatuses[s] !== "ready");
        if (someNotReady) return false;

        //console.log("creationErrors", creationErrors)
        
        if (creationErrors && Object.keys(creationErrors).length > 0) return false;

        return true;

    }, [sectionStatuses, creationErrors])

    const insideKey = useMemo(() => {
        //console.log("insideKey", creationId, drawerRef.current?.isOpen)
        return creationId ? `creationEditor-${creationId}` : "creationEditor"
    }, [creationId])

    const type:BaseCreationType | null = useMemo(() => {
        if (!creation) return null;
        return creation.type;
    }, [creation])

    const subtype:CreationSubtype | null = useMemo(() => {
        if (!creation) return null;
        return creation.content?.type;
    }, [creation]);

    const config = useMemo(() => {
        const type: CreationType | null = creation ? CreationTypeFromCreation(creation) : null;

        if (type === null) return null;
        const e = GetEditorConfigByType(type);
        if (!e) return null;
        return e;
    }, [type, subtype])

    const editor = useMemo(() => {
        return config ? config.editor : null
    }, [config, creation])

    const typeConfig = useMemo(() => {
        return CreationTypeConfigByType(type, subtype);
    }, [type, subtype])

    const handleNewQueue = (q: string[]) => {
        setQueue(q);
        setShowQueue(true);
    }

    useImperativeHandle(ref, () => ({
        creation,
        updateAsset: handleUpdateCreation,
        openCreation: handleOpenCreation,
        close: handleEditorClose,
        setQueue: setQueue
    }))

    window.ceSectionStatuses = sectionStatuses;

    const value: CreationEditorContext = {
        creation,
        creationId,
        content: creationContent,
        errors: creationErrors,
        type,
        subtype,
        config,
        typeConfig,
        permissions,
        updateCreation: handleUpdateCreation,
        openCreation: handleOpenCreation,
        close: handleEditorClose,
        sectionStatuses,
        setSectionStatus,
        removeSection,
        isReadyToPublish,
        setQueue: handleNewQueue,
        queue: queue,
    }

    // if (!creation) return null;
    // if (!creationId) return null;

    const showPrefixColumn = !!creationContent?.comment && si >= 5

    return (
        <CreationEditorContext.Provider value={value} key={"editor"}>
            {props.children}
            <StandardDrawer
                ref={drawerRef}
                width={showPrefixColumn ? "1600px" : "1200px"}
                mask={true}
                title={config?.editorTitle as any}
                onClose={handleEditorClose}
                layout={showPrefixColumn ? "threeColumns" : "twoColumns"}
                sider={<CreationPreview />}
                prefix={showPrefixColumn && <AIComments />}
                zIndex={110}
                onAnimEnd={(e) => {
                    if (e) {
                        setTimeout(() => setAnimEnded(true), 300)
                    } else {
                        setAnimEnded(false);
                    }
                }}
                footer={
                    creation ?
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: '1fr 400px',
                            width: "100%"
                        }}>
                            <CreationMainActions>
                                {queue.length > 0 ? (
                                <Tooltip title={
                                    <FormattedMessage
                                        id="tools.creationEditor.actions.showQueue.tooltip"
                                        defaultMessage="Show Queue"
                                    />
                                }>
                                <StandardButton icon={faList} onClick={() => setShowQueue(q => !q)} />
                                </Tooltip>
                                ) : null}
                            </CreationMainActions>
                        </div>
                        : <div></div>
                }
            >
                {creation ?
                    <Suspense fallback={
                        <div>
                            <FormattedMessage
                                id="common.loading"
                                defaultMessage="Loading..." />
                        </div>
                    }>
                        <RelaxMultiform
                            fieldIdPrefix="ce"
                            key={insideKey}
                            name="creationEditor"
                            callAPI={patchCreation(creationId)}
                            initialValuesSelector={[creationContentSelector, creationId]}
                            finalizeSubmit={(data: any) => {
                                return { content: { ...data } }
                            }}
                            debug={false}
                        >
                            {editor}
                            <ValidationResults key={insideKey} warningOnly />
                        </RelaxMultiform>
                    </Suspense>
                    : <div></div>
                }
                {showQueue && <CreationDraftQueue animEnded={animEnded}/>}
            </StandardDrawer>
        </CreationEditorContext.Provider>
    )
})

export default CreationEditor;