import { Input, Radio, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import { LanguageSelect, LanguageSelectInput } from "components/inputs/LanguageAndCountrySelectors";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow, StandardThirdColumn } from "components/layout/StandardGrid";
import { AiProjectInfoSelector, GetAiProjectInfo, UpdateAIProjectInfo, ProjectHasAICompanyDescriptionSelector, ProjectHasAIProjectInfoSelector, ProjectHasAIClientDescriptionSelector } from "modules/panel/state/AISlice";
import SettingsAI from "modules/smart/components/AIGeneration/SettingsAI"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";

const OnboardingAIClientInfo = () => {

    const fullfilled = useSelectorWithParams(ProjectHasAIClientDescriptionSelector);
    const intl = useIntl();

    const placeholder = intl.formatMessage({
        id: "apps.smart.components.aiSettings.clientDescription.placeholder",
        defaultMessage: "Tell us about your clients. Who are they? What do they do? What are their interests?",
      })

    return (
        <>
            <RelaxMultiform
                initialValuesSelector={AiProjectInfoSelector}
                callAPI={UpdateAIProjectInfo}
            >                
                        <RelaxField
                            name={"client_description"}
                            label={<FormattedMessage
                                id="apps.smart.components.aiSettings.clientDescription.label"
                                defaultMessage="Client Description"
                            />}
                        >
                            <Input.TextArea rows={6} placeholder={placeholder}/>
                        </RelaxField>
                        <RelaxField
                            name={["info", "client_type"]}
                            label={<FormattedMessage
                                id="apps.smart.components.aiSettings.clientType.label"
                                defaultMessage="Client Type"
                            />}
                            submitOnChange
                            >
                                <Radio.Group>
                                    <Radio.Button value="individual">
                                        <FormattedMessage
                                            id="apps.smart.components.aiSettings.clientType.individual"
                                            defaultMessage="Individual"
                                        />
                                    </Radio.Button>
                                    <Radio.Button value="business">
                                        <FormattedMessage
                                            id="apps.smart.components.aiSettings.clientType.business"
                                            defaultMessage="Business"
                                        />
                                    </Radio.Button>
                                </Radio.Group>
                        </RelaxField>
            </RelaxMultiform>
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={!fullfilled} />
        </>
    )

}

export default OnboardingAIClientInfo;