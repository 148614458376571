import React from "react";
import StandardButton from "components/common/StandardButton";
import { faFileExport } from "node_modules/@fortawesome/pro-light-svg-icons";

export type DataExportProps = {
  filename: string;
  type: "pdf" | "csv" | "docx";
  columns?: ColumnProps[];
  fileContent?: string; 
};

export type ColumnProps = {
  key: string;
  label: string;
  value?: (object: any) => string | number;
};

export const useDataExport = ({ filename, type, columns, fileContent }: DataExportProps) => {
  const exportData = (items: any[]) => {
    switch(type){
        case "csv":
            const csvContent = generateCsv(items, columns || []);
            downloadFile(csvContent, `${filename}.csv`, "text/csv")
    }
  };

  const generateCsv = (items: any[], columns: ColumnProps[]): string => {
    const header = columns.map((col) => col.label).join(",");
    const rows = items.map((item) => {
      return columns
        .map((col) => {
          if (col.value) {
            return col.value(item);
          }
          return item[col.key];
        })
        .join(",");
    });

    return [header, ...rows].join("\n");
  };

  const downloadFile = (content: string, filename: string, type: string) => {
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { exportData };
};

export const ExportButton = ({
    filename,
    type,
    columns,
    fileContent,
    items
}: DataExportProps & { items?: any[] }) => {
    const { exportData } = useDataExport({ filename, type, columns, fileContent });

    return (
        <StandardButton type="primary" onClick={() => exportData(items || [])} icon={faFileExport}/>
    );
};
