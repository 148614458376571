import { Drawer, Space } from "antd"
import { useEffect, useMemo, useState } from "react";
import { useCreationEditor } from "../CreationEditor";
import { Text, Title } from "components/common/StandardText";
import { HorizontalCenter } from "components/common/Center";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faX, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";

export const AIComments = () => {

    const {content} = useCreationEditor();

    const mappedComment = useMemo(() => {

        if (!content) return null;
        if (!content.comment) return null;

        const c = Array.isArray(content.comment) ? content.comment : [content.comment];

        return (
            c.map((comment: any) => (
                <div style={{
                    padding: "5px",
                    position: "relative",
                }}
                    className="standard-border"
                >
                    <div>
                        <strong>
                        {comment.text}
                        </strong>
                    </div>

                    <div style={{paddingTop: "5px"}}>
                        {comment.explanation}
                    </div>
                </div>
            ))
        )

    }, [content])

    if (!mappedComment || mappedComment.length === 0) return null;

    return (
        <Space direction="vertical">
            <Title level={4}>
                <FormattedMessage
                    id="tools.creationEditor.aiComments.title"
                    defaultMessage="AI Comments"
                />
            </Title>
            <Text>
                <FormattedMessage
                    id="tools.creationEditor.aiComments.subtitle"
                    defaultMessage="subtitle"
                />
            </Text>
            <Space direction="vertical">
            {mappedComment}
            </Space>
        </Space>
    )

}
