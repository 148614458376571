import {createSlice, createSelector} from '@reduxjs/toolkit';
import { callAPIProps } from 'services/hooks/useAPI';
import _ from 'lodash';
import { currentProjectIdSelector } from 'services/store/scopeSlice';

export type CallMeState = any;

const initialState: CallMeState = {

}


export const callMeSlice = createSlice({
    name: "callMe",
    initialState, 
    reducers: {
        getConfig: (state, action) => {

            if (!state[action.payload.projectId]) state[action.payload.projectId] = {};
            state[action.payload.projectId].config = action.payload.data;
        },
        getCustomization: (state, action) => {
            if (!state[action.payload.projectId]) state[action.payload.projectId] = {};
            state[action.payload.projectId].customization = action.payload.data;
        },
        updateConfig: (state, action) => {
            state[action.payload.projectId].config = action.payload.data;
        },
        updateCustomization: (state, action) => {
            state[action.payload.projectId].customization = action.payload.data;
        },

    }
})


export const GetCallMeConfig:callAPIProps = {
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/call-me`,1),
    method: "GET",
    successDispatch: callMeSlice.actions.getConfig,
    adjustResponse: (response) => {
        if (Array.isArray(response)) response = response[0];
        if (response.law_sms_active === 1) response.law_sms_active = true;
        if (response.law_sms_active === 0) response.law_sms_active = false;
        return response;
      }
}

export const GetCallMeCustomization:callAPIProps = {
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/call-me/customization`,1),
    method: "GET",
    successDispatch: callMeSlice.actions.getCustomization,
}

export const UpdateCallMeConfig: callAPIProps = {
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/call-me`,1),
    method: "PATCH",
    adjustResponse: (response) => {
        if (Array.isArray(response)) response = response[0];
        if (response.law_sms_active === 1) response.law_sms_active = true;
        if (response.law_sms_active === 0) response.law_sms_active = false;
        return response;
      },
    successDispatch: callMeSlice.actions.updateConfig,
}

export const UpdateCallMeCustomization: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/call-me/customization`),
    method: "PATCH",
    successDispatch: callMeSlice.actions.updateCustomization,
}

export const ProjectCallMeSelector = createSelector([
    (state:any) => state?.callMe,
    currentProjectIdSelector
],
    (state:any, projectId: any) => state && state[projectId]
)

export const ProjectCallMeConfigSelector = createSelector([
    ProjectCallMeSelector,
], (callMe: any) => callMe && callMe.config)

export const ProjectCallMeCustomizationSelector = createSelector([
    ProjectCallMeSelector,
], (callMe: any) => callMe && callMe.customization)


export default callMeSlice;