import { Divider, Drawer as AntDrawer } from "antd";
import { AwesomeClose } from "components/common/AwesomeIcon";
import React, { Children, ReactElement, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import useSimpleBreakpoint from "./useSimpleBreakpoint";

export type DrawerProps = {
    mask?: "full" | "half" | "none" | true | false;
    layout?: "standard" | "twoColumns" | "threeColumns" | undefined;
    children?: ReactNode;
    footer?: ReactElement | ReactElement[] | undefined;
    footerWidth?: any;
    title?: string | ReactElement | undefined;
    width?: number | string | undefined;
    onClose?: () => void;
    forceRender?: boolean;
    sider?: ReactElement | undefined;
    zIndex?: number;
    onAnimEnd?: (isOpen: boolean) => void;
}


export const StandardDrawer = React.forwardRef((props: DrawerProps, ref: any) => {

    const [isOpen, setIsOpen] = useState(false);

    const setOpen = (open: boolean) => setIsOpen(open);
    const [s, si, isMobile] = useSimpleBreakpoint();

    useImperativeHandle(ref, () => ({
        isOpen,
        setIsOpen,
    }))

    const drawerContent = () => {
        if (!props.layout)
            return (
                <div
                    style={{
                        padding: "10px",
                        height: "100%",
                        overflow: "auto",
                        width: props.width,
                        maxWidth: "100vw",
                    }}
                >
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "60px 1fr",
                        placeItems: "center"
                    }}><AwesomeClose size="2xl" onClick={() => setOpen(false)} /><h1 style={{ justifySelf: "start", marginBottom: "0px" }}>{props.title}</h1></div>
                    {props.children}
                </div>
            )
        if (props.layout === "standard") {
            return (
                <div style={{
                    height: "auto",
                    maxWidth: "100vw",
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "60px auto 60px",
                    width: props.width,
                }}>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "60px 1fr",
                        placeItems: "center"
                    }}><AwesomeClose size="2xl" onClick={() => setOpen(false)} /><h1 style={{ justifySelf: "start", marginBottom: "0px" }}>{props.title}</h1></div>
                    <div style={{ maxHeight: "calc(100vh - 120px)", overflow: "auto", padding: "20px" }}>
                        {props.children}
                    </div>
                    <div style={{ paddingLeft: "20px", width: props.footerWidth ? props.footerWidth : "50%" }}>{props?.footer}</div>
                </div>
            )
        }
        if (props.layout === "twoColumns") {

            const mobileStyle = {
                gridTemplateAreas: '"header header" "leftColumn leftColumn" "footer footer"',
                gridTemplateRows: '60px 1fr 60px',
                gridTemplateColumns: '1fr 1fr', 
            }

            const desktopStype = {
                gridTemplateAreas: '"header header" "leftColumn rightColumn" "footer footer"',
                gridTemplateRows: '60px 1fr 60px',
                gridTemplateColumns: '1fr auto', 
            }


            return (
                <div style={{
                    display: 'grid',
                    gridGap: '10px', 
                    height: '100vh',
                    width: props.width,
                    maxWidth: "100vw",
                    ...(isMobile ? mobileStyle : desktopStype)
                }}>
                    <div style={{
                        gridArea: 'header', 
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px"
                        }}>
                        <AwesomeClose size="2xl" onClick={() => setOpen(false)} />
                        <h1 style={{ justifySelf: "start", marginBottom: "0px" }}>
                            {props.title}
                        </h1>
                    </div>
                    <div style={{ 
                        gridArea: 'leftColumn', 
                        overflowY: 'scroll', 
                        padding: '20px' 
                        }}>
                            {props.children}
                    </div>
                    <div style={{ 
                        gridArea: 'rightColumn', 
                        overflowY: 'hidden', 
                        padding: '20px',
                        display: isMobile ? "none" : "block"
                        }}>
                            {props.sider}
                    </div>
                    <div style={{ 
                        gridArea: 'footer', 
                        gap: "10px",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>{props.footer}</div>
                </div>
            )
        }
        if (props.layout === "threeColumns") {

            const mobileStyle = {
                gridTemplateAreas: '"header header" "leftColumn leftColumn" "footer footer"',
                gridTemplateRows: '60px 1fr 60px',
                gridTemplateColumns: '1fr 1fr', 
            }

            const desktopStype = {
                gridTemplateAreas: '"prefixHeader header header" "prefixColumn leftColumn rightColumn" "prefixFooter footer footer"',
                gridTemplateRows: '60px 1fr 60px',
                gridTemplateColumns: '400px 1fr auto', 
            }


            return (
                <div style={{
                    display: 'grid',
                    gridGap: '10px', 
                    height: '100vh',
                    width: props.width,
                    maxWidth: "100vw",
                    ...(isMobile ? mobileStyle : desktopStype)
                }}>
                    <div style={{
                        gridArea: 'prefixColumn',
                        width: "100%",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "scroll",
                    }}>
                        {props.prefix}
                    </div>
                    <div style={{
                        gridArea: 'header', 
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px"
                        }}>
                        <AwesomeClose size="2xl" onClick={() => setOpen(false)} />
                        <h1 style={{ justifySelf: "start", marginBottom: "0px" }}>
                            {props.title}
                        </h1>
                    </div>
                    <div style={{ 
                        gridArea: 'leftColumn', 
                        overflowY: 'scroll', 
                        padding: '20px' 
                        }}>
                            {props.children}
                    </div>
                    <div style={{ 
                        gridArea: 'rightColumn', 
                        overflowY: 'hidden', 
                        padding: '20px',
                        display: isMobile ? "none" : "block"
                        }}>
                            {props.sider}
                    </div>
                    <div style={{ 
                        gridArea: 'footer', 
                        gap: "10px",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>{props.footer}</div>
                </div>
            )
        }
        return props.children;
    }

    const maskStyle = useMemo(() => {
        if (!props.mask) return "rgba(0, 0, 0, 0)"
        if (props.mask === "full" || props.mask === true) return "rgba(0, 0, 0, 0.45)"
        if (props.mask === "half") return "rgba(0, 0, 0, 0.25)"
        if (props.mask === "none" || props.mask === false) return "rgba(0, 0, 0, 0)"
        return "rgba(0, 0, 0, 0)"
    }, [props.mask]);

    const handleClose = () => {
        setOpen(false);
        props.onClose && props.onClose();
    }

    return (
        <AntDrawer
            open={isOpen}
            onClose={handleClose}
            mask={true}
            maskClosable={true}
            className="standard-drawer"
            width={"auto"}
            maskStyle={{ background: maskStyle }}
            forceRender={props.forceRender}
            zIndex={props.zIndex}
            afterOpenChange={props.onAnimEnd}
        >
            {drawerContent()}
        </AntDrawer>
    )


})

export const useStandardDrawer = () => {
    
    const ref: any = useRef();
    const open = () => ref.current?.setIsOpen(true);
    const close = () => ref.current?.setIsOpen(false);
    const isOpen = ref.current?.isOpen;

    return [ref, open, close, isOpen]
}
