import { faCheckCircle, faClipboard, faClipboardCheck, faCopy } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "antd";
import { ReactNode, useState } from "react";
import StandardButton from "./StandardButton";

type CopyToClipboardProps = {
    text: string;
    label?: ReactNode;
    style?: React.CSSProperties;
}

export default function CopyToClipboard (props:CopyToClipboardProps) {

    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(props.text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    }

    return (
        <Tooltip title="Copy to clipboard">
            <StandardButton icon={copied ? faClipboardCheck : faClipboard} onClick={handleClick} style={props.style}>
                {props.label}
            </StandardButton>
        </Tooltip>
    )
}