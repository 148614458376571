
// export enum Reaction {
//     like = "like",
//     love = "love",
//     haha = "haha",
//     wow ="wow",
//     sad = "sad",
//     angry = "angry",
//     takeCare = "takeCare",
// }

import { callAPIProps } from "services/hooks/useAPI";
import Reactions, {ReactionsData} from "modules/smart/components/list-item/base-list-item/reactions/reactions";
import { getApiUrl } from "services/helpers/api-helpers";
import _ from "lodash";
import { Provider } from "modules/panel/config/ProviderConfig";
import {postsSlice, NormalizePost } from "modules/posts/state/postsSlice";
import Asset, { CalendarInfo } from "./asset";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import dayjs, { Dayjs } from "dayjs";

type Reactions = {[key: string]: number}

export type PostInsights = {
    reactions: Reactions;
    clicks: number;
    reach: number;
    paid_reach: number;
    interactions: number;
    organic_reach: number;
}

export type AdInsights = {
    clicks: number;
    reach: number;
    impressions: number;
    spend: number;
    date_start: string;
    date_stop: string;
    actions: number;
    action_type: string | string[];
    cost_per_action_type: number;
    conversions: any[];
}

export type ProviderInfo = {
    external_id: string;
    status: string;
    error_reason: string;
    is_eligable_for_promotion: boolean;
    is_published: boolean;
    ads: any[];
    insights: PostInsights;
    generated: { [key: string]: any }
}

export type PostProvider = "facebook" | "google" | "instagram";

export type PostType = {

    local_id: string;
    post_type: string;
    insights: PostInsights;

    language: string | undefined;

    title: string;
    message: string;
    link: string;
    description: string;
    call_to_action: string | undefined;
    button_url: string | undefined;
    button_type: string | undefined;

    image_url: string | undefined;
    crop: string | undefined;

    video: string | undefined;
    video_url: string | undefined;

    type: string | undefined;
    status: string;

    providers: Partial<Record<PostProvider,ProviderInfo>>;

    created_at: string;

    rawData: any;


    //Generated 
    generated: { [key: string]: any }

    // generated: {
    //     promotionStatus: string;
    //     secondaryPromotionStatus: string | null;
    //     additionalStatusCount: number;
    // }

    
}

export type FacebookAdSet = {
    id: string;
}

export type Ad = {
    id: string;
    effective_status: string;
    configured_status: string;
    status: string;
    calculated_status: string;
    generated?: {
        reviews: string[] | null;
    }
}

/* Base class for posts. Used mainly in the post / ad / bot list.
*
*/
export default class Post extends Asset implements PostType{

    local_id = "";
    post_type = "none";
    provider = "none";
    rawData = {};

    insights: PostInsights = {
        reactions: {
            "like": -1,
            "love": -1
        },
        clicks: -1,
        reach: -1,
        paid_reach: -1,
        interactions: -1,
        organic_reach: -1
    };

    is_eligible_for_promotion = false;
    adsStatus = "none";
    language= undefined;

    title = "default title"
    message = ""
    link = ""
    description = ""
    call_to_action = undefined
    button_url: string | undefined;
    button_type: string | undefined;

    image_url: undefined;
    crop: undefined;

    video = undefined;
    video_url = undefined;

    type= "noType"
    status= "noStatus"

    providers: Partial<Record<PostProvider,ProviderInfo>> = {};

    created_at = "";
    

    generated:any = {
        promotionStatus: "notSet",
        secondaryPromotionStatus: null,
        additionalStatusCount: 0,
    }


    constructor(data: any) {

        super(data);

        this.rawData = data;

        for (const [key, value] of Object.entries(data)) {
            (this as any)[key] = _.cloneDeep(value);
        }

        //Calculate effective promotion state.
        const allAds = this.getAllAds();
        const statuses:string[] = allAds.map((ad: any) => this.getAdStatus(ad));
        const counts = _.countBy(statuses, (status: any) => status);

        const ua:string[] = _.uniq(statuses)
        const sortedStatuses = _.sortBy(ua, (status: string) => -counts[status]);

        if (sortedStatuses.length === 0) {
            this.generated.promotionStatus = "notPromoted"
        } else if (sortedStatuses.length === 1) { 

            this.generated.promotionStatus = sortedStatuses[0];
            this.generated.secondaryPromotionStatus = null;
            this.generated.additionalStatusCount = 0;

        } else {

            if (counts["error"] > 0) this.generated.promotionStatus = "error"
            else if (counts["paused"] > 0) this.generated.promotionStatus = "paused"
            else if (counts["active"] > 0) this.generated.promotionStatus = "active"
            else if (counts["ended"] > 0) this.generated.promotionStatus = "ended"
    
            this.generated.secondaryPromotionStatus = sortedStatuses.find((status: string) => status !== this.generated.promotionStatus) || null;
            this.generated.additionalStatusCount = sortedStatuses.length - 2;

        }


        //generate provider data

        try {
        if (this.providers) {
            if (this.providers.facebook && this.providers.facebook.ads) {

                if (!this.providers.facebook.generated) this.providers.facebook.generated = {};
                
                this.providers.facebook.ads.forEach((ad:any) => {
                    if (!ad.generated) ad.generated = {};
                    if (!ad.ad_review_feedback) return;


                    let reviews:any = {};
                    const g = ad.ad_review_feedback.global;
                    const fb = ad.ad_review_feedback.placement_specific.facebook;
                    const insta = ad.ad_review_feedback.placement_specific.instagram;

                    g && Object.entries(g).forEach(([key, value]) => {
                        reviews[key] = value;
                    });
                    
                    fb && Object.entries(fb).forEach(([key, value]) => {

                        if (!reviews[key]) {
                            reviews[key] = value;
                            return;
                        }

                        if (reviews[key] === value) return;

                        if (reviews[key] !== value) {
                            reviews[`Facebook: ${key}`] = value;
                        }

                    });

                    ad.generated.reviews = reviews;

                });


            }

            if (this.providers.instagram) {

                //Hopefully temoporary fix for instagram insights to align them with existing model.

                if (this.providers.instagram.like_count) {
                    if (!this.providers.instagram.insights) this.providers.instagram.insights = {reactions: {}} as any;
                    this.providers.instagram.insights.reactions = {
                        like: this.providers.instagram.like_count,
                        love: 0,
                        haha: 0,
                        wow: 0,
                        sad: 0,
                        angry: 0,
                        takeCare: 0
                    }
                }
            }

            }
        } catch (e) {
            console.warn("Error generating provider data", e)
        }
    

    }

    getGeneratedData() {
        return this.generated;
    }

    getId(provider?: Provider) {
        if (provider && this.providers[provider]) return this.providers[provider]?.external_id;
        if (this.id && this.id !== "") return this.id

        if (this.providers) {
            if (provider) {
                return this.providers[provider]?.external_id;
            }
            if (Object.values(this.providers)?.length === 1) {
                return Object.values(this.providers)[0]?.external_id;
            }
            for (const [key, value] of Object.entries(this.providers)) {
                return `${key}${value?.external_id}`
              }
        }
        return this.id
    }

    getType() {
        if (this.id && this.id !== "") return "post";
        if (this.providers.facebook) return "post_facebook";
        if (this.providers.instagram) return "post_instagram";
        if (this.providers.google) return "post_google";
        return "post";
    }

    getIdByType(type: string) {
        if (!type) return null;
        if (type === "post") return this.id;
        if (type === "post_facebook") return this.providers.facebook?.external_id;
        if (type === "post_google") return this.providers.google?.external_id;
        if (type === "post_instagram") return this.providers.instagram?.external_id;
    }

    getShortenedDescription() {
        if (!this.message) return ""

        const msg = this.message;

        const maxLength = 100;

        if (msg.length < maxLength) return msg;

        const mid = this.message.substring(maxLength-10,maxLength+30);
        const index = Math.max(...[mid.indexOf("!"),mid.indexOf("."),mid.indexOf("?")]);

        if (index >= 0) return msg.substring(0,index+maxLength+31) + "...";
        return this.message.substring(0, maxLength+20) + "...";
    }

    getPostStatus(provider?: Provider) {


        if (provider) return this.getProviderPost(provider)?.status;

        //reduce providers to one status
        const statuses = _.uniq(this.getProvidersAsArray().map(p => p?.status).filter( s => s !== undefined));
    
        if (statuses.length === 1) {
            return statuses[0];
        }
            return "mixed"


    }

    //Returns organic insights for a provider (without ads)
    getProviderInsights(type: string, provider: Provider, aggragator?: "sum" | "avg"):number {
            if (this.providers[provider]) {

                if (!this.providers[provider]) return 0;

                const r = (this.providers[provider] as any)?.insights?.[type]
                if (r === undefined) return 0;
                return r;
                
            }
            return 0;
    }

    //Returns combined insights from all ads for a given provider.
    getProviderAdInsights(type: string, provider: Provider, aggragator?: "sum" | "avg"):number {
        if (this.providers[provider]) {
            const prov = this.providers[provider];
            if (!prov) return 0;
            if (!prov.ads) return 0;
            if (prov.ads.length === 0) return 0;

            if (!aggragator || aggragator === "sum") {
                return parseInt(prov.ads.reduce((prev, curr) => prev + (curr.insights?.[type] || 0),0));
            }
            if (aggragator === "avg") {
                return parseInt(prov.ads.reduce((prev, curr) => prev + (curr.insights?.[type] || 0),0)) / prov.ads.length;
            }
        }
        return 0;
    }

    //Returns combined insights for a given provider (with ads)
    getProviderCombinedInsights(type: string, provider: Provider, aggragator?: "sum" | "avg") {

        const organic = this.getProviderInsights(type, provider, aggragator);
        const ads = this.getProviderAdInsights(type, provider, aggragator);
        
        return organic + ads;
    }



    //Returns combined insights for all providers (with ads)
    getCombinedInsights(type: string, aggragator?: "sum" | "avg") {
        return this.getProviders().reduce((prev, curr) => {
            return prev + this.getProviderCombinedInsights(type, curr, aggragator)
        }, 0);
    }

    getSummerizedStatus() {
        let hasErrors = false;
        const error_reasons:string[] = [];
        let statuses:string[] = [];

        if (this.getProviderPosts().length === 0) return {
            hasErrors: false,
            error_reasons: ["This post has not yet been published."],
            status: "notPublished"
        }

        this.getProviderPosts().forEach(provider => {

            const p = this.getProviderPost(provider as any);

            if (!p) return;
            const ps = this.getPostStatus(provider as any) || "error";
            statuses = _.union(statuses, [ps]);

            if (p.status === "error") {
                hasErrors = true;
                error_reasons.push(p.error_reason);
            }

        });

        let status = "";
        if (statuses.length === 1) {    
            status = statuses[0];
        } else {
            status = "mixed";
        }

        return {
            hasErrors,
            error_reasons,
            status
        }
    }

    getProviderReactions(provider: Provider):Reactions {
        if (this.providers[provider]) {
            //console.log("provider reactions", provider, this.providers[provider]?.insights?.reactions)
            return this.providers[provider]?.insights?.reactions || {};
        }
        return {};
    }

    getCombinedReactions():Reactions {
        return this.getProviders().reduce((prev, curr) => {
            const prov = this.getProviderReactions(curr);
            //console.log("combined reactions", curr, prov, prev)
            if (!prov) return prev;
            if (Object.keys(prov).length === 0) return prev;

            Object.keys(prov).forEach(r => {
                if (!prev[r]) prev[r] = 0;
                if (prov[r]) prev[r] += prov[r]
            })
            return prev;
        },{} as Reactions);
    }


    getAllAdConversions = () => {
        const ads = this.getAllAds();
        if (!ads) return [];
        const conversions = ads.filter((a:any) => !!a).map((a:any) => a.insights?.conversions).flat().filter((c:any) => c);
        let sum:any[] = [];
        conversions.forEach((c:any) => {
            let i = sum.findIndex((s:any) => s.action_type === c.action_type);
            if (i === -1) {
                sum.push(c);
                return;
            } else {
                sum[i] = {
                    ...sum[i],
                    value: sum[i].value + c.value
                }
            }
        })
        return sum;
    }

    //ADS

    getAllAds() {
        return this.getProviderPosts().map((p:any) => this.getAdsForProvider(p)).flat();
    }

    getNumberOfRunningAds() {
        return this.getAllAds().filter((a:any) => this.getAdStatus(a) === "active").length;
    }


    getAdStatus(ad: any) {
        if (!ad) return null;
        let s = ad.status;

        if (s === "" || s=== undefined) {
            s = "active";
        }

        if (ad.adset?.end_time) {
            const end = new Date(ad.adset.end_time);
            const now = new Date();
            if (end < now) {
                s = "ended";
            }
        }
        
        if (s === "pending_review") return "pending";
        if (s === "PENDING_REVIEW") return "pending";
        if (s === "DISAPPROVED") return "disapproved";
        if (s === "ACTIVE") return "active";
        if (s === "PAUSED") return "paused";
        if (s === "ERROR") return "error";
        return s;
    }


    static safelyGetAdStartDate(ad: any) {
        if (!ad) return null;
        if (ad.created_time) return ad.created_time;
        if (ad.created_at) return ad.created_at;
        return null;
    }

    static safelyGetAdEndDate(ad: any) {
        if (!ad) return null;
        if (!ad.adset) return null;
        if (ad.adset.end_time) return ad.adset.end_time;
        return null;
    }

    forEachProvider = (callback: (provider: Provider, data: any) => void) => {
        (["facebook", "google"] as Provider[]).forEach((p:Provider) => this.providers[p] && callback(p, this.providers[p]));
    }


    //Get statuses of all ads of all providers
    getAdStatuses() {
        const statuses = this.getProviders().map((p:any) => p.ads && p.ads.map((a:any) => this.getAdStatus(a)));
        return statuses;
    }
    

    getPublicationDate(provider?: string) {

            return this.created_at || "-";

    }


    getProvider(provider: Provider) {
        return this.providers[provider];
    }

    getProviders(): Provider[] {
        return Object.keys(this.providers) as Provider[];
    }

    getProvidersAsArray() {
        return this.getProviders().map((p) => this.providers[p]);
    }


    getProviderPosts() {
        return this.getProviders().filter((p:any) => this.getProviderPost(p));
    }

    getProviderPost(provider: Provider) {
        const p = this.providers[provider];
        if (!p) return null;
        if (Array.isArray(p)) return null;
        return p;
    }

    getAdsForProvider(provider: Provider) {
        if (!provider) return null;
        return this.providers?.[provider]?.ads;
    }

     


    getDebugDisplay() {
        return `POST:${this.post_type}:${this.id}`
    }

    roundToDecimals = (num: number | string) => {
        return (Math.floor(parseInt(num as any) || 0)/100);
    }

    //BUDGET

    getBudgetByAd(ad: any) {
        if (!ad) return 0;
        //return ad.adset?.daily_budget divided by 100 and rounded to two decimal places
        return this.roundToDecimals(ad.adset?.daily_budget);
    }

    getBudgetByProvider(provider: Provider) {
        return this.getAdsForProvider(provider)?.reduce((prev, curr) => {
            return prev + (this.getBudgetByAd(curr) || 0);
        },0) || 0;
    }

    getBudget() {
        return this.getProviderPosts().reduce((prev, curr) => {
            return prev + this.getBudgetByProvider(curr as any);
        },0) || 0;
    }

    //SPEND


    getSpentBudgetByAd(ad: any) {
         return this.roundToDecimals(ad.insights?.spend * 100);
    }

    getSpentBudgetByProvider(provider: Provider) {
        return this.getAdsForProvider(provider)?.reduce((prev, curr) => {
            return prev + (this.getSpentBudgetByAd(curr) || 0);
        },0) || 0;
    }

    getSpentBudget() {
        return this.getProviderPosts().reduce((prev, curr) => {
            return prev + this.getSpentBudgetByProvider(curr as any);
        },0) || 0;
    }

    static getCprByAd(ad: any) {
        if (!ad.insights.actions) return null;
        if (ad.insights.actions === 0) return null;
        return Math.floor((ad.insights.spend / ad.insights.actions) * 100)/100;
    }

    getCprByProvider(provider: Provider) {
        return this.getAdsForProvider(provider)?.reduce((prev, curr) => {
            const cpc = Post.getCprByAd(curr);
            if (cpc === null) return prev;
            return prev + cpc;
        },0);
    }

    getCpr() {
        if (this.getProviderPosts().length === 0) return null;
        return this.getProviderPosts().reduce((prev, curr) => {
            const cpc = this.getCprByProvider(curr as any);
            if (cpc === null) return prev;
            return prev + parseFloat(cpc);
        },0)
    }

        //Publish and ad
    publishAdToFacebookPost: (keyword: string) => callAPIProps = (keyword) => ({
            method: "PATCH",
            url: ({projectId}) => (getApiUrl(`projects/${projectId}/posts/${this.getType()}/${this.getIdByType(this.getType())}/publish?ad_category=${keyword}`,3)),
            auth: true,
            body: {
                id: this.getIdByType(this.getType()),
                type: this.getType()
            },
           // successDispatch: postsSlice.actions.updatePost,
            customNormalizer: (data:any) => {
                if (!data) return null
                return NormalizePost(data.data)
            }
    })
    

    actionRemovePost:callAPIProps = {
        method: "DELETE",
        url: ({projectId}) => (getApiUrl(`projects/${projectId}/posts/${this.getType()}/${this.getIdByType(this.getType())}`)),
        successDispatch: postsSlice.actions.delete,
        passToDispatcher: {
            postId: this.local_id,
            post: this
        },
        auth: true,
        // fakeResponse: async (request) => ({
        //     status: 204,
        //     json: () => ({
        //         data: [
        //         ]
        //     })
        // }),
    }

    updateFacebookAd: (adId: string | number) => callAPIProps = (adId) => ({
        method: "PATCH",
        url: () => (getApiUrl(`services/facebook/ads/${adId}`)),
        auth: true,
    })

    updateAdStatus: (adId: string | number, status: string, provider: string) => callAPIProps = (adId, status, provider) => ({
        method: "PATCH",
        url: () => (getApiUrl(`services/${provider}/ads/${adId}/status`)),
        auth: true,
        body: {
            status
        }
    })


    getCalendarInfo() {
        return {
            typeIcon: faCamera,
            status: "Published",
            type: "Post",
            date: dayjs(this.created_at),
            color: "red",
            description: this.getShortenedDescription(),
            image: this.image || "",
            canBePickedUp: false,
            moveInFutureOnly: false
        }
    }

}

