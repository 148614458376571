import { Input } from 'antd';
import StandardButton from 'components/common/StandardButton';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import OutsideAlerter from 'services/hooks/useClickOutside';


export default function ColorPicker(props: any) {

    const [showPicker, setShowPicker] = useState(false);

    return (
        <div style={{ position: "relative", ...props.style }} className="color-picker">
            {showPicker && !props.disabled && (
                <OutsideAlerter 
                    callback={() => setShowPicker(false)}
                    style={{ position: "absolute", zIndex: 1000 }}
                >
                    <ChromePicker
                        color={props.value || ""}
                        onChange={(c) => props.onChange && props.onChange(c.hex)}
                        disableAlpha={true}
                    />
                </OutsideAlerter>
            )}
            <Input
                value={props.value}
                onChange={(c) => props.onChange && props.onChange(c.target.value)}
                onBlur={props.onBlur}
                disabled={props.disabled}
                suffix={
                    props.disabled ||
                    <div className='color-picker-preview'>
                        <StandardButton
                            className="color-picker-preview-button"
                            onClick={() => setShowPicker(true)}
                            style={{ backgroundColor: props.value }}
                        >
                            {" "}
                        </StandardButton>
                    </div>
                }
            />

        </div>
    )

}