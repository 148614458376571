import { Alert } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import useAPI from "services/hooks/useAPI";
import { initialState, updateUserSettings } from "state/user/userSettingsSlice";

type Props = {
  filteredItems: number;
  resetSettings: 'creationList' | 'postListv2' | 'tasksList' | 'calculationsList'
};

export const InfinityListResetFilters = (props: Props) => {
  const { call } = useAPI({});

  const handleResetFilters = () => {
    const body = initialState.interface?.[props.resetSettings];
    call(updateUserSettings({ interface: {
      [props.resetSettings]: body
    } }));
  };

  return (
    <>
      {props.filteredItems > 0 && (
        <Alert
          style={{ padding: 0 }}
          message={
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div style={{ flex: 1, padding: "0 20px" }}>
                <FormattedMessage
                  id="list.filters.hidden.items.number"
                  defaultMessage={`You have ${props.filteredItems} items filtered out`}
                />
              </div>
              <StandardButton onClick={handleResetFilters}>
                <FormattedMessage
                  id="list.filters.reset"
                  defaultMessage="Reset filters"
                />
              </StandardButton>
            </div>
          }
          type="info"
        />
      )}
    </>
  );
};
