import { faCheckCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import dayjs from "dayjs";
import { Col, Row } from "antd";
import { ReactNode } from "react";
import { InfinityListSettingsCheckbox } from "tools/infinityList/infinityListSettingsCheckbox";
import { InfinityColumnProps } from "tools/infinityList/infinityTemplates";
import { Text } from "components/common/StandardText";

export const TransactionsListAdvancedSettings = [
    {
        key: "type",
        label: (
            <FormattedMessage
                id="seller.calculations.list.filtering.advancedSettings.filtering.type.label"
                defaultMessage="Filter by type" />
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "calculations",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.calculations.label"
                                defaultMessage="Show Calculations" />
                        }
                        path={["calculationsList", "calculations"]}
                    />
                )

            },
            {
                key: "teamCalculations",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.teamCalculations.label"
                                defaultMessage="Show Team Calculations" />
                        }
                        path={["calculationsList", "teamCalculations"]}
                    />
                )

            },
        ]
    },
    {
        key: "status",
        label: (
            <FormattedMessage
                id="seller.calculations.list.filtering.advancedSettings.filtering.status.label"
                defaultMessage="Filter by status" />
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "paid",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.paid.label"
                                defaultMessage="Show Paid" />
                        }
                        path={["calculationsList", "paid"]}
                    />
                )

            },
            {
                key: "unpaid",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.unpaid.label"
                                defaultMessage="Show Unpaid" />
                        }
                        path={["calculationsList", "unpaid"]}
                    />
                )

            },
        ]
    },
]

const TransactionColumnHeadings: { [key: string]: ReactNode } = {
    "title": <FormattedMessage
        id="seller.calculations.list.header.title"
        defaultMessage="Title"
    />,
    "user": <FormattedMessage
        id="seller.calculations.list.header.user"
        defaultMessage="User"
    />,
    "status": <FormattedMessage
        id="seller.calculations.list.header.status"
        defaultMessage="Status"
    />,
    "type": <FormattedMessage
        id="seller.calculations.list.header.type"
        defaultMessage="Type"
    />,
    "subscription": <FormattedMessage
        id="seller.calculations.list.header.subscription"
        defaultMessage="Subscription"
    />,
    "amount": <FormattedMessage
        id="seller.calculations.list.header.amount"
        defaultMessage="Amount"
    />,
}

const columnSize = {
    small: ["75px", "75px", "75px", "75px", "75px", "75px"],
    medium: ["50px", "75px", "75px", "100px", "100px", "100px"],
    standard: [false, false, "75px", "100px", "150px", "250px"],
    large: [false, false, false, false, "400px", "400px"],
    wide: ["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"],
}

export const TransactionListHeaders: InfinityColumnProps[] = [
    {
        key: 'title',
        size: columnSize.wide,
        render: () => TransactionColumnHeadings['title'],
    },
    {
        key: 'user',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['user'],
    },
    {
        key: 'status',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['status'],
    },
    {
        key: 'type',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['type'],
    },
    {
        key: 'subscription',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['subscription'],
    },
    {
        key: 'amount',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['amount'],
    },
];

const TransactionTypeStatuses: any = {
    paid: {
        title: <FormattedMessage
            id="transaction.status.paid"
            defaultMessage="Paid"
        />,
        icon: <AwesomeIcon icon={faCheckCircle} size="xl" color="green" />
    },
    unpaid: {
        title: <FormattedMessage
            id="transaction.status.unpaid"
            defaultMessage="UnPaid"
        />,
        icon: <AwesomeIcon icon={faXmarkCircle} size="xl" color="red" />
    }
}



export const TransactionColumns = [
    {
        title: 'Title',
        size: columnSize.wide,
        render: (record: any) => record.title,
    },
    {
        title: 'User',
        size: columnSize.standard,
        render: (record: any) =>
            <>
                <span>{record?.user?.name}</span>
                <span>{record?.user?.email}</span>
            </>,
    },
    {
        title: 'Status',
        size: columnSize.standard,
        render: (record: any) => TransactionTypeStatuses[record.status] ? TransactionTypeStatuses[record.status].icon : record.status,
    },
    {
        title: 'Type',
        size: columnSize.standard,
        render: (record: any) => record.type,
    },
    {
        title: 'Subscription',
        size: columnSize.standard,
        render: (record: any) =>
            <>
                <span>{record?.subscription?.name}</span>
                <span>{dayjs(record?.subscription?.creation_time).format("DD-MM-YYYY")}</span>
                <span>{record?.subscription?.additional}</span>
            </>,
    },
    {
        title: 'Amount',
        size: columnSize.standard,
        render: (record: any) => record.amount,
    }
];

export const TransactionColumnsToExport = [
    {
        key: "title",
        label: "Title",
        value: (value: any) => value.title
    },
    {
        key: "amount",
        label: "Amount",
        value: (value: any) => value.amount
    },
    {
        key: "status",
        label: "Status",
        value: (value: any) => value.status
    },
    {
        key: "user.name",
        label: "User Name",
        value: (value: any) => value?.user?.name
    },
    {
        key: "user.email",
        label: "User Email",
        value: (value: any) => value?.user?.email
    },
    {
        key: "subscription.name",
        label: "Subscription Name",
        value: (value: any) => value?.subscription?.name
    },
    {
        key: "subscription.creation_time",
        label: "Subscription Creation Time",
        value: (value: any) => {
            const creationTime = value?.subscription?.creation_time;

            const date = new Date(creationTime);
            if (date instanceof Date && !isNaN(date.getTime())) {
                return date.toLocaleDateString("pl-PL");
            }

            return "";
        },
    },
    {
        key: "subscription.additional",
        label: "Subscription Additional",
        value: (value: any) => value?.subscription?.additional
    },
];


const SellerSubscriptionsColumnHeadings: { [key: string]: ReactNode } = {
    "name": <FormattedMessage
        id="seller.subscription.list.header.name"
        defaultMessage="Name"
    />,
    "user": <FormattedMessage
        id="seller.subscription.list.header.user"
        defaultMessage="User"
    />,
    "status": <FormattedMessage
        id="seller.subscription.list.header.status"
        defaultMessage="Status"
    />,
    "expiry": <FormattedMessage
        id="seller.subscription.list.header.expiry"
        defaultMessage="Expiry"
    />,
    "billingCycle": <FormattedMessage
        id="seller.subscription.list.header.billingCycle"
        defaultMessage="Billing cycle"
    />,
    "nextPayment": <FormattedMessage
        id="seller.subscription.list.header.nextPayment"
        defaultMessage="Next payment"
    />,
    "agreement": <FormattedMessage
        id="seller.subscription.list.header.agreement"
        defaultMessage="Agreement"
    />,
}

const SellerSubscriptionTypeStatuses: any = {
    active: {
        title: <FormattedMessage
            id="transaction.status.active"
            defaultMessage="Active"
        />,
        icon: <AwesomeIcon icon={faCheckCircle} size="xl" color="green" />
    },
    disabled: {
        title: <FormattedMessage
            id="transaction.status.disabled"
            defaultMessage="Disabled"
        />,
        icon: <AwesomeIcon icon={faXmarkCircle} size="xl" color="red" />
    }
}


export const SellerSubscriptionsListHeaders: InfinityColumnProps[] = [
    {
        key: 'name',
        size: columnSize.standard,
        render: () => SellerSubscriptionsColumnHeadings['name'],
    },
    {
        key: 'user',
        size: columnSize.standard,
        render: () => SellerSubscriptionsColumnHeadings['user'],
    },
    {
        key: 'status',
        size: columnSize.medium,
        render: () => SellerSubscriptionsColumnHeadings['status'],
    },
    {
        key: 'expiry',
        size: columnSize.medium,
        render: () => SellerSubscriptionsColumnHeadings['expiry'],
    },
    {
        key: 'billingCycle',
        size: columnSize.medium,
        render: () => SellerSubscriptionsColumnHeadings['billingCycle'],
    },
    {
        key: 'nextPayment',
        size: columnSize.large,
        render: () => SellerSubscriptionsColumnHeadings['nextPayment'],
    },
    {
        key: 'agreement',
        size: columnSize.large,
        render: () => SellerSubscriptionsColumnHeadings['agreement'],
    },
];


export const SellerSubscriptionsColumns = [
    {
        title: 'Name',
        size: columnSize.standard,
        render: (record: any) => record?.attributes?.name,
    },
    {
        title: 'User',
        size: columnSize.standard,
        render: (record: any) =>
            <>
                <span>{record?.attributes?.user?.name}</span>
                <span>{record?.attributes?.user?.phone}</span>
            </>,
    },
    {
        title: 'Status',
        size: columnSize.medium,
        render: (record: any) => SellerSubscriptionTypeStatuses[record?.attributes?.status] ? SellerSubscriptionTypeStatuses[record?.attributes?.status].icon : record?.attributes?.status,
    },
    {
        title: 'expiry',
        size: columnSize.medium,
        render: (record: any) => <span>{dayjs(record?.attributes?.expiry).format("DD-MM-YYYY")}</span>,
    },
    {
        title: 'billingCycle',
        size: columnSize.medium,
        render: (record: any) => record?.attributes?.billing_cycle,
    },
    {
        title: 'nextPayment',
        size: columnSize.large,
        render: (record: any) =>
            <>
                <Row>
                    <Col span={12}>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.nextPayment.periodEnd"
                                defaultMessage="Period end: "
                            />
                            {dayjs(record?.attributes?.next_payment?.period_end).format("DD-MM-YYYY")}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.nextPayment.netto"
                                defaultMessage="Netto: "
                            />
                            {record?.attributes?.next_payment?.netto}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.nextPayment.brutto"
                                defaultMessage="Brutto: "
                            />
                            {record?.attributes?.next_payment?.brutto}
                        </Text>
                    </Col>

                    <Col span={12}>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.nextPayment.tax"
                                defaultMessage="Tax: "
                            />
                            {record?.attributes?.next_payment?.tax}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.nextPayment.toBePaid"
                                defaultMessage="To be paid: "
                            />
                            {record?.attributes?.next_payment?.to_be_paid}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.nextPayment.amountPaid"
                                defaultMessage="Amount paid: "
                            />
                            {record?.attributes?.next_payment?.amount_paid}
                        </Text>
                    </Col>
                </Row>
            </>,
    },
    {
        title: 'agreement',
        size: columnSize.large,
        render: (record: any) =>
            <>
                <Row style={{placeItems:"start"}}>
                    <Col span={12}>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.agreement.billingCycle"
                                defaultMessage="Billing cycle: "
                            />
                            {record?.attributes?.agreement?.billing_cycle}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.agreement.endTime"
                                defaultMessage="End time: "
                            />
                            {dayjs(record?.attributes?.agreement?.end_time).format("DD-MM-YYYY")}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.agreement.startTime"
                                defaultMessage="Start time: "
                            />
                            {dayjs(record?.attributes?.agreement?.start_time).format("DD-MM-YYYY")}
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.agreement.extensionTime"
                                defaultMessage="Extension time: "
                            />
                            {dayjs(record?.attributes?.agreement?.extension_time).format("DD-MM-YYYY")}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.agreement.mandatoryPeriods"
                                defaultMessage="Mandatory periods: "
                            />
                            {record?.attributes?.agreement?.mandatory_periods}
                        </Text>
                        <br/>
                        <Text>
                            <FormattedMessage
                                id="seller.subscription.list.column.agreement.leftPeriods"
                                defaultMessage="Left periods: "
                            />
                            {record?.attributes?.agreement?.left_periods}
                        </Text>
                    </Col>
                </Row>
            </>,
    }
];


export const SellerSubscriptionsColumnsToExport = [
    {
        key: "name",
        label: "Name",
        value: (value: any) => value?.attributes?.name
    },
    {
        key: "status",
        label: "Status",
        value: (value: any) => value?.attributes?.status
    },
    {
        key: "user.name",
        label: "User Name",
        value: (value: any) => value?.attributes?.user?.name
    },
    {
        key: "user.phone",
        label: "User Phone",
        value: (value: any) => value?.attributes?.user?.phone
    },
    {
        key: "expiry",
        label: "Expiry",
        value: (value: any) => {
            const time = value?.attributes?.expiry;

            const date = new Date(time);
            if (date instanceof Date && !isNaN(date.getTime())) {
                return date.toLocaleDateString("pl-PL");
            }

            return "";
        },
    },
    {
        key: "billingCycle",
        label: "Billing cycle",
        value: (value: any) => value?.attributes?.billing_cycle
    },
];