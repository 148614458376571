import SellerRevenue from './SellerRevenue'
import FormattedMessage from 'components/common/FormattedMessage'
import SellerCalculations from './SellerCalculations'
import SellerCoupons from './SellerCoupons'
import Tabs from '_components/tabs/tabs'
import SellerSubscriptions from './SellerSubscriptions'

export default function SellerPage() {
    const Panes = [
        // {
        //   title:(
        //     <FormattedMessage
        //       id={"panel.containers.seller.revenue"}
        //       defaultMessage={"Revenue"}
        //     />
        //   ),
        //   key: "sellerRevenue",
        //   content: <SellerRevenue />
        // },
        {
          title:(
            <FormattedMessage
              id="panel.containers.seller.calculations"
              defaultMessage="Calculations"
            />
          ),
          key: "sellerCalculations",
          content: <SellerCalculations />
        },
        {
          title:(
            <FormattedMessage
              id="panel.containers.seller.subscriptions"
              defaultMessage="Subscriptions"
            />
          ),
          key: "sellerSubscriptions",
          content: <SellerSubscriptions />
        },
        // {
        //   title:(
        //     <FormattedMessage
        //       id={"panel.containers.seller.coupons"}
        //       defaultMessage={"Coupons"}
        //     />
        //   ),
        //   key: "sellerCoupons",
        //   content: <SellerCoupons />
        // },
      ]
    
      return (
        <Tabs
          panes={Panes}
        />
      )
      
}
