import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import CalendarControls from "./CalendarControls";
import CalendarGrid from "./CalendarGrid";
import { calendarRangeToDays, getCalendarRange, isWithinRange } from "./CalendarHelpers";
import "dragula/dist/dragula.css";
import dragula from 'react-dragula';
import _ from "lodash";
import CalendarCreationObserver from "./CalendarCreationObserver";
import dayjs, { Dayjs } from "dayjs";
import "./calendar.less";
import "./calendar.css";
import "./calendar-entries.less";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import Toolbar from "components/toolbar/Toolbar";

const CalendarContext = React.createContext({});
CalendarContext.displayName = "CalendarContext";

export type CalendarMode = "day" | "week" | "month";

type Range = [Dayjs, Dayjs];

type CalendarHook = {
    date: Dayjs,
    setDate: (newDate: Dayjs) => void,
    mode: CalendarMode,
    setMode: (newMode: CalendarMode) => void,
    range: Range | null,
    entries: any[],
    setEntries: (newEntries: Map<string, any>) => void,
    days: Dayjs[],
    drake: dragula.Drake | null,
    dayFilter?: (entry: CalendarEntry, day: Dayjs) => boolean,
    EntryComponent?: React.ComponentType<any>,
    DayTool?: React.ComponentType<any>,
    hasValidSider: boolean,
    isShowingSider: boolean,
    setIsShowingSider: React.Dispatch<React.SetStateAction<boolean>>,
    previewManager: ReturnType<typeof useCalendarPreview>,
}

export type CalendarEntry = {
    id: string,
    date: Dayjs,
} | {
    name: string,
    date: Dayjs,
}

export type CalendarEntriesManager = (props: { date: Dayjs, mode: CalendarMode, range: Range | null }) => {
    entries: CalendarEntry[],
    dayFilter?: (entry: CalendarEntry, day: Dayjs) => boolean,
    EntryComponent: React.ComponentType<any>,
    DayTool?: React.ComponentType<any>,
    Sider?: React.ComponentType<any>,
    usePreview?: boolean,
};

const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);

type CalendarProps = {
    children?: React.ReactNode,
    entriesManager: CalendarEntriesManager,
    fitOnScreen?: boolean,
}

const useCalendarPreview = () => {

    const [previewEntry, setPreviewEntry] = React.useState<string | null>(null);
    const [isPreviewLocked, setIsPreviewLocked] = React.useState(false);

    const setPreview = (entryId: string | null) => setPreviewEntry(entryId);
    const setLock = (lock: boolean) => setIsPreviewLocked(lock);
    const openAndLockPreview = (entryId: string | null) => {
        setPreviewEntry(entryId);
        setLock(!!entryId);
    }

    return {
        previewEntry,
        setPreview,
        isPreviewLocked,
        setLock,
        openAndLockPreview,
    }

}

export const useCalendar = (): CalendarHook => useContext(CalendarContext) as any;

const Calendar = React.forwardRef((props: CalendarProps, ref: any) => {

    const bp = useSimpleBreakpoint();
    const [date, _setDate] = React.useState(dayjs().subtract(0, 'month'));
    const [mode, _setMode] = React.useState<CalendarMode>('month');
    const [fitToScreen, setFitToScreen] = React.useState(props.fitOnScreen);

    const [isShowingSider, setIsShowingSider] = React.useState(true);
    const previewManager = useCalendarPreview();

    const range: Range | null = useMemo(() => {
        if (!date) return null;
        if (!mode) return null;
        return getCalendarRange(date, mode);

    }, [date, mode])

    const {
        entries,
        dayFilter,
        EntryComponent,
        DayTool,
        Sider,
        usePreview,
    } = props.entriesManager({ date, mode, range });

    const [drake, setDrake] = useState<dragula.Drake | null>(null);

    // useEffect(() => {
    //     console.log('entries', entries);
    // }, [entries]);


    useEffect(() => {

        let start;

        const d = dragula([], {
            revertOnSpill: true,
            moves: (el) => {

                const canBePickedUp = el.getAttribute("data-calendar-entry-can-be-picked-up");
                if (canBePickedUp === "false") return false;

                start = getIndexInParent(el);
                return true;
            },
            accepts: (el, target, source, sibling) => {

                //console.log("accepts", el, target)

                const now = dayjs();
                const futureOnly = el.getAttribute("data-calendar-entry-future-only");
                const entryDate = dayjs(parseInt(el.getAttribute("data-calendar-entry-date")));

                //console.log("futureOnly", futureOnly, "entryDate", entryDate.format("YYYY-MM-DD HH:MM"))

                // const targetDate = dayjs(parseInt(target.getAttribute("data-calendar-day-date")));
                // console.log("target date", targetDate.format("YYYY-MM-DD HH:MM"))


                if (futureOnly === "true") {
                    if (entryDate.isBefore(now, 'day')) return false;
                }

                return true;
            },

        })


        d.on("drop", (el, target, source, sibling) => {

            const entryId = el.getAttribute("data-calendar-entry-index");
            const entryType = el.getAttribute("data-calendar-entry-type");
            const from = source.getAttribute("data-calendar-day-date");
            const to = target.getAttribute("data-calendar-day-date");

            //console.log("Dropped-Calendar", entryType, entryId, " from ", from, " to ", to)

        })

        setDrake(d)

    }, []);


    const daysOnGrid = useMemo(() => {

        if (!range) return [];

        return calendarRangeToDays(range[0], range[1]);

    }, [range])

    const setDate = (newDate: Dayjs) => {
        _setDate(newDate);
    }

    const setMode = (newMode: CalendarMode) => {
        _setMode(newMode);
    }

    const isMobile = bp?.[2];

    const hasValidSider = !!Sider;
    const showSider = isShowingSider && Sider && !isMobile;

    const hasValidPreview = usePreview && !!EntryComponent && !!previewManager.previewEntry;
    const previewEntry = entries.find(e => String(e.id) === String(previewManager.previewEntry));
    const fitToScreenEnabled = ( fitToScreen ) && !isMobile;

    if (!drake) return null;


    return (
        <CalendarContext.Provider
            key="calendar-context"
            value={{
                date,
                setDate,
                mode,
                setMode,
                range,
                entries,
                days: daysOnGrid,
                drake,
                dayFilter,
                EntryComponent,
                DayTool,
                isShowingSider,
                setIsShowingSider,
                hasValidSider,
                previewManager,
                fitToScreen,
                setFitToScreen,
            }}
        >
            <div
                className={`calendar-wrapper ${fitToScreenEnabled ? 'fit-on-screen' : "fit-to-content"} ${showSider ? 'with-sider' : 'without-sider'} ${mode ? "mode-" + mode : ""}`}
            >
                <Toolbar 
                    title="Calendar" 
                    className="calendar-controls"
                    smallToolbar={(
                        <>
                        <CalendarControls.Date />
                        <Toolbar.Spacer />
                        </>
                    )}
                    largeToolbar={(
                        <>
                        <CalendarControls.SiderToggle />
                        <CalendarControls.Date />
                        <CalendarControls.Year />
                        <CalendarControls.FitToggle />
                        <CalendarControls.Mode />
                        </>
                    )}
                    drawerToolbar={(
                        <>
                        <CalendarControls.SiderToggle />
                        <CalendarControls.Date />
                        <CalendarControls.FitToggle />
                        <CalendarControls.Mode />
                        </>
                    )}
                    />
                <CalendarGrid />
                <div className="calendar-sider">
                    {hasValidSider && <Sider />}
                </div>
                <div className="calendar-preview">
                    <div className="calendar-preview-header">
                        Preview
                    </div>
                    <div className="calendar-preview-content">
                        {hasValidPreview && <EntryComponent entry={previewEntry} mode="preview" />}
                    </div>
                </div>
            </div>
            <CalendarCreationObserver />
        </CalendarContext.Provider>
    )

});

export default Calendar;