

import FormattedMessage from "components/common/FormattedMessage";
import SelectWithSuggestions from "components/forms/SelectWithSuggestionsNew";
import URLInput from "components/inputs/URLInput";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { callAPIFunction } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { userSettingsInterfaceLanguageSelector } from "state/user/userSettingsSlice";
import RelaxList from "tools/relaxForm/RelaxList";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { GoogleSmartSettingsSelector, HasFilledAdsAudienceSelector, UpdateGoogleSmartSettings } from "modules/smart/smartSlice";
import { useEffect } from "react";


export default function OnboardingAdsAudience (props:any) {

    const language = useSelectorWithParams([userSettingsInterfaceLanguageSelector]);
    const completed = useSelectorWithParams(HasFilledAdsAudienceSelector)
    const settings = useSelectorWithParams([GoogleSmartSettingsSelector]);


    const getKeywords:callAPIFunction = (q) => ({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/keywords?language=${language}&type=query&query=${q}`, 2),
    })


    return (
        <>

        <RelaxSingleField
            initialValuesSelector={GoogleSmartSettingsSelector}
            callAPI={UpdateGoogleSmartSettings}
            name="keywords_seed"
            required
            label={
                <FormattedMessage
                    id="apps.forsant.businessDetails.keywordSuggestion"
                    defaultMessage="Keyword suggestion"
                />
            }
            submitOnChange
        >
            <SelectWithSuggestions
                mode="multiple"
                callAPI={getKeywords}
                adjustValue={(v) => ({value: v.label, label: v.label, disabled: false})}
                adjustSuggestion={(s) => ({value: s.text, label: s.text, disabled: false})}
                uniqueValueProp="label"
                allowCustomValue={true}
            />
        </RelaxSingleField>
        <RelaxList
            name="competitors_websites"
            //initialValuesSelector={GoogleSmartSettingsSelector}
            initialValues={settings?.competitors_websites || [""]}
            callAPI={UpdateGoogleSmartSettings}
            required
            label={
                <FormattedMessage
                  id="apps.forsant.businessDetails.competitorsWebsites"
                  defaultMessage="Your competitors’ websites"
                />
              }
        >
            <URLInput />
        </RelaxList>

        <ConnectedOnboardingControls nextDisabled={!completed}/>
        </>
    )

}
