import { Alert, Checkbox, Col, Input, Row } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import { useEffect, useMemo, useRef, useState } from "react"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import RelaxField from "tools/relaxForm/RelaxField"
import RelaxMultiform from "tools/relaxForm/RelaxMultiform"
import useAPI from "services/hooks/useAPI"
import { CompanyDataWithExternalDownload } from "./CompanyDataWithExternalDownload"
import ZipCodeInput from "_components/forms/masked-inputs/zip-code-input"
import useRelaxData from "tools/relaxForm/useRelaxData"
import { useOnboarding } from "providers/onboarding/onboarding-context"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useSimpleModal } from "services/hooks/useModal"
import AddressDisplay from "components/displays/AddressDisplay"
import RelaxFormWrapper from "tools/relaxForm/RelaxForm"
import { companyDataSelector, confirmCompanyData, updateCompanyData } from "modules/panel/state/companySlice"
import { userHasBusinessPurposesStatement, userLanguageSelector } from "modules/panel/config/selectors/user";
import { useIntl } from "react-intl"
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid"
import { rulesetArrayMinMax } from "tools/relaxForm/relaxPresets"
import { getStatement } from "modules/panel/components/statements/Statement"

type companyDataTypes = {
    vat_number: string,
    name: string,
    country: string,
    city: string,
    street: string,
    street_number: string,
    street_apartment: string,
    postal_code: string,
    [key: string]: string;
}

export const OnboardingCompanyInvoiceData = () => {
    const { call: callGetStatement, data: statement } = useAPI({});
    const language = useSelectorWithParams(userLanguageSelector);
    const companyData:companyDataTypes = useSelectorWithParams(companyDataSelector)
    const hasFilledCompanyData = useSelectorWithParams(userHasBusinessPurposesStatement)
    const { nextStep } = useOnboarding();
    const formRef = useRef<any>(null);
    const rd = useRelaxData();
    
    const [showForm, setShowFrom] = useState(companyData?.vat_number ? true : false)

    const companyUpdateCall = useAPI(updateCompanyData(companyData.id))

    const invoiceDataConfirmationCall = useAPI(confirmCompanyData(companyData.id))

    const [openModal, contextHolder] = useSimpleModal();
    const [taxNumber, setTaxNumber] = useState(companyData?.vat_number || "")


    const [statementCheck, setStatementCheck] = useState(false)

    useEffect(() => {
        callGetStatement({
            ...getStatement("business_purposes", language),
            onFailure(response, additional, request) {
                callGetStatement(getStatement("business_purposes", "en"))
            },
        });
    }, []);


    const data = {
        ...companyData,
        ...rd.currentValue,
    }
    delete data.regon;
    if (taxNumber) data.vat_number = taxNumber;

    const statementText = (() => {
        if (!statement?.data?.length) return null;
    
        return statement.data.reduce((best:any, current:any) => {
            if (!best || current.attributes.version > best.attributes.version) {
                return current;
            }
            return best;
        }, null)?.attributes?.body || null;
    })();

    const handleNext = () => {

        openModal({
            title: "Confirmation",
            type: "confirm",
            width: 800,
            content: (
                <div>
                    <AddressDisplay address={data} />

                    <FormattedMessage
                        id="apps.panel.containers.onboarding.companyData.confirmation.title"
                        defaultMessage="Are you sure this data is correct?"
                    />
                </div>
            ),
            onOk: handleConfirm,
        })
        return false;
    }

    const handleConfirm = () => {

        const filteredData: any = {};
        for (const key in data) {
            if (data[key] !== null) {
                filteredData[key] = data[key];
            }
        }

        if (statementText !== null) {
            if(statementCheck){
                filteredData.statement_check = true;
                filteredData.statement = statementText;
            }
        }

        companyUpdateCall.call({
            body: { ...filteredData },
        }).then((res) => {
            if (res.status === 200 || res.status === 204) {
                invoiceDataConfirmationCall.call();
                nextStep()
            }
        })
    }

    const form = (
        <RelaxFormWrapper
            relaxData={rd}
            initialValues={companyData}
            submitOnChange
            ref={formRef}
        >

            <StandardRow>
                <StandardFullColumn>
                    <RelaxField
                        name="name"
                        required
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.name"
                                defaultMessage="Name"
                            />
                        }
                        rules={rulesetArrayMinMax(3,0)}>
                        <Input />
                    </RelaxField>
                    <RelaxField
                        name="country"
                        required
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.country"
                                defaultMessage="Country"
                            />
                        }
                        rules={rulesetArrayMinMax(3,0)}>
                        <Input />
                    </RelaxField>
                    <RelaxField
                        name="city"
                        required
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.city"
                                defaultMessage="City"
                            />
                        }
                        rules={rulesetArrayMinMax(3,0)}>
                        <Input />
                    </RelaxField>
                    <RelaxField
                        name="street"
                        required
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.street"
                                defaultMessage="Street"
                            />
                        }
                        rules={rulesetArrayMinMax(3,0)}>
                        <Input />
                    </RelaxField>
                </StandardFullColumn>
            </StandardRow>

            <StandardRow>
                <StandardHalfFullColumn>
                    <RelaxField
                        name="street_number"
                        required
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.streetNumber"
                                defaultMessage="Street number"
                            />
                        }
                        rules={rulesetArrayMinMax(1,0)}>
                        <Input />
                    </RelaxField>
                </StandardHalfFullColumn>

                <StandardHalfFullColumn>
                    <RelaxField
                        name="street_apartment"
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.streetApartment"
                                defaultMessage="Apartment number"
                            />
                        }>
                        <Input />
                    </RelaxField>
                </StandardHalfFullColumn>
            </StandardRow>

            <StandardRow>
                <StandardFullColumn>
                    <RelaxField
                        name="postal_code"
                        required
                        label={
                            <FormattedMessage
                                id="panel.containers.companySettings.zipCode"
                                defaultMessage="ZIP code"
                            />
                        }>
                        <ZipCodeInput />
                    </RelaxField>
                </StandardFullColumn>
            </StandardRow>


        </RelaxFormWrapper>
    )

    const canClickNext = () => {
        if (!data) return false;
        if (statementText !== null && !statementCheck) return false;
        if (rd?.extraStatus === "fieldErrors") return false;
        return true;
    }


    return (
        <>
            <StandardRow>
                <StandardFullColumn>
                    <CompanyDataWithExternalDownload
                        data={data}
                        onChange={(value: any) => {
                            setTaxNumber(value);
                        }}
                        onSuccess={(value: any) => {

                            const mappedData = {
                                name: value?.attributes?.company_name || value.company_name,
                                postal_code: value?.attributes?.zip_code || value.zip_code,
                                vat_number: value?.attributes?.nip || value.nip,
                                country: value?.attributes?.country || value.country,
                                city: value?.attributes?.city || value.city,
                                street: value?.attributes?.street || value.street,
                                street_number: value?.attributes?.street_number || value.street_number,
                                street_apartment: value?.attributes?.street_apartment || value.street_apartment,
                            };

                            formRef.current.updateAllFields(mappedData);

                            setShowFrom(true);
                        }}
                        onError={() => {setShowFrom(true)}}
                    />
                    <Checkbox
                        onClick={(e) => {
                            const c = e.target?.checked;
                            if (c) setShowFrom(true);
                        }}
                        style={{ marginBottom: "20px" }}>
                        <FormattedMessage
                            id="apps.panel.containers.onboarding.companyData.withoutVatNumber"
                            defaultMessage="My company does not have a VAT number"
                        />
                    </Checkbox>
                </StandardFullColumn>
            </StandardRow>

            {showForm && form}
            {!hasFilledCompanyData && statementText !== null && (
                <StandardRow>
                    <StandardFullColumn>
                        <Checkbox
                            checked={statementCheck}
                            onChange={(e) => setStatementCheck(e.target.checked)}>
                            {statementText}
                        </Checkbox>
                    </StandardFullColumn>
                </StandardRow>
            )}
            {contextHolder}
            <ConnectedOnboardingControls
                clickOverride={handleNext}
                nextDisabled={!canClickNext()}
            />
        </>
    )
}