import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Typography, Image } from "antd";
import FormattedMessage from "components/common/FormattedMessage";

const QRCodeGalleryItem = ({src, width, link}: {src: string, width?: number, link?: string}) => {
    return (
        <Space direction="vertical" size="small">
            <Image src={src} width={width || 175} />
            <Button type="primary" block href={link || ""} target="_blank">
                <FormattedMessage id="modules.call.reviewsConfiguration.qrCodeGallery.goToCanva" 
                defaultMessage="Open in Canva" />
                </Button>
        </Space>
    )
}

const squareLink = "https://www.canva.com/design/DAGg4c38rHo/LrJiD2tFgS9eO_aNrpRR9g/view?utm_content=DAGg4c38rHo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview";
const portraitLink = "https://www.canva.com/design/DAGg4QaWP_w/CamDckcslxu9fYLpdfyLLg/view?utm_content=DAGg4QaWP_w&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview";

const QRCodeGallery = () => {
    return (
        <Space direction="vertical" size="large">
        <Image.PreviewGroup>
            <Space direction="horizontal" size="small" wrap>
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/15.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/11.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/8.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/7.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/4.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/5.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/12.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/13.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/14.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/16.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/10.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/6.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/3.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/1.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/2.png" link={portraitLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/9.png" link={portraitLink} />
            </Space>
        </Image.PreviewGroup>

        <Image.PreviewGroup>
            <Space direction="horizontal" size="small" wrap>
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/Karta-do-wystaniania-opini-w-Google-dla-restauracji.png" link={squareLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/Karta-NFC-opinii-Google.png" link={squareLink}/>
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/Karta-opinii-w-Google-NFC.png" link={squareLink} />
                <QRCodeGalleryItem src="https://fasttony.com/wp-content/uploads/2025/03/Zbieranie-legalnie-opini-w-Google-w-lokalu.png" link={squareLink} />
            </Space>
        </Image.PreviewGroup>

        </Space>
    )
}

export default QRCodeGallery;