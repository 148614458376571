import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import _ from "lodash";
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { APIErrorDisplay } from "services/hooks/useAPI";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { RelaxContainerContext } from "./RelaxContainerContext";
import RelaxErrors from "./RelaxError";
import { RelaxApiStatus, RelaxLabel } from "./relaxExtras";
import RelaxSingleField from "./RelaxField";
import { RelaxFieldProps, Rule } from "./relaxTypes";
import { findErrorsToInjectInList } from "./ruleEvaluation";
import useRelaxWrapper from "./useRelaxWrapper";
import RelaxDebugView from "./RelaxDebugView";

export type RelaxListProps = RelaxFieldProps & {
    elementRules?: Rule[]
}

const RelaxList = React.forwardRef(({children, relaxData, ...props}: RelaxListProps, ref: React.Ref<any>) => {

    const rd = relaxData;

    useEffect(() => {
        rd.setBackFilledProps({
            ...props,
            adjustInitialValue: (val: any[]) => {
                if (!val) return [""];
                if (val.length === 0) return [""];
                return val;
            },
            shouldReset: (newInitialValue: any[], initialValue: any[]) => {
                if (!initialValue) return true;
                return false;
            },
        })
    }, []);
    
    const {
        currentValue,
        setCurrentValue,
        changed,
        hasErrors,
        errors,
        settings,
        parentContext,
        hasParentContext,
        touched,
        initialValue,
        name,
        isFocused,
        setIsFocused,
        optimisticValue,
        stateRef,
        submit,
        extraStatus,
        setExtraStatus,
    } = rd;
    

    const [fields, setFields] = useState<number[]>([]);
    const maxIndex = useRef<number>(0);
    const allFields = useRef<any>({});

    const [fieldErrors, setFieldErrors] = useState<any>(null);
    const [hasFieldErrors, setHasFieldErrors] = useState<boolean>(false);

    const checkFieldErrors = () => {
       // console.log("Checking field errors")
        setHasFieldErrors(() => {
            if (!fields) return false;
            if (!fieldErrors) return false;
            //console.log("checking for field errors", fieldErrors, fields)
            const errorsForActiveFields = fields.map((fieldNumber: number) => fieldErrors[fieldNumber] || null);
            if (!errorsForActiveFields || !errorsForActiveFields.length) return false;
            const hasErrors = errorsForActiveFields.some((e: any) => !!e);
            return hasErrors;
        })
    }

    useEffect(() => {
       checkFieldErrors();
    }, [fields, fieldErrors])

    
    const fieldRefs = useRef<any[]>([]);

    useEffect(() => {
        if (hasFieldErrors) {
            setExtraStatus("fieldErrors") 
        } else {
            setExtraStatus(null)
        }
    }, [hasFieldErrors]);
    
    const { loading: apiLoading, data: apiData, error: apiError, clear: apiClear } = rd.apiHandler;

    const requestCallAfterUpdate = useCallAfterUpdate(() => {
       // console.log("currentValue", currentValue, "initialValue", optimisticValue)
        if (_.isEqual(currentValue, optimisticValue)) return;
        submit();
        if (hasParentContext && parentContext.handleFieldBlur) parentContext.handleFieldBlur(name);
    })


    useEffect(() => {

        setFields(() => {
            if (!optimisticValue) return [];
            return optimisticValue.map((v: any, index: number) => index);
        })
        maxIndex.current = optimisticValue ? optimisticValue.length : 0;
       
    }, [optimisticValue]);


    const initialValuesObject = useMemo(() => {
        let o:any = {};
        _.set(o, name, optimisticValue);
        return o;
    }, [optimisticValue])


    const handleFieldChange = (fieldName: any, value: any) => {

        if (fieldName && Array.isArray(fieldName)) {

            const fieldNumber = fieldName[fieldName.length - 1];
            const indexOfThisField = fields.indexOf(fieldNumber);
            
        //    console.log("Chanegd filed", fieldNumber, indexOfThisField, value)

            setCurrentValue((oldValue: any) => {
                const copy = _.cloneDeep(oldValue);
                _.set(copy, indexOfThisField, value)
                return copy;
            });
        }
        if (hasParentContext && parentContext.handleFieldChange) parentContext.handleFieldChange(name, currentValue);
    }

    const handleFieldBlur = () => {
        requestCallAfterUpdate();
    }

    const handleFieldErrors = (fieldName: any, errors: any) => {


        if (fieldName && Array.isArray(fieldName)) {

            const fieldNumber = fieldName[fieldName.length - 1];
            const indexOfThisField = fields.indexOf(fieldNumber);

            setFieldErrors((oldValue: any) => {
                const copy = _.cloneDeep(oldValue || []);
                _.set(copy, fieldNumber, errors)
                return copy;
            });

            checkFieldErrors();

        }
    }

    useEffect(() => {
        if (hasParentContext && parentContext.handleFieldErrors) parentContext.handleFieldErrors(name, hasFieldErrors);
    }, [fieldErrors]);

    const errorsToInject = findErrorsToInjectInList(currentValue, settings.rules);

    useImperativeHandle(ref, () => ({
        ...rd,
    }))

    const handleAdd = (atIndex: number) => {

        if (!canHaveMore()) return;


        setFields((oldFields: number[]) => {
            const newFields = [...oldFields];
            newFields.splice(atIndex + 1, 0, maxIndex.current);
            return newFields;
        })
        maxIndex.current = maxIndex.current + 1

        let newValue:any = {};

        setCurrentValue((oldValue: any) => {
            newValue = _.cloneDeep(oldValue);
            newValue.splice(atIndex + 1, 0, "");
            return newValue;
        })

        props.onChange && props.onChange(newValue);
        handleFieldBlur();


    }

    const handleRemove = (index: number) => {

        if (!canHaveLess()) return;

        setFields((oldFields: number[]) => {
            const newFields = [...oldFields];
            newFields.splice(index, 1);
            return newFields;
        })

        let newValue:any = {};

        setCurrentValue((oldValue: any) => {
            newValue = _.cloneDeep(oldValue);
            newValue.splice(index, 1);
            return newValue;
        })

        props.onChange && props.onChange(newValue);
        handleFieldBlur();
    }

    const canHaveMore = () => {
        if (!currentValue) return true;
        if (settings.rules) {
            const max = settings.rules.find((r: any) => r.max && r.max > 0);
            if (!max) return true;
            return currentValue.length < max.max;
        }
        return true;
    }

    const canHaveLess = () => {
        if (!currentValue) return true;
        if (settings.rules) {
            const min = settings.rules.find((r: any) => r.min && r.min > 0);
            if (!min) return true;
            return currentValue.length > min.min;
        }
        return true;
    }

    const handleKeyDown = (e:any, index:any) => {

        if (!e || !e.key) return;
        if (e.key === "Enter") {
            if (fieldRefs.current[index + 1]) fieldRefs.current[index + 1].focus();
        }

    }

    const fieldComponents = () => {

        if (!fields) return null;

        //  console.log("---------------------------------")
        //  console.log("Fields", fields)
        //  console.log("optimisticValue", optimisticValue)
        //  console.log("currentValue", currentValue)
        //  console.log("initialValuesObject", initialValuesObject)
        //  console.log("---------------------------------")

        let f:any = [];

        //for (let i = 0; i < maxIndex.current; i++) {
        for (let i = 0; i< fields.length; i++) {
        
            const v = fields[i]

            if (!v && v !== 0) {
                continue;
            }

            const index = i;
            const numberOfThisField = v;
            const indexOfThisField = index;

            const nameOfThisField = typeof props.name === "string" ? [props.name, numberOfThisField] : [...props.name, numberOfThisField];

            f.push(
                    <div 
                    key={nameOfThisField.join("-")}
                    className="relax-list-item"
                    onKeyDown={(e:any) => handleKeyDown(e, index)}
                    >
                        <div className="list-content">
                            <RelaxSingleField
                                ref = {(element: any) => allFields.current[numberOfThisField] = element}
                                name={nameOfThisField}
                                rules={props.elementRules}
                                fieldIdPrefix={settings.fieldIdPrefix}
                                initialValuesSelector={null}
                                initialValues={initialValuesObject}
                                initialValue={undefined}
                                adjustInitialValue={null}
                                extraErrors={errorsToInject[indexOfThisField] ? [errorsToInject[indexOfThisField]] : []}
                                disableSubmit={true}
                                style={{
                                    paddingBottom: "0px",
                                }}
                                hideStatus={true}
                                shouldReset={true}
                            >
                                {children}
                            </RelaxSingleField>
                        </div>
                        <div className="list-tools">

                            <div className="list-tools-minus">
                            <AwesomeIcon
                                icon={faMinusCircle}
                                size="2xl"
                                color={canHaveLess() ? "black" : "gray"}
                                onClick={() => handleRemove(indexOfThisField)}
                            />
                            </div>

                            
                            <div className="list-tools-plus">
                                <AwesomeIcon
                                    icon={faPlusCircle}
                                    size="2xl"
                                    color={canHaveMore() ? "black" : "gray"}
                                    onClick={() => handleAdd(indexOfThisField)}
                                />
                            </div>

                        </div>
                    </div>
            )



        }

        return f;
    }


    // console.log("Rendering list")
    // console.log("Fields", fields)
    // console.log("optimisticValue", optimisticValue)
    // console.log("currentValue", currentValue)
    // console.log("fieldErros", fieldErrors)

    return (
        <RelaxContainerContext.Provider
            value={{
                settings: { ...settings, label: null },
                //data: rd, 
                handleFieldChange,
                handleFieldBlur,
                handleFieldErrors,
            }}
        >
            <div className={`relax-new relax-container list ${props.className || ''} ${props.horizontal ? 'list-horizontal' : ''}`} style={{
                paddingBottom: "20px",
            }}
            id={(settings.fieldIdPrefix ? settings.fieldIdPrefix + "-" : "") + (Array.isArray(name) ? name.join("-") : name)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            >
                <div className="label-bar">
                    <RelaxLabel label={settings.label} />
                    <RelaxApiStatus loading={apiLoading} data={apiData} error={apiError} touched={touched} changed={false}/>
                </div>
                <div className="content list-wrapper">
                   {fieldComponents()}
                </div>
                <div className="error-bar">
                    <RelaxErrors errors={errors} />
                    <APIErrorDisplay error={apiError} />
                    {settings.debug && <RelaxDebugView relaxData={rd} />}
                </div>
            </div>
        </RelaxContainerContext.Provider>
    )

})

const wrapped = React.forwardRef(({children, ...props}: any, ref:any) => {

    const {relaxData: _relaxData, ref: _ref} = useRelaxWrapper(props);

    if (!_relaxData) return null;

    return (
        <RelaxList ref={_ref} relaxData={_relaxData} {...props}> 
            {children}
        </RelaxList>
    )

})

export default wrapped;