import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import Calendar from "./Calendar";
import AICampaignGenerationModal from "modules/smart/components/AIGeneration/AICampaignGenerationForm";
import useCalendarProjectEntries from "./useCalendarProjectEntries";

function ProjectCalendar() {
    return (
        <Calendar
            entriesManager={useCalendarProjectEntries}
            fitOnScreen={true}
        >
            <DisplayIfHasPermission permission={"use module smart ai generate"}>
                <AICampaignGenerationModal />
            </DisplayIfHasPermission>
        </Calendar>
    );
}

export default ProjectCalendar;