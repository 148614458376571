import { useCallback } from "react";

const useDownloadSVG = (svgId: string) => {
  // Function to download SVG as a file
  const downloadAsSVG = useCallback(() => {
    const svg = document.getElementById(svgId);
    if (!svg) {
      console.error("SVG element not found");
      return;
    }

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const blob = new Blob([svgString], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "image.svg";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [svgId]);

  // Function to download SVG as PNG
  const downloadAsPNG = useCallback((width: number) => {
    const svg = document.getElementById(svgId) as SVGSVGElement;
    if (!svg) {
      console.error("SVG element not found");
      return;
    }

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const blob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Canvas context not supported");
        return;
      }

      // Get bounding box and scale width accordingly
      const bbox = svg.getBBox();
      const scaleFactor = width / bbox.width;
      canvas.width = width;
      canvas.height = bbox.height * scaleFactor;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      URL.revokeObjectURL(url);

      // Convert canvas to PNG and trigger download
      const pngUrl = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = pngUrl;
      a.download = "image.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    img.src = url;
  }, [svgId]);


  return { downloadAsSVG, downloadAsPNG };
};

export default useDownloadSVG;
