import { Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import {
  AiProjectInfoSelector,
  ProjectHasAILanguageSelector,
  UpdateAIProjectInfo,
} from "modules/panel/state/AISlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { callAPIFunction } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { userSettingsInterfaceLanguageSelector } from "state/user/userSettingsSlice";
import RelaxField from "tools/relaxForm/RelaxField";
import SelectWithSuggestions from "components/forms/SelectWithSuggestionsNew";
import URLInput from "components/inputs/URLInput";
import { GoogleSmartSettingsSelector, HasFilledAdsAudienceSelector, SmartSelector, UpdateGoogleSmartSettings, smartSlice } from "modules/smart/smartSlice";
import { ruleForbiddenDomains } from "tools/relaxForm/relaxPresets";

const OnboardingAIBasicInfo = () => {
  const fullfilled = useSelectorWithParams(ProjectHasAILanguageSelector);
  const language = useSelectorWithParams([
    userSettingsInterfaceLanguageSelector,
  ]);
  const completed = useSelectorWithParams(HasFilledAdsAudienceSelector);

  const getKeywords: callAPIFunction = (q) => ({
    url: ({ getApiUrl, projectId }) =>
      getApiUrl(
        `projects/${projectId}/services/google/ads/keywords?language=${language}&type=query&query=${q}`,
        2
      ),
  });

  return (
    <>
      <RelaxField
        initialValuesSelector={GoogleSmartSettingsSelector}
        callAPI={UpdateGoogleSmartSettings}
        name="keywords_seed"
        required
        label={
          <FormattedMessage
            id="apps.forsant.businessDetails.keywordSuggestion"
            defaultMessage="Keyword suggestion"
          />
        }
        submitOnChange
      >
        <SelectWithSuggestions
          mode="multiple"
          callAPI={getKeywords}
          adjustValue={(v) => ({
            value: v.label,
            label: v.label,
            disabled: false,
          })}
          adjustSuggestion={(s) => ({
            value: s.text,
            label: s.text,
            disabled: false,
          })}
          uniqueValueProp="label"
          allowCustomValue={true}
        />
      </RelaxField>

      <RelaxField
        initialValuesSelector={AiProjectInfoSelector}
        callAPI={UpdateAIProjectInfo}
        name={["info", "language"]}
        label={
          <FormattedMessage
            id="apps.smart.components.aiSettings.info.language.label"
            defaultMessage="Language"
          />
        }
      >
        <Input />
      </RelaxField>

      <RelaxField
        name="website"
        label={
          <FormattedMessage
            id="apps.smart.forms.businessDetails.websiteAddress"
            defaultMessage="Your website address"
          />
        }
        initialValuesSelector={SmartSelector}
        callAPI={{
          //url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smarts/${smartId}`, 2),
          url: ({ getApiUrl, projectId }) =>
            getApiUrl(`projects/${projectId}/smart`, 1),
          method: "PATCH",
          successDispatch: smartSlice.actions.get,
        }}
        //finalizeSubmit={(values) => ({ ...values, project_id: projectId })}
        rules={[ruleForbiddenDomains(["facebook.com", "instagram.com", "fb.com"])]}
      >
        <URLInput />
      </RelaxField>

      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fullfilled}
      />
    </>
  );
};

export default OnboardingAIBasicInfo;
