import { HorizontalCenter } from "components/common/Center";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { Select, Spin } from "antd";
import { useEffect, useMemo } from "react";
import { timeout } from "services/helpers/fake-asset-generators";
import FormattedMessage from "components/common/FormattedMessage";

type FacebookCampaign = {
    id: number;
    special_type: string;
    facebook_campaigns_bases_id: number;
    error: string | null;
    external_id: string;
    status: string;
    promoted_object: any | null;
    created_at: string | null;
    updated_at: string | null;
    laravel_through_key: number;
    name: string;
    type: string;
    objective: string;
    daily_budget: number;
}

type FacebookCampaignSelectProps = {
    value?: string; 
    onChange?: (value: string) => void;
    type?: string
    onBlur?: () => void;
}

const FacebookCampaignSelect = (props: FacebookCampaignSelectProps) => {

    const campaignCall = useAPI({
        url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/facebook/campaigns`, 1),
    }, true)

    const campaigns = useMemo(() => {
        if (!campaignCall?.data) return [];
        return campaignCall.data.filter((campaign: FacebookCampaign) => campaign.type === props.type).map((campaign: FacebookCampaign) => ({
            value: campaign.id,
            label: campaign.name
        }));
    }, [campaignCall.data]);


    if (campaignCall.error) return <APIErrorDisplay error={campaignCall.error} />
    if (campaignCall.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    if (campaigns.length === 0) return (
        <FormattedMessage
            id="tools.creationEditor.facebookCampaignSelect.noCampaigns"
            defaultMessage="No campaigns found"
        />
    )

    return (
        <Select options={campaigns} value={props.value} onChange={props.onChange} onBlur={props.onBlur}/>
    )

}

export default FacebookCampaignSelect;
