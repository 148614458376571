import { Collapse, Input, Select, Space, Spin } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import { AiProjectInfoSelector, GetAiProjectInfo, UpdateAIProjectInfo } from "modules/panel/state/AISlice";
import { useEffect, useRef, useState } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { GenerateProjectKeywords, GetCustomization, GetProjectKeywords, ProjectKeywordsSelector, ProjectKeywordsSelectorByType, ProjectReviewsCustomizationSelector, UpdateCustomization, UpdateProjectKeywords } from "state/callAppSlice";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import Checkbox from "components/inputs/Checkbox";
import { currentSubscriptionIdSelector } from "services/store/scopeSlice";
import { addPermissionToSubscription } from "state/subscriptions/subscriptionsSlice";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import TranslationInput from "components/inputs/translationInput/TranslationInput";
import ColorPicker from "components/inputs/ColorPicker";
import RelaxList from "tools/relaxForm/RelaxList";
import { HorizontalCenter } from "components/common/Center";

const AddGoogleIntegration = () => {

    const subscription = useSelectorWithParams(currentSubscriptionIdSelector);
    const addGoogleIntegrationCall = useAPI(addPermissionToSubscription("addGoogleMyBusiness", subscription));
    const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);

    const handleAddGoogleIntegration = () => {
        if (subscription) {
            addGoogleIntegrationCall.call().then(() => window.location.reload())
        }
    }

    if (permissions?.includes("use integration google myBusiness")) {
        return null;
    }

    return (
        <StandardButton
            loading={addGoogleIntegrationCall.loading}
            onClick={handleAddGoogleIntegration}>
            <FormattedMessage
                id="apps.callApp.components.reviewsCustomization.addGoogleIntegration"
                defaultMessage="Add Google Integration"
            />
        </StandardButton>
    )

}

const SEOKeywordsEditor = () => {

    const generateKeywordsCall = useAPI(GenerateProjectKeywords("seo"));
    const keywords = useSelectorWithParams(ProjectKeywordsSelectorByType("seo"));

    const updateKeywordsCall = useAPI(UpdateProjectKeywords);

    const handleRegenerateKeywords = () => {
        generateKeywordsCall.call().then(r => {
            if (r?.data?.all_seo_keywords) {
                updateKeywordsCall.call({
                    body: {
                        selected_seo_keywords: r?.data?.all_seo_keywords
                    }
                })
            }
        })
    }

    return (
        <>
            <StandardRow>
                <StandardFullColumn>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, width: "100%", maxWidth: "800px", justifyContent: "space-between", alignItems: "center" }}>
                        <h1>
                            <FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.seoKeywords.title"
                                defaultMessage="SEO Keywords"
                            />
                        </h1>
                        <StandardButton
                            onClick={handleRegenerateKeywords}
                            type="primary"
                            disabled={generateKeywordsCall.loading}
                            loading={generateKeywordsCall.loading}
                        >
                            <FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.seoKeywords.regenerateKeywords"
                                defaultMessage="Regenerate Keywords"
                            />
                        </StandardButton>
                    </div>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <RelaxList
                    name={["selected_seo_keywords"]}
                    initialValuesSelector={ProjectKeywordsSelector}
                    callAPI={UpdateProjectKeywords}
                    shouldReset={true}
                    horizontal={true}
                >
                    <Input style={{ maxWidth: "100%", width: "460px" }} />
                </RelaxList>
            </StandardRow>
        </>
    )
}

const OpinionKeywordsEditor = () => {

    const generateKeywordsCall = useAPI(GenerateProjectKeywords("opinion"));
    const keywords = useSelectorWithParams(ProjectKeywordsSelectorByType("opinion"));
    const updateKeywordsCall = useAPI(UpdateProjectKeywords);

    const handleRegenerateKeywords = () => {
        generateKeywordsCall.call().then(r => {
            if (r?.data?.all_opinion_keywords) {
                updateKeywordsCall.call({
                    body: {
                        selected_opinion_keywords: r?.data?.all_opinion_keywords
                    }
                })
            }
        })
    }

    return (
        <>
            <StandardRow>
                <StandardFullColumn>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, width: "100%", maxWidth: "800px", justifyContent: "space-between", alignItems: "center" }}>
                        <h1>
                            <FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.opinionKeywords.title"
                                defaultMessage="Opinion Keywords"
                            />
                        </h1>
                        <StandardButton
                            onClick={handleRegenerateKeywords}
                            disabled={generateKeywordsCall.loading}
                            loading={generateKeywordsCall.loading}
                            type="primary"
                        >
                            <FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.opinionKeywords.regenerateKeywords"
                                defaultMessage="Regenerate Keywords"
                            />
                        </StandardButton>
                    </div>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <RelaxList
                    name={["selected_opinion_keywords"]}
                    initialValuesSelector={ProjectKeywordsSelector}
                    callAPI={UpdateProjectKeywords}
                    shouldReset={true}
                    horizontal={true}
                >
                    <Input style={{ maxWidth: "100%", width: "460px" }} />
                </RelaxList>
            </StandardRow>
        </>
    )
}

const ProjectInfo = () => {
    return (
        <>
            <RelaxMultiform
                initialValuesSelector={AiProjectInfoSelector}
                callAPI={UpdateAIProjectInfo}
            >
                <StandardRow style={{ marginBottom: 20 }}>
                    <StandardFullColumn>
                        <h1><FormattedMessage
                            id="apps.callApp.components.reviewsCustomization.projectInfo.title"
                            defaultMessage="Project Info"
                        /></h1>
                        <span>
                            <FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.projectInfo.description"
                                defaultMessage="This is the project info that will be used to generate SEO optimized reviews."
                            />
                        </span>

                    </StandardFullColumn>
                </StandardRow>
                <StandardRow>
                    <StandardHalfFullColumn>
                        <RelaxField
                            name={["info", "language"]}
                            label={<FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.projectInfo.language.label"
                                defaultMessage="Language"
                            />}
                        >
                            <Input />
                        </RelaxField>
                    </StandardHalfFullColumn>
                </StandardRow>
                <StandardRow>
                    <StandardHalfFullColumn>
                        <RelaxField
                            name={["location", "city"]}
                            label={<FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.projectInfo.location.label"
                                defaultMessage="Location"
                            />}
                        >
                            <Input />
                        </RelaxField>
                    </StandardHalfFullColumn>
                </StandardRow>
                <StandardRow>
                    <StandardFullColumn>
                        <RelaxField
                            name={"company_description"}
                            label={<FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.projectInfo.companyDescription.label"
                                defaultMessage="Company Description"
                            />}
                        >
                            <Input.TextArea />
                        </RelaxField>
                    </StandardFullColumn>
                </StandardRow>
                <StandardRow>
                    <StandardFullColumn>
                        <RelaxField
                            name={"client_description"}
                            label={<FormattedMessage
                                id="apps.callApp.components.reviewsCustomization.projectInfo.clientDescription.label"
                                defaultMessage="Client Description"
                            />}
                        >
                            <Input.TextArea />
                        </RelaxField>
                    </StandardFullColumn>
                </StandardRow>
            </RelaxMultiform>
        </>
    )
}

const LandingPageCustomization = () => {
    return (
        <RelaxMultiform
            name="customization"
            callAPI={UpdateCustomization}
            initialValuesSelector={ProjectReviewsCustomizationSelector}
        >
            <RelaxField
                name={["customization", "text", "welcomeMessage"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.welcomeMessage"
                    defaultMessage="Welcome Message"
                />}
            >
                <TranslationInput languages={["PL", "EN"]} />
            </RelaxField>
            <RelaxField
                name={["customization", "text", "thankYouAfterGoodRatingMessage"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.thankYouAfterGoodRatingMessage"
                    defaultMessage="Thank you message after user selects a good rating"
                />}
            >
                <TranslationInput languages={["PL", "EN"]} />
            </RelaxField>
            <RelaxField
                name={["customization", "text", "feedbackFormBadRatingMessage"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.feedbackFormBadRatingMessage"
                    defaultMessage="Text above the form if users leaves a bad rating"
                />}
            >
                <TranslationInput languages={["PL", "EN"]} />
            </RelaxField>
            <RelaxField
                name={["customization", "text", "feedbackFormNoButtonsMessage"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.feedbackFormNoButtonsMessage"
                    defaultMessage="Text above the form if there are no links to review platforms."
                />}
            >
                <TranslationInput languages={["PL", "EN"]} />
            </RelaxField>
            <RelaxField
                name={["customization", "text", "feedbackFormThankYouMessage"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.feedbackFormThankYouMessage"
                    defaultMessage="Thank you message after user submits the form"
                />}
            >
                <TranslationInput languages={["PL", "EN"]} />
            </RelaxField>
            <RelaxField
                name={["customization", "colors", "primary"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.themeColor"
                    defaultMessage="Theme Color"
                />}
                initialValue="#3b7ad9" 
            >
                <ColorPicker style={{ width: "200px" }} />
            </RelaxField>
            <RelaxField
                name={["customization", "colors", "stars"]}
                label={<FormattedMessage
                    id="apps.callApp.components.reviewsCustomization.translations.starColor"
                    defaultMessage="Star Color"
                />}
                initialValue="#f0b01a"
            >
                <ColorPicker style={{ width: "200px" }} />
            </RelaxField>
        </RelaxMultiform>

    )
}



const ReviewsCustomization = () => {

    const customizationCall = useAPI(GetCustomization, true);
    const customization = useSelectorWithParams(ProjectReviewsCustomizationSelector);
    const aiSettingsCall = useAPI(GetAiProjectInfo, true)
    const getKeywordsCall = useAPI(GetProjectKeywords, true);
    const updateCustomizationCall = useAPI(UpdateCustomization, true);

    if (customizationCall.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    return (
        <>
            <AddGoogleIntegration />
            <div>
                <p>
                    <FormattedMessage
                        id="apps.callApp.components.reviewsCustomization.enableCustomization.description"
                        defaultMessage="Would you like to enable AI review generation for this project?"
                    />
                </p>
                <Checkbox checked={customization?.customization?.ai_enabled} onChange={(v) => {
                    updateCustomizationCall.call({
                        body: {
                            customization: {
                                ai_enabled: v
                            }
                        }
                    })
                }}>
                    <FormattedMessage
                        id="apps.callApp.components.reviewsCustomization.enableCustomization"
                        defaultMessage="Enable AI review generation"
                    />
                </Checkbox>

            </div>
            <Collapse>
                <Collapse.Panel header="Project Info" key="3" collapsible={customization?.customization?.ai_enabled ? "header" : "disabled"}>
                    {customization?.customization?.ai_enabled && <ProjectInfo />}
                </Collapse.Panel>
                <Collapse.Panel header="SEO Keywords" key="1" collapsible={customization?.customization?.ai_enabled ? "header" : "disabled"}>
                    {customization?.customization?.ai_enabled && <SEOKeywordsEditor />}
                </Collapse.Panel>
                <Collapse.Panel header="Opinion Keywords" key="2" collapsible={customization?.customization?.ai_enabled ? "header" : "disabled"}>
                    {customization?.customization?.ai_enabled && <OpinionKeywordsEditor />}
                </Collapse.Panel>
                <Collapse.Panel header="Landing Page Customization" key="4">
                    <LandingPageCustomization />
                </Collapse.Panel>
            </Collapse>
        </>
    )
}

export default ReviewsCustomization;
