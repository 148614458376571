import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
export default function AppDownloadInstructions() {

    return (<>


        <h2>
            <FormattedMessage
                id="panel.containers.onboarding.details.download.heading"
                defaultMessage="You can download app here"
            />
        </h2>

        <StandardButton
            href={import.meta.env.VITE_CALL_APP_DOWNLOAD_LINK}
            type="primary"
            target="_blank"
        >
            <FormattedMessage
                id="panel.containers.onboarding.details.download.btn"
                defaultMessage="Click to download"
            />
        </StandardButton>
    </>
    )

}