const generateRandomObjects = (count:number, start:Date, end:Date) => {
  const objects = [];
  const idSet = new Set(); // to keep track of unique ids
  
  for (let i = 0; i < count; i++) {
    let id;
    do {
      id = Math.floor(Math.random() * 100000); // generate a random integer between 0 and 99999
    } while (idSet.has(id)); // check if the id already exists in the set
    idSet.add(id); // add the unique id to the set
    
    const created_at = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())); // generate a random date between start and end
    
    const obj = { id, created_at };
    objects.push(obj);
  }
  
  return objects;
}


export const fakeDates = [
  {"id": 76542, "created_at": "2025-03-12T12:58:29.321674"},
  {"id": 39842, "created_at": "2025-03-13T18:32:41.459874"},
  {"id": 84321, "created_at": "2025-03-14T10:15:34.923845"},
  {"id": 98716, "created_at": "2025-03-15T19:47:28.564872"},
  {"id": 69312, "created_at": "2025-03-16T17:20:42.321987"},
  {"id": 42981, "created_at": "2025-03-17T07:58:12.932654"},
  {"id": 17543, "created_at": "2025-03-18T07:20:14.432198"},
  {"id": 73149, "created_at": "2025-03-19T08:54:13.489765"},
  {"id": 74512, "created_at": "2025-03-20T21:37:19.432678"},
  {"id": 41987, "created_at": "2025-03-20T09:28:34.125764"},
  {"id": 79312, "created_at": "2025-03-21T13:45:17.879654"},
  {"id": 74158, "created_at": "2025-03-22T23:12:48.231456"},
  {"id": 75982, "created_at": "2025-03-23T16:37:58.564321"},
  {"id": 48126, "created_at": "2025-03-24T10:57:49.675432"},
  {"id": 24789, "created_at": "2025-03-25T16:41:37.453218"},
  {"id": 56781, "created_at": "2025-03-26T11:10:59.982137"},
  {"id": 26514, "created_at": "2025-03-27T14:09:46.875342"},
  {"id": 63149, "created_at": "2025-03-28T14:02:58.765123"},
  {"id": 61532, "created_at": "2025-03-29T11:40:28.986754"},
  {"id": 98124, "created_at": "2025-03-30T12:14:45.123789"},
  {"id": 65832, "created_at": "2025-03-31T06:35:47.123876"},
  {"id": 45781, "created_at": "2025-04-01T15:27:56.345218"},
  {"id": 63429, "created_at": "2025-04-01T08:22:44.658923"},
  {"id": 54892, "created_at": "2025-04-02T06:28:49.345189"},
  {"id": 31854, "created_at": "2025-04-02T06:11:32.459876"},
  {"id": 26589, "created_at": "2025-04-03T15:09:17.874356"},
  {"id": 24398, "created_at": "2025-04-04T19:22:31.432654"},
  {"id": 23948, "created_at": "2025-04-05T18:54:32.654987"},
  {"id": 64298, "created_at": "2025-04-05T19:45:21.542698"},
  {"id": 41857, "created_at": "2025-04-06T23:49:30.875429"},
  {"id": 31974, "created_at": "2025-04-07T03:12:23.457298"},
  {"id": 24738, "created_at": "2025-04-07T10:25:14.986754"},
  {"id": 27389, "created_at": "2025-04-08T16:12:43.745321"},
  {"id": 47269, "created_at": "2025-04-09T13:11:29.876543"},
  {"id": 86153, "created_at": "2025-04-09T01:43:58.765419"},
  {"id": 61598, "created_at": "2025-04-10T14:37:25.764892"},
  {"id": 82741, "created_at": "2025-04-11T18:23:45.654398"},
  {"id": 94621, "created_at": "2025-04-12T16:10:55.654231"},
  {"id": 38195, "created_at": "2025-04-12T04:50:39.764985"},
  {"id": 58162, "created_at": "2025-04-13T09:34:52.785439"},
  {"id": 98172, "created_at": "2025-04-14T22:54:12.783245"},
  {"id": 74216, "created_at": "2025-04-14T20:57:39.432198"},
  {"id": 89754, "created_at": "2025-04-15T05:23:31.214567"},
  {"id": 89243, "created_at": "2025-04-15T03:59:58.431765"},
  {"id": 24698, "created_at": "2025-04-16T21:09:47.764325"},
  {"id": 97412, "created_at": "2025-04-17T11:34:52.678932"},
  {"id": 19573, "created_at": "2025-04-18T05:52:13.985274"},
  {"id": 25871, "created_at": "2025-04-19T17:31:50.879543"},
  {"id": 56372, "created_at": "2025-04-20T09:32:15.987123"},
  {"id": 31987, "created_at": "2025-04-21T09:57:33.654928"},
  {"id": 13589, "created_at": "2025-04-22T12:58:54.654321"},
  {"id": 85947, "created_at": "2025-04-23T10:45:51.456781"},
  {"id": 36542, "created_at": "2025-04-24T22:09:34.678541"},
  {"id": 85962, "created_at": "2025-04-25T14:39:42.564789"},
  {"id": 51329, "created_at": "2025-04-26T09:20:38.981234"},
  {"id": 98235, "created_at": "2025-04-27T07:10:28.432764"},
  {"id": 38561, "created_at": "2025-04-28T05:39:21.876345"},
  {"id": 53178, "created_at": "2025-04-28T23:59:21.198723"},
  {"id": 94213, "created_at": "2025-04-29T19:41:12.987231"},
  {"id": 82437, "created_at": "2025-04-30T04:33:18.543219"},
  {"id": 53214, "created_at": "2025-04-30T11:23:54.765423"}
]


export const generateRandomDates = (startDate:any, endDate:any, n:number) => {
  const dates = [];
  for (let i = 0; i < n; i++) {
    const date = new Date(
      startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())
    );
    dates.push(date);
  }
  return dates.sort((a, b) => a.getTime() - b.getTime()).map((date) => ({
    id: Math.floor(Math.random() * 100000),
    created_at: date.toISOString()
  }));
};
