import { Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import { FreshMailCampaignSelect } from "./integrationSelectors/FreshMailCampaignSelect";
import { SmsApiCampaignSelect } from "./integrationSelectors/SmsApiCampaignSelect";
import { MailerLiteCampaignSelect } from "./integrationSelectors/MailerLiteCampaignSelect";
import RelaxField from "tools/relaxForm/RelaxField";
import TextArea from "antd/lib/input/TextArea";

type botInputsConfigProps = {
    hideStatus?:boolean;
    style?: any;
    name?: string | (string | number)[];
    integration?:string;
    bot?:any
}

export const SimpleReplyConfig = (props: botInputsConfigProps) => {
    return (
        <>
            <div>
                <RelaxField
                    name="message"
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.welcomeMessage"
                            defaultMessage="Welcome message"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.welcomeMessage.tooltip"
                            defaultMessage="Welcome message"
                        />
                    }
                    shouldReset={true}
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
        </>
    );
}


export const ExtendMailConfig = (props: botInputsConfigProps) => {
    return (
        <>
            <div>
                <RelaxField
                    name={["confirmations", 0, "message"]}
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.welcomeMessage"
                            defaultMessage="Welcome message"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.welcomeMessage.tooltip"
                            defaultMessage="Welcome message"
                        />
                    }
                    shouldReset={true}
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name={["confirmations", 0, "keyword"]}
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.confirmation"
                            defaultMessage="Confirmation word"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.confirmation.tooltip"
                            defaultMessage="Confirmation word"
                        />
                    }
                    shouldReset={true}
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name={["confirmations", 0, "error_message"]}
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.confirmationError"
                            defaultMessage="Confirmation error message"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.confirmationError.tooltip"
                            defaultMessage="Confirmation error message"
                        />
                    }
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name="message"
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.lead"
                            defaultMessage="Lead message"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.lead.tooltip"
                            defaultMessage="Lead message"
                        />
                    }
                    shouldReset={true}
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name="success_message"
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.thankYouMessage"
                            defaultMessage="Thank you message"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.thankYouMessage.tooltip"
                            defaultMessage="Thank you message"
                        />
                    }
                    shouldReset={true}
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name="error_message"
                    required
                    label={
                        <FormattedMessage
                            id="modules.bot.form.error"
                            defaultMessage="Error message"
                        />
                    }
                    tooltip={
                        <FormattedMessage
                            id="modules.bot.form.error.tooltip"
                            defaultMessage="Error message"
                        />
                    }
                    shouldReset={true}
                    hideStatus={props.hideStatus}
                >
                    <Input />
                </RelaxField>
            </div>
        </>
    )
}

export const SimplePreviewConfig = (props: botInputsConfigProps) => {


    return (
        <>
            <div>
                <RelaxField
                    name="message"
                    style={props.style}
                    wrapperClass="message side-left"
                    shouldReset={true}
                    hideStatus={true}
                    initialValue={props?.bot?.message}
                >
                    <TextArea />
                </RelaxField>
            </div>
        </>
    )
}

export const ExtendPreviewConfig = (props: botInputsConfigProps) => {
    return (
        <>
            <div>
                <RelaxField
                    name={["confirmations", 0, "message"]}
                    style={props.style}
                    wrapperClass="message side-left"
                    shouldReset={true}
                    hideStatus={true}
                    initialValue={props?.bot?.confirmations?.[0]?.message}
                >
                    <TextArea />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name={["confirmations", 0, "keyword"]}
                    style={props.style}
                    wrapperClass="message side-right"
                    shouldReset={true}
                    hideStatus={true}
                    initialValue={props?.bot?.confirmations?.[0]?.keyword}
                >
                    <TextArea />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name="message"
                    style={props.style}
                    wrapperClass="message side-left"
                    shouldReset={true}
                    hideStatus={true}
                    initialValue={props?.bot?.message}
                >
                    <TextArea />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name="content"
                    style={props.style}
                    wrapperClass="message side-right"
                    initialValue={props.integration === 'fresh-mail' || props.integration === 'mailer-lite'  ? "daniel@fasttony.es" : " +48 500 500 500"}
                >
                    <TextArea />
                </RelaxField>
            </div>
            <div>
                <RelaxField
                    name="success_message"
                    style={props.style}
                    wrapperClass="message side-left"
                    shouldReset={true}
                    hideStatus={true}
                    initialValue={props?.bot?.success_message}
                >
                    <TextArea />
                </RelaxField>
            </div>
        </>
    )
}


export const FreshMailConfig = (props: botInputsConfigProps) => {
    return (
        <>
            <ExtendMailConfig hideStatus={props.hideStatus} />
            <div style={{ display: 'flex' }}>
                <div style={{ width: '99%' }}>
                    <RelaxField
                        name={props.name}
                        required
                        style={{ marginRight: "25px" }}
                        label={
                            <FormattedMessage
                                id="modules.bot.form.mailingList"
                                defaultMessage="Mailing list"
                            />
                        }
                        hideStatus={props.hideStatus}
                    >
                        <FreshMailCampaignSelect />
                    </RelaxField>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <TooltipIcon title={
                        <FormattedMessage
                            id="modules.bot.form.mailingList.tooltip"
                            defaultMessage="Mailing list"
                        />
                    } />
                </div>
            </div>
        </>
    )
}

export const SmsApiConfig = (props: botInputsConfigProps) => {
    return (
        <>
            <ExtendMailConfig hideStatus={props.hideStatus} />
            <div style={{ display: 'flex' }}>
                <div style={{ width: '99%' }}>
                    <RelaxField
                        name={props.name}
                        required
                        style={{ marginRight: "25px" }}
                        label={
                            <FormattedMessage
                                id="modules.bot.form.smsCampaign"
                                defaultMessage="SMS Campaign"
                            />
                        }
                        hideStatus={props.hideStatus}
                    >
                        <SmsApiCampaignSelect />
                    </RelaxField>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <TooltipIcon title={
                        <FormattedMessage
                            id="modules.bot.form.smsCampaign.tooltip"
                            defaultMessage="SMS Campaign"
                        />
                    } />
                </div>
            </div>
        </>
    )
}


export const MailerLiteConfig = (props: botInputsConfigProps) => {

    return (
        <>
            <ExtendMailConfig hideStatus={props.hideStatus} />
            <div style={{ display: 'flex' }}>
                <div style={{ width: '99%' }}>
                    <RelaxField
                        name={props.name}
                        required
                        style={{ marginRight: "25px" }}
                        label={
                            <FormattedMessage
                                id="modules.bot.form.mailerLiteCampain"
                                defaultMessage="Mailer Lite Campain"
                            />
                        }
                        hideStatus={props.hideStatus}
                    >
                        <MailerLiteCampaignSelect />
                    </RelaxField>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <TooltipIcon title={
                        <FormattedMessage
                            id="modules.bot.form.mailerLiteCampain.tooltip"
                            defaultMessage="Mailer Lite Campain"
                        />
                    } />
                </div>
            </div>
        </>
    )
}

