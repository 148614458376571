import { faArrowRotateRight, faExclamation, faEye, faHourglass, faPenToSquare, faPlay } from "@fortawesome/pro-light-svg-icons";
import ButtonGroup from "antd/es/button/button-group";
import StandardButton from "components/common/StandardButton";
import { useMemo } from "react";
import { usePost } from "../postContext";
import { GetTranslatedActionString } from "../postTranslatedStrings";
import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";

export function PostStateAndPromoteButton(props: any) {

    const { post, openDetails } = usePost();
    const { promotionStatus } = post.getGeneratedData();

    const buttonText = useMemo(() => {
        if (!promotionStatus) return GetTranslatedActionString("promote");
        if (promotionStatus === "ended") return GetTranslatedActionString("renew");
        if (promotionStatus === "paused") return GetTranslatedActionString("edit");
        if (promotionStatus === "pending") return GetTranslatedActionString("edit");
        if (promotionStatus === "active") return GetTranslatedActionString("edit");
        if (promotionStatus === "error") return GetTranslatedActionString("retry");
        if (promotionStatus === "disapproved") return GetTranslatedActionString("retry");
        if (promotionStatus === "inactive") return GetTranslatedActionString("promote");
        if (promotionStatus === "notPromoted") return GetTranslatedActionString("promote");
        if (promotionStatus === "with_issues") return GetTranslatedActionString("retry");
        return GetTranslatedActionString("promote");
    }, [promotionStatus])

    const buttonIcon = useMemo(() => {
        if (!promotionStatus) return faPlay;
        if (promotionStatus === "ended") return faArrowRotateRight;
        if (promotionStatus === "paused") return faPlay;
        if (promotionStatus === "pending") return faHourglass;
        if (promotionStatus === "active") return faPenToSquare;
        if (promotionStatus === "error") return faExclamation;
        if (promotionStatus === "with_issues") return faArrowRotateRight;
        return faPlay;
    }, [promotionStatus])

    return (
        <div className="state-and-promote post">
            <DisplayIfHasPermission permission="use module smart ads">
                <ButtonGroup className="main-action-group">
                    <StandardButton
                        icon={buttonIcon}
                        className="main-action-icon"
                        iconProps={{ size: "2xl" }}
                        onClick={(e: any) => { e.preventDefault(); openDetails({ page: "promote" }) }}
                    />
                    <StandardButton
                        className="main-action-button"
                        onClick={(e: any) => { e.preventDefault(); openDetails({ page: "promote" }) }}
                    >
                        {buttonText}
                    </StandardButton>
                </ButtonGroup>
            </DisplayIfHasPermission>
            <StandardButton
                icon={faEye}
                iconProps={{ size: "2xl" }}
                onClick={(e: any) => { e.preventDefault(); openDetails({ page: "details" }) }}
            />
        </div>
    )

}

export function AdStateAndPromoteButton(props: any) {

    const { post } = usePost();
    const adStatus = post.getAdStatus(props.ad);

    const buttonText = useMemo(() => {
        if (!adStatus) return GetTranslatedActionString("promote");
        if (adStatus === "ended") return GetTranslatedActionString("renew");
        if (adStatus === "paused") return GetTranslatedActionString("edit");
        if (adStatus === "pending") return GetTranslatedActionString("edit");
        if (adStatus === "active") return GetTranslatedActionString("edit");
        if (adStatus === "error") return GetTranslatedActionString("retry");
        if (adStatus === "disapproved") return GetTranslatedActionString("retry");
        if (adStatus === "inactive") return GetTranslatedActionString("promote");
    }, [adStatus])

    const buttonAction = useMemo(() => {
        if (!adStatus) return { page: "promote", ad: props.ad };
        if (adStatus === "ended") return { page: "duration", ad: props.ad };
        if (adStatus === "paused") return { page: "promote", ad: props.ad };
        if (adStatus === "active") return { page: "duration", ad: props.ad };
        if (adStatus === "inactive") return { page: "promote", ad: props.ad };
        if (adStatus === "error") return { page: "promote", ad: props.ad };
        if (adStatus === "disapproved") return { page: "promote", ad: props.ad };
        return { ad: props.ad };
    }, [adStatus])

    const buttonIcon = useMemo(() => {
        if (!adStatus) return faPlay;
        if (adStatus === "ended") return faArrowRotateRight;
        if (adStatus === "paused") return faPlay;
        if (adStatus === "pending") return faHourglass;
        if (adStatus === "active") return faPenToSquare;
        if (adStatus === "error") return faExclamation;
        return faPlay;
    }, [adStatus])

    return (
        <div className="state-and-promote ad">
            <ButtonGroup className="main-action-group">
                <StandardButton
                    icon={buttonIcon}
                    className="main-action-icon"
                    iconProps={{ size: "2xl" }}
                    onClick={(e: any) => props.query.handleOpen(e, buttonAction)}
                />
                <StandardButton
                    className="main-action-button"
                    onClick={(e: any) => props.query.handleOpen(e, buttonAction)}
                >
                    {buttonText}
                </StandardButton>
            </ButtonGroup>
        </div>
    )

}
