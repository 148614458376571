import { createSelector } from "@reduxjs/toolkit";

export const loginStatusSelector = (state) => state.loginStatus;

export const userIsLoggedSelector = createSelector(
  loginStatusSelector,
  (loginStatus) => loginStatus.get("logged")
);

export const userTokenSelector = createSelector(
  loginStatusSelector,
  (loginStatus) => loginStatus.getIn(["token", "value"])
);

export const userSelector = (state) => state.user;

export const userIdSelector = createSelector(userSelector, (user) =>
  user.get("id")
);

export const userLanguageSelector = createSelector(userSelector, (user) =>
  user.get("language") || "en"
);

export const userEmailSelector = createSelector(userSelector, (user) =>
  user.get("email")
);

export const userCustomEmailsSelector = createSelector([userSelector], (user) =>
  user.get("emails")
)

export const userPhoneSelector = createSelector(
  userSelector,
  (user) => user.get("phone")
);

export const userHasFlagSelector = createSelector(
  [
    userSelector,
    (state, flag) => flag,
  ], (user, flag) => {

    if (!user) return false;
    if (!flag) return false;
    if (!user.get("flags")) return false;

    const flags = user.get("flags") && user.get("flags").toJS() || [];
    return flags.includes(flag);

  }
);

export const userCustomEmailsOfTypeSelector = createSelector([
  userCustomEmailsSelector,
  (state, emailType) => emailType,
], (emails, emailType) => {
  if (!emails) return [];
  const allEmails = emails ? Object.values(emails.toJS()) : []
  const filtered = allEmails.filter(e => {
    if (!e) return false;
    return e.toJS ? e.toJS().type === emailType : e.type === emailType})
  return filtered
})

export const userHasAtLeastOneCriticalEmailSelector = createSelector(
  userCustomEmailsSelector,
  (emails) => {
    if (!emails) return false;
    const allEmails = emails ? Object.values(emails.toJS()) : []
    const filtered = allEmails.filter(e => {
      if (!e) return false;
      return e.toJS ? e.toJS().type === "critical" : e.type === "critical"})
    return filtered.length > 0
  }
);

export const userCustomEmailSelector = createSelector([userCustomEmailsSelector,
  (state, emailType) => emailType],
  (emails, emailType) => {
    if (!emails) {
      return;
    }
    const filtered = emails.filter(e => e.toJS ? e.toJS().type === emailType : e.type === emailType)?.first()
    if (filtered) {
      return filtered.toJS()
    }
    return
  }
)

export const userHasPhoneVerifySelector = createSelector(
  userSelector,
  (user) => !!user.get("phone") && !!user.get("phone_verified_at")
);

export const userHasPhoneNumberSelector = createSelector(
  userSelector,
  (user) => !!user.get("phone")
);

export const userFirstNameSelector = createSelector(
  userSelector,
  (user) => user.get("first_name") || ""
);

export const userNameSelector = createSelector(
  userSelector,
  (user) => `${user.get("first_name") || ""} ${user.get("last_name") || ""}`
);

export const hiddenEmailSelector = createSelector(userSelector, (user) =>
  user.get("changePasswordHiddenEmail")
);

export const userAvatarSelector = createSelector(userSelector, (user) =>
  user.get("image")
);

export const userStatementsSelector = createSelector(userSelector, (user) =>
  user.get("statements")
);

export const userLegalContentsSelector = createSelector(userSelector, (user) =>
  user.get("legalContents")
);

export const userNewestTermsOfService = createSelector(userLegalContentsSelector, (legalContents) => {

  try {

  const legal = legalContents.toJS();
  const tos = legal.filter((content) => content.type === "terms_of_service");
  const newestVersionTos = tos.sort((a, b) => a.version < b.version)[0];
  return newestVersionTos;

  } catch (e) {
    console.error("Error in userNewestTermsOfService", e);
  }

  return null;

});

export const userMarketingStatement = createSelector(
  userStatementsSelector,
  (statements) => statements.get("marketing_consents")
);

export const userHasMarketingStatement = createSelector(
  userMarketingStatement,
  () => true,
  //(marketingStatement) => !!marketingStatement
);

export const userHasNewestTermsOfServicesStatement = createSelector(
  userStatementsSelector,
  userNewestTermsOfService,
  (statements, newestVersionTos) => {
  
    const newest = newestVersionTos;
    const current = statements?.get("terms_of_service");

    if (!current) return false;
    if (!newest) return false;

    return current.get("version") >= newest.version;

    //(statements) => statements.get("terms_of_service")
});



export const userBusinessPurposesStatement = createSelector(
  userStatementsSelector,
  (statements) => statements.get("business_purposes")
);

export const userHasBusinessPurposesStatement = createSelector(
  userBusinessPurposesStatement,
  (businessPurposesStatement) => !!businessPurposesStatement
);

export const statementsContentSelector = createSelector(userSelector, (user) =>
  user.get("statementsContent")
);

export const termsOfServiceStatementContentSelector = createSelector(
  statementsContentSelector,
  (contents) => contents.get("termsOfService")
);

export const marketingConsentsContentSelector = createSelector(
  statementsContentSelector,
  (contents) => contents.get("marketingConsents")
);

export const userAccessTokensSelector = createSelector(
  userSelector, (user) => user.get("accessTokens")
)

export const providerAccessTokenSelector = createSelector([
  userAccessTokensSelector,
  (state, provider) => provider],
  (tokens, provider) => {
    console.log("token", tokens.get(provider))
    return tokens.get(provider)
  }
)

// Temporaty providers Selectors

export const userIntegrationsSelector = createSelector(
  userSelector, (user) => user.get("integrations")
)

export const userHasAuthorizedFacebook = createSelector([
  userAccessTokensSelector,
  (state, facebook) => facebook],
  (facebook) => {
    if (facebook.toJS().facebook && facebook.toJS().facebook.permissions === "granted") {
      return true
    } else {
      return false
    }
  }
)

export const userFacebookDeclinedPermissions = createSelector([
  userAccessTokensSelector,
  (state, facebook) => facebook],
  (facebook) => {
    if (facebook.toJS().facebook && facebook.toJS().facebook.permissions === "declined") {
      return JSON.parse(facebook.toJS().facebook.permissions_data)
        .filter(permission => permission.status === "declined")
        .map(permission => [permission.permission])
    }
    return [];
  }
)

export const userHasAuthorizedGoogle = createSelector([
  userAccessTokensSelector,
  (state, google) => google],
  (google) => {
    if (google.toJS().google && google.toJS().google.permissions === "granted") {
      return true
    } else {
      return false
    }
  }
)

export const userGooglekDeclinedPermissions = createSelector([
  userAccessTokensSelector,
  (state, google) => google],
  (google) => {
    if (google.toJS().google && google.toJS().google.permissions === "declined") {
      return JSON.parse(google.toJS().google.permissions_data)
          .filter((permission) => permission.status === "declined")
          .map((permission) =>[permission.permission.split("/auth/")[1].replace(/\./g, "_")])
    }
    return [];
  }
)

export const userHasAuthorizedPlatforms = (providers) =>
  createSelector(
    userHasAuthorizedFacebook,
    userHasAuthorizedGoogle,
    (facebook, google) => {
      //TODO: it should take authorized from integrations config.

      if (providers.includes("facebook") && facebook === false) return false;
      if (providers.includes("google") && google === false) return false;

      return true;
    }
  );

export const userPermissionsSelector = createSelector(
  userSelector, (user) => user.get("permissions") ? user.get("permissions").toJS() : []
)