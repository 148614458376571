import { useEffect, useState } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import Gallery from "tools/gallery/Gallery";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import FormattedMessage from "components/common/FormattedMessage";
import { CheckSizeAgainstPresets } from "tools/gallery/imageHelpers";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { rulesetArrayMinMax} from "tools/relaxForm/relaxPresets";
import { CurrentAdsSettingsIdSelector, HasFilledDisplayAdsMediaHorizontalSelector, HasFilledDisplayAdsMediaSquareSelector, MediaSelector, smartSlice } from "modules/smart/smartSlice";
import { generateHashForFile, generateHashFromUrl } from "tools/gallery/galleryUploader";

export const DisplayAdsWithSuggestions = (props: any) => {
  
  const GetSuggestions: (
    type: string,
    smart: any,
    disp: string
  ) => callAPIProps = (type: string, smart: any, disp: string) => ({
    url: ({ getApiUrl, projectId }) =>
      getApiUrl(
        `projects/${projectId}/google-smart-settings/images?type=${type}`,
        3
      ),
    dispatchName: disp,
  });

    const UpdateSettings:(smart:any)=>callAPIProps = (smart:any) => ({
        url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/smarts/${smart}/google-smart-settings`,2),
        method: "PATCH",
        successDispatch: smartSlice.actions.updateGoogleSmartSettings,
    }); 

  
    const smartId = useSelectorWithParams(CurrentAdsSettingsIdSelector)
    const images = useSelectorWithParams([MediaSelector, props.fieldName])
    const {call, data, loading} = useAPI(GetSuggestions(props.type, smartId, props.dispatch),true);
    const [suggestedImages, setSuggestedImages] = useState<any>([]);


    useEffect(() => {
        //console.log("Suggestions", data)
        if (!data) return;
        if (!data || !data.length) return;


        data.forEach((s:any) => {

          if (!s) return;
          if (!s.url) return;

            //console.log("s", s);
            const i = new Image();
            (i.src as any) = s.url;
            if (!i) return;
            i.onload = async function () {
                const { height, width } = i;
                if (!props.cropPresets) return;
                const allowed = CheckSizeAgainstPresets({height, width}, props.cropPresets!);

                if (!allowed) {
                    console.log("Image doesnt match requirements", {height, width, url: s.url});
                    return;
                }
            setSuggestedImages((si:any) => [...si, {...s}])
            }
        })
}, [data])

  if (loading)
    return (
      <>
        <FormattedMessage
          id="apps.smart.containers.onboarding.displayAds.loadingSuggestions"
          defaultMessage="Loading Suggestions..."
        />
      </>
    );

  return (
    <RelaxSingleField
    hideStatus={props.hideStatus ? props.hideStatus : false}
      label={props.label}
      name={props.fieldName}
      callAPI={UpdateSettings(smartId)}
      initialValues={{ [props.fieldName]: images }}
      debounce={100}
      rules={props.rules}
      ignoreUpdateBlockOnFocus
      ignoreChangesToInitialValues={false}
      showBadgeMode={"errorOnly"}
    >
      <Gallery
        allowedTools={["crop", "select"]}
        initialItems={suggestedImages}
        sizeRequirements={props.sizeRequirements}
        requireCropToSelect
        cropPresets={props.cropPresets}
      />
    </RelaxSingleField>
  );
};

export const OnboardingHorizontalAds = () => {
  const fulfilled = useSelectorWithParams(
    HasFilledDisplayAdsMediaHorizontalSelector
  );

  return (
    <>
      <DisplayAdsWithSuggestions
        type="horizontal"
        fieldName="horizontal_images"
        dispatch="getHorizontalImagesSuggestions"
        sizeRequirements={{
          minWidth: 600,
          minHeight: 314,
        }}
        rules={rulesetArrayMinMax(2, 20)}
        cropPresets={{
          default: [
            {
              ratio: "1.9:1",
              aspect: 1.91,
              minHeight: 314,
              minWidth: 600,
            },
          ],
        }}
      />
      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fulfilled}
      />
    </>
  );
};

export const OnboardingSquareAds = () => {
  const fulfilled = useSelectorWithParams(
    HasFilledDisplayAdsMediaSquareSelector
  );

  return (
    <>
      <DisplayAdsWithSuggestions
        type="square"
        fieldName="square_images"
        dispatch="getSquareImagesSuggestions"
        sizeRequirements={{
          minWidth: 300,
          minHeight: 300,
        }}
        rules={rulesetArrayMinMax(1, 20)}
        cropPresets={{
          default: [
            {
              ratio: "1:1",
              aspect: 1,
              minHeight: 300,
              minWidth: 300,
            },
          ],
        }}
      />
      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fulfilled}
      />
    </>
  );
};
