import { callAPIFunction, callAPIProps } from "services/hooks/useAPI";
import { sellerSlice } from "./sellerSilce";
import { timeout } from "services/helpers/fake-asset-generators";

export const SortListByDate = (calcs: any[], newestFirst: boolean) =>
    calcs &&
    calcs.sort((a: any, b: any) => {
        const aDate = new Date(a.created_at);
        const bDate = new Date(b.created_at);
        if (newestFirst) return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
    });

export const GetSeller: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller`, 1),
    method: 'GET',
    successDispatch: sellerSlice.actions.getSeller,
}

export const GetSellerCalculations: callAPIFunction = (query?: any): callAPIProps => {

    const call = {
        url: ({ getApiUrl, serializeQuery }) => getApiUrl(`seller/calculations${query ? "?" + serializeQuery(query) : ""}`, 1),
        method: "GET",
        successDispatch: sellerSlice.actions.getCalculations,
        passToDispatcher: query,
        customNormalizer: (data: any) => data.data
    }

    if (import.meta.env.VITE_DEBUG_FAKE_SELLER_CALCULATIONS === "true") {
        return {
            ...call,
            fakeResponse: async (request) => await timeout(500).then(() => GenerateFakeCalculations()),
        }
    }

    return call;

};

export const GetSellerCoupons: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller/coupons`, 1),
    method: 'GET',
    successDispatch: sellerSlice.actions.getCoupons,
}

export const GetSellerSubsciptions: callAPIFunction = (query?: any): callAPIProps => {

    const call = {
        url: ({ getApiUrl, serializeQuery }) => getApiUrl(`seller/subscriptions${query ? "?" + serializeQuery(query) : ""}`, 1),
        method: "GET",
        successDispatch: sellerSlice.actions.getSubscriptions,
        passToDispatcher: query,
        customNormalizer: (data: any) => data.data
    }

    if (import.meta.env.VITE_DEBUG_FAKE_SELLER_CALCULATIONS === "true") {
        return {
            ...call,
            fakeResponse: async (request) => await timeout(500).then(() => GenerateFakeSubsriptions()),
        }
    }

    return call;

};

export const GetSellerValidate: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller/validate`, 1),
    method: 'GET',
    // successDispatch: sellerSlice.actions.get,
}

export const GenerateFakeCalculations = () => {

    const fakeData = [
        {
            type: "calculations",
            attributes: {
                start: "2024-10-01T00:00:00.000000Z",
                end: "2024-10-31T23:59:59.999999Z",
                paid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                },
                unpaid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                }
            }
        },
        {
            type: "team-calculations",
            attributes: {
                start: "2024-10-01T00:00:00.000000Z",
                end: "2024-10-31T23:59:59.999999Z",
                paid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                },
                unpaid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                }
            }
        },
    ]


    return {
        status: 200,
        json: () => ({
            data: fakeData
        })
    }
}


export const GenerateFakeSubsriptions = () => {

    const fakeData = [
        {

            id: "64463",

            type: "subscriptions",

            attributes: {

                name: "Smart",

                stripe_status: "active",

                status: "active",

                enabled: true,

                ends_at: null,

                subscription_permissions_plain: [

                    "use module smart posts",

                    "use module smart ads",

                    "use module smart createPost",

                    "use module smart createAd",

                    "use module smart adsReport",

                    "use module smart editAdsSettings",

                    "use module smart adsSettings",

                    "use module smart pixel",

                    "use module smart adsSettings interestsKeywords",

                    "use module smart createPostInfo",

                    "use module smart createAoa",

                    "use module smart aoaList",

                    "use module smart advancedSettings",

                    "use integration facebook pixel",

                    "use integration facebook fbAdAccount",

                    "use integration facebook fanpage",

                    "use project integrations",

                    "use view lead forms",

                    "use module panel tracking",

                    "use module panel tracking pixel",

                    "use module panel integrations",

                    "use module smart facebook settings",

                    "use module smart business settings",

                    "use module smart creations",

                    "use creations platform facebook",

                    "use creations type post",

                    "use creations type aoa",

                    "use creations publish",

                    "use integration facebook instagram",

                    "use module smart createW2LAoa",

                    "use creations type w2laoa",

                    "use module smart calendar",

                    "use module panel promotions",

                    "use module panel assistant",

                    "use module smart facebook settings budget",

                    "use module smart facebook settings changeCampaignName",

                    "use postlist facebook",

                    "use module bot chat"

                ],

                expiry: "2024-10-31 15:29:08",

                billing_cycle: "monthly",

                next_payment: {

                    period_end: "2025-03-31 15:29:05",

                    netto: 164,

                    brutto: 201.72,

                    tax: 37.72,

                    to_be_paid: 201.72,

                    amount_paid: 0,

                    tax_percentage: 0

                },

                agreement: {

                    type: "indefinite",

                    billing_cycle: "monthly",

                    end_time: "2024-10-31 15:29:08",

                    start_time: "2023-10-31 15:29:08",

                    extension_time: "2024-10-17 15:29:08",

                    mandatory_periods: 12,

                    left_periods: -1

                },

                user: {

                    name: "Piotr Basista",

                    phone: "+48508338343"

                },

                created_at: "2023-10-31T15:29:08.000000Z"

            }

        },
        {

            id: "64463",

            type: "subscriptions",

            attributes: {

                name: "Smart",

                stripe_status: "active",

                status: "active",

                enabled: true,

                ends_at: null,

                subscription_permissions_plain: [

                    "use module smart posts",

                    "use module smart ads",

                    "use module smart createPost",

                    "use module smart createAd",

                    "use module smart adsReport",

                    "use module smart editAdsSettings",

                    "use module smart adsSettings",

                    "use module smart pixel",

                    "use module smart adsSettings interestsKeywords",

                    "use module smart createPostInfo",

                    "use module smart createAoa",

                    "use module smart aoaList",

                    "use module smart advancedSettings",

                    "use integration facebook pixel",

                    "use integration facebook fbAdAccount",

                    "use integration facebook fanpage",

                    "use project integrations",

                    "use view lead forms",

                    "use module panel tracking",

                    "use module panel tracking pixel",

                    "use module panel integrations",

                    "use module smart facebook settings",

                    "use module smart business settings",

                    "use module smart creations",

                    "use creations platform facebook",

                    "use creations type post",

                    "use creations type aoa",

                    "use creations publish",

                    "use integration facebook instagram",

                    "use module smart createW2LAoa",

                    "use creations type w2laoa",

                    "use module smart calendar",

                    "use module panel promotions",

                    "use module panel assistant",

                    "use module smart facebook settings budget",

                    "use module smart facebook settings changeCampaignName",

                    "use postlist facebook",

                    "use module bot chat"

                ],

                expiry: "2024-10-31 15:29:08",

                billing_cycle: "monthly",

                next_payment: {

                    period_end: "2025-03-31 15:29:05",

                    netto: 164,

                    brutto: 201.72,

                    tax: 37.72,

                    to_be_paid: 201.72,

                    amount_paid: 0,

                    tax_percentage: 0

                },

                agreement: {

                    type: "indefinite",

                    billing_cycle: "monthly",

                    end_time: "2024-10-31 15:29:08",

                    start_time: "2023-10-31 15:29:08",

                    extension_time: "2024-10-17 15:29:08",

                    mandatory_periods: 12,

                    left_periods: -1

                },

                user: {

                    name: "Piotr Basista",

                    phone: "+48508338343"

                },

                created_at: "2023-10-31T15:29:08.000000Z"

            }

        },
        {

            id: "64463",

            type: "subscriptions",

            attributes: {

                name: "Smart",

                stripe_status: "active",

                status: "active",

                enabled: true,

                ends_at: null,

                subscription_permissions_plain: [

                    "use module smart posts",

                    "use module smart ads",

                    "use module smart createPost",

                    "use module smart createAd",

                    "use module smart adsReport",

                    "use module smart editAdsSettings",

                    "use module smart adsSettings",

                    "use module smart pixel",

                    "use module smart adsSettings interestsKeywords",

                    "use module smart createPostInfo",

                    "use module smart createAoa",

                    "use module smart aoaList",

                    "use module smart advancedSettings",

                    "use integration facebook pixel",

                    "use integration facebook fbAdAccount",

                    "use integration facebook fanpage",

                    "use project integrations",

                    "use view lead forms",

                    "use module panel tracking",

                    "use module panel tracking pixel",

                    "use module panel integrations",

                    "use module smart facebook settings",

                    "use module smart business settings",

                    "use module smart creations",

                    "use creations platform facebook",

                    "use creations type post",

                    "use creations type aoa",

                    "use creations publish",

                    "use integration facebook instagram",

                    "use module smart createW2LAoa",

                    "use creations type w2laoa",

                    "use module smart calendar",

                    "use module panel promotions",

                    "use module panel assistant",

                    "use module smart facebook settings budget",

                    "use module smart facebook settings changeCampaignName",

                    "use postlist facebook",

                    "use module bot chat"

                ],

                expiry: "2024-10-31 15:29:08",

                billing_cycle: "monthly",

                next_payment: {

                    period_end: "2025-03-31 15:29:05",

                    netto: 164,

                    brutto: 201.72,

                    tax: 37.72,

                    to_be_paid: 201.72,

                    amount_paid: 0,

                    tax_percentage: 0

                },

                agreement: {

                    type: "indefinite",

                    billing_cycle: "monthly",

                    end_time: "2024-10-31 15:29:08",

                    start_time: "2023-10-31 15:29:08",

                    extension_time: "2024-10-17 15:29:08",

                    mandatory_periods: 12,

                    left_periods: -1

                },

                user: {

                    name: "Piotr Basista",

                    phone: "+48508338343"

                },

                created_at: "2023-10-31T15:29:08.000000Z"

            }

        },
        {

            id: "64463",

            type: "subscriptions",

            attributes: {

                name: "Smart",

                stripe_status: "active",

                status: "active",

                enabled: true,

                ends_at: null,

                subscription_permissions_plain: [

                    "use module smart posts",

                    "use module smart ads",

                    "use module smart createPost",

                    "use module smart createAd",

                    "use module smart adsReport",

                    "use module smart editAdsSettings",

                    "use module smart adsSettings",

                    "use module smart pixel",

                    "use module smart adsSettings interestsKeywords",

                    "use module smart createPostInfo",

                    "use module smart createAoa",

                    "use module smart aoaList",

                    "use module smart advancedSettings",

                    "use integration facebook pixel",

                    "use integration facebook fbAdAccount",

                    "use integration facebook fanpage",

                    "use project integrations",

                    "use view lead forms",

                    "use module panel tracking",

                    "use module panel tracking pixel",

                    "use module panel integrations",

                    "use module smart facebook settings",

                    "use module smart business settings",

                    "use module smart creations",

                    "use creations platform facebook",

                    "use creations type post",

                    "use creations type aoa",

                    "use creations publish",

                    "use integration facebook instagram",

                    "use module smart createW2LAoa",

                    "use creations type w2laoa",

                    "use module smart calendar",

                    "use module panel promotions",

                    "use module panel assistant",

                    "use module smart facebook settings budget",

                    "use module smart facebook settings changeCampaignName",

                    "use postlist facebook",

                    "use module bot chat"

                ],

                expiry: "2024-10-31 15:29:08",

                billing_cycle: "monthly",

                next_payment: {

                    period_end: "2025-03-31 15:29:05",

                    netto: 164,

                    brutto: 201.72,

                    tax: 37.72,

                    to_be_paid: 201.72,

                    amount_paid: 0,

                    tax_percentage: 0

                },

                agreement: {

                    type: "indefinite",

                    billing_cycle: "monthly",

                    end_time: "2024-10-31 15:29:08",

                    start_time: "2023-10-31 15:29:08",

                    extension_time: "2024-10-17 15:29:08",

                    mandatory_periods: 12,

                    left_periods: -1

                },

                user: {

                    name: "Piotr Basista",

                    phone: "+48508338343"

                },

                created_at: "2023-10-31T15:29:08.000000Z"

            }

        },

    ]


    return {
        status: 200,
        json: () => ({
            data: fakeData
        })
    }
}