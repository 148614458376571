import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import { Input, Space } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { useDispatch } from "react-redux";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import useRelaxData from "tools/relaxForm/useRelaxData";
import PhoneErrorAlert from "./PhoneErrorAlert";
import { useEffect, useState } from "react";
import { userHasPhoneNumberSelector, userHasPhoneVerifySelector, userSelector } from "modules/panel/config/selectors/user";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { updateUserPhoneNumber } from "modules/panel/actions/user/user";

export default function OnboardingPhoneVerification() {
  const [visibleAlert, setVisibleAlert] = useState(false);
  const user = useSelectorWithParams(userSelector);
  const dispatch = useDispatch();
  const userHasVerifiedNumber = useSelectorWithParams(
    userHasPhoneVerifySelector
  );
  const userHasPhoneNumber = useSelectorWithParams(userHasPhoneNumberSelector);
  const rd = useRelaxData();

  const sendPhone = useAPI({
    url: ({ getApiUrl }) => getApiUrl("user/verify-sms", 2),
    method: "POST",
    customNormalizer: (data) => data,
  });

  const verifyCode = useAPI({
    url: ({ getApiUrl }) => getApiUrl(`user/phone`, 1),
    method: "PATCH",
    customNormalizer: (data) => data,
  });

  useEffect(()=>{
    if(user.phone) rd.setCurrentValue({phone:user.phone})
  },[])

  useEffect(()=>{
    if(sendPhone.error || verifyCode.error){
      setVisibleAlert(true)
    }
  }, [sendPhone.error, verifyCode.error])

  const handlePhone = () => {
    const phoneToSend = rd?.currentValue?.phone || user.phone;

    sendPhone
      .call({
        body: { phone: phoneToSend },
      })
      .then((res: any) => {
        if (res.status === 200 || res.status === 204) {
          dispatch({
            type: "USER_DATA_SET_SUCCESS",
            payload: { user: { phone: phoneToSend } },
          });
        }
      });
  };

  const handleCheckPhone = () => {
    const values = {
      phone_number: rd?.currentValue?.phone,
      verify_code: rd?.currentValue?.verifyCode,
    };

    verifyCode
      .call({
        body: values,
      })
      .then((res: any) => {
        if (res.status === 200 || res.status === 204) {
          const phoneVerifiedAt = new Date()
            .toISOString()
            .replace("Z", ".000000Z");
          dispatch({
            type: updateUserPhoneNumber.type.success,
            payload: {
              phone: rd?.currentValue?.phone,
              phone_verified_at: phoneVerifiedAt,
            },
          });
        }
      });
  };

  const handlePhoneFocus = () => {
    const phoneInput = document.querySelector(
      ".PhoneInputInput"
    ) as HTMLElement;
    if (phoneInput) {
      phoneInput.focus();
    }

    dispatch({
      type: updateUserPhoneNumber.type.success,
      payload: { phone: null, phone_verified_at: null },
    });
  };


  
  return (
    <>
        <RelaxMultiform
          relaxData={rd}
          initialValuesSelector={userSelector}
          finalizeSubmit={(data) => {
            console.log("data", data)
            rd.setCurrentValue({ ...rd?.currentValue, ...data });
          }}
        >
          <Space direction="vertical" style={{width:"100%"}}>
            <RelaxField
              name="phone"
              hideStatus={true}
              disabled={userHasPhoneNumber}
              shouldReset={true}
              label={
                <FormattedMessage
                  id="panel.containers.register.phoneNumber"
                  defaultMessage="Phone number"
                />
              }
            >
              <PhoneNumberInputWithCountrySelect />
            </RelaxField>

            {!userHasPhoneNumber && (
              <StandardButton
                onClick={handlePhone}
                style={{width:"100%"}}
                loading={sendPhone.loading}
                disabled={!rd?.currentValue?.phone}
                block
                type="primary"
              >
                <FormattedMessage
                  id="panel.containers.register.verificationCode.verifyPhone"
                  defaultMessage="Next step"
                />
              </StandardButton>
            )}

            {userHasPhoneNumber && (
              <>
                <div>
                  <a onClick={handlePhoneFocus}>
                    <FormattedMessage
                      id="panel.containers.register.verificationCode.changeNumber"
                      defaultMessage="Change number"
                    />
                  </a>
                </div>

                <RelaxField
                  name="verifyCode"
                  hideStatus={true}
                  disabled={userHasVerifiedNumber}
                  label={
                    <FormattedMessage
                      id="panel.containers.register.verificationCode"
                      defaultMessage="Verification code"
                    />
                  }
                >
                  <Input />
                </RelaxField>
                {!userHasVerifiedNumber && (
                  <StandardButton
                    type="primary"
                    style={{width:"100%"}}
                    onClick={handleCheckPhone}
                    loading={verifyCode.loading}
                    disabled={!rd?.currentValue?.verifyCode}
                    block
                  >
                    <FormattedMessage
                      id="panel.containers.register.verifyPhone"
                      defaultMessage="Verify number"
                    />
                  </StandardButton>
                )}
              </>
            )}
            {(sendPhone.error || verifyCode.error) && (
              <PhoneErrorAlert
                phoneError={sendPhone.error}
                verifyError={verifyCode.error}
                handlePhone={handlePhone}
                handlePhoneFocus={handlePhoneFocus}
                visibleAlert={visibleAlert}
                setVisibleAlert={setVisibleAlert}
              />
            )}
          </Space>
        </RelaxMultiform>
        <ConnectedOnboardingControls hideButtons = {!userHasVerifiedNumber}/>
    </>
  );
}
