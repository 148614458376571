import { faBullseye, faCalendarCheck, faCircleCheck, faCommentAlt, faImage, faImages, faLink, faMagnifyingGlass, faPenRuler, faPenToSquare, faTrash, faVideo } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";

import aoa from "/creationTypes/aoa.svg";
import leadAds from "/creationTypes/aoaLeads.svg";
import linkClick from "/creationTypes/postLink.svg";
import messenger from "/creationTypes/postMessenger.svg";
import photo from "/creationTypes/postPhoto.svg";
import video from "/creationTypes/postVideo.svg";
import carousel from "/creationTypes/postCarousel.svg";
import eventAOA from "/creationTypes/aoaEvent.svg";
import w2lConversion from "/creationTypes/aoaWebToLearnConversion.svg";
import w2lTraffic from "/creationTypes/aoaWebToLearnTraffic.svg";

import instagramVideo from "/creationTypes/instagramVideo.svg";
import instagramPhoto from "/creationTypes/instagramPhoto.svg";
import instagramStory from "/creationTypes/instagramStory.svg";
import instagramCarousel from "/creationTypes/instagramCarousel.svg";

import linkSocial from "/creationTypes/linkSocial.svg";
import linkWebsite from "/creationTypes/linkWebsite.svg";
import linkCall from "/creationTypes/linkCall.svg";
import linkMessage from "/creationTypes/linkMessage.svg";

import instaAOA from "/creationTypes/instaAOA.svg";
import instaEventAOA from "/creationTypes/instaEventAOA.svg";
import instaW2LConversion from "/creationTypes/instaW2LConversion.svg";
import instaW2LTraffic from "/creationTypes/instaW2LTraffic.svg";
import instaLead from "/creationTypes/instaLeads.svg";


import { ReactNode } from "react";
import { InfinityListSettingsCheckbox } from "tools/infinityList/infinityListSettingsCheckbox";
import { Creation } from "./creationsSlice";

type InstagramPostType = "instagram-image" | "instagram-video" | "instagram-carousel" | "instagram-stories";
type InstagramAdType = "instagram-link-social" | "instagram-link-website" | "instagram-link-call" | "instagram-link-message";
type InstagramAOAType = "instagram_aoa" | "instagram_event_aoa" | "instagram_w2l_aoa" | "instagram_w2l_event" | "instagram_lead_ads";

type FacebookPostType = "image" | "video" | "video_reel" | "messenger" | "carousel" | "link";
type FacebookAOAType = "aoa" | "event_aoa" | "w2l_aoa" | "w2l_event";
type FacebookCreativeType = "leads_ad";

export type BaseCreationType = "post" | "aoa" | "creative";
export type CreationSubtypePost = FacebookPostType | InstagramPostType | InstagramAdType | InstagramAOAType;
export type CreationSubtypeCreative = FacebookCreativeType;
export type CreationSubtype = CreationSubtypePost | FacebookAOAType | CreationSubtypeCreative;

export type CreationType = [BaseCreationType, CreationSubtype];

export const EmptyCreationByType = (creationType: CreationType) => {
  const [type, subType] = creationType;
  return {
    type: type,
    content: {
      type: subType,
    }
  }
}

export const CreationTypeFromCreation = (creation: Creation): CreationType | null => {

  if (!creation) return null;

  const type = creation?.type;
  const subType = creation?.content?.type;
  return [type, subType];

}


const statusDraft = <FormattedMessage
  id="creations.status.draft"
  defaultMessage="Draft"
/>

const statusPublished = <FormattedMessage
  id="creations.status.published"
  defaultMessage="Published"
/>

const statusScheduled = <FormattedMessage
  id="creations.status.scheduled"
  defaultMessage="Scheduled"
/>

const statusAwaitingAcceptation = <FormattedMessage
  id="creations.status.awaitingAcceptation"
  defaultMessage="Awaiting Acceptation"
/>

const statusRemoved = <FormattedMessage
  id="creations.status.removed"
  defaultMessage="Removed"
/>

const statusInReview = <FormattedMessage
  id="creations.status.inReview"
  defaultMessage="In Review"
/>

export const CreationStatusString = (status: string) => {
  switch (status) {
    case "draft":
      return statusDraft;
    case "published":
      return statusPublished;
    case "scheduled":
      return statusScheduled;
    case "removed":
      return statusRemoved;
    case "inReview":
      return statusInReview;
    case "awaiting_acceptation":
      return statusAwaitingAcceptation;
    case "bound_to_task":
      return "Pending"
    default:
      return status;
  }
}

export const CreationStatusIcon = (status: string) => {
  switch (status) {
    case "draft":
      return faPenToSquare;
    case "published":
      return faCircleCheck;
    case "scheduled":
      return faCalendarCheck;
    case "removed":
      return faTrash;
    case "inReview":
      return faMagnifyingGlass;
    default:
      return faPenRuler;
  }
}

export type CreationTypeConfig = {
  image: string;
  icon: any;
  type: CreationType;
  permission: string | ((permissions: string[]) => boolean);
  title: ReactNode;
  tooltip: ReactNode;
  subtitle: ReactNode;
  description: ReactNode;
  isAd?: boolean;
  publicationSuccessMessage?: ReactNode | ((creation: any) => ReactNode);
  publicationErrorMessage?: ReactNode | ((creation: any) => ReactNode);
}

export const CreationTypes: CreationTypeConfig[] = [
  { //Default video
    image: video,
    icon: faVideo,
    type: ["post", "video"],
    permission: "use creations type post",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.video.title"
        defaultMessage="Video post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.video.tooltip"
        defaultMessage="Video post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.video.subtitle"
        defaultMessage="Get more views and interactions"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.video.description"
        defaultMessage='Advertising creation with text and video material. Additionally, it may contain a CTA button redirecting to a website or a Send message button redirecting to Messenger. It works primarily in posts such as "how it is made", "how to use", as well as with testimonials and dynamic product presentation. Important! A YouTube link post is not a video post. Promoting posts with a YouTube link is only possible with the goal of increasing engagement as opposed to regular video where Smart FastTony optimizes the campaign for the purpose of viewing the material.'
      />
    ),
    publicationSuccessMessage(creation) {
      return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )
    },
  },
  { // video reel
    image: video,
    icon: faVideo,
    type: ["post", "video_reel"],
    permission: "use creations type post",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.videoReel.title"
        defaultMessage="Video Reel"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.videoReel.tooltip"
        defaultMessage="Video Reel"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.videoReel.subtitle"
        defaultMessage="Get more views and interactions"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.videoReel.description"
        defaultMessage='Advertising creation with text and video material. Additionally, it may contain a CTA button redirecting to a website or a Send message button redirecting to Messenger. It works primarily in posts such as "how it is made", "how to use", as well as with testimonials and dynamic product presentation. Important! A YouTube link post is not a video post. Promoting posts with a YouTube link is only possible with the goal of increasing engagement as opposed to regular video where Smart FastTony optimizes the campaign for the purpose of viewing the material.'
      />
    ),
    publicationSuccessMessage(creation) {
      return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )
    }
  },
  { //Instagram video
    image: instagramVideo,
    icon: faVideo,
    type: ["post", "instagram-video"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramVideo.title"
        defaultMessage="Instagram Reel"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramVideo.tooltip"
        defaultMessage="Instagram Reel"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramVideo.subtitle"
        defaultMessage="Get more views and interactions"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramVideo.description"
        defaultMessage='Advertising creation with text and video material. Additionally, it may contain a CTA button redirecting to a website or a Send message button redirecting to Messenger. It works primarily in posts such as "how it is made", "how to use", as well as with testimonials and dynamic product presentation. Important! A YouTube link post is not a video post. Promoting posts with a YouTube link is only possible with the goal of increasing engagement as opposed to regular video where Smart FastTony optimizes the campaign for the purpose of viewing the material.'
      />
    ),
    publicationSuccessMessage(creation) {
      return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )
    },
  },
  { //Default photo
    image: photo,
    icon: faImage,
    type: ["post", "image"],
    permission: "use creations type post",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.photo.title"
        defaultMessage="Photo post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.photo.tooltip"
        defaultMessage="Photo post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.photo.subtitle"
        defaultMessage="Build active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.photo.description"
        defaultMessage="Advertising creation with text and graphics. This is nothing but product posts and community engagement. Through these publications, we educate customers, tell the story of the product, present it in the environment of others (building value by comparison) and present its natural environment (e.g. a watch on the hand of a woman wearing Versace, a phone on which it rains - waterproof). Posts should refer to solutions to the customer's problems and emphasize that the presented product or service is a response to the customer's need."
      />
    ),
  },
  { //Instagram photo
    image: instagramPhoto,
    icon: faImage,
    type: ["post", "instagram-image"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramImage.title"
        defaultMessage="Instagram Photo post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramImage.tooltip"
        defaultMessage="Instagram Photo post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramImage.subtitle"
        defaultMessage="Build active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramImage.description"
        defaultMessage="Advertising creation with text and graphics. This is nothing but product posts and community engagement. Through these publications, we educate customers, tell the story of the product, present it in the environment of others (building value by comparison) and present its natural environment (e.g. a watch on the hand of a woman wearing Versace, a phone on which it rains - waterproof). Posts should refer to solutions to the customer's problems and emphasize that the presented product or service is a response to the customer's need."
      />
    ),
  },
  { //Instagram story
    image: instagramStory,
    icon: faImage,
    type: ["post", "instagram-stories"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramStory.title"
        defaultMessage="Instagram Story"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramStory.tooltip"
        defaultMessage="Instagram Story"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramStory.subtitle"
        defaultMessage="Build active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramStory.description"
        defaultMessage="Advertising creation with text and graphics. This is nothing but product posts and community engagement. Through these publications, we educate customers, tell the story of the product, present it in the environment of others (building value by comparison) and present its natural environment (e.g. a watch on the hand of a woman wearing Versace, a phone on which it rains - waterproof). Posts should refer to solutions to the customer's problems and emphasize that the presented product or service is a response to the customer's need."
      />
    ),
  },
  { //Link click
    image: linkClick,
    icon: faLink,
    type: ["post", "link"],
    permission: "use creations type post",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.linkClick.title"
        defaultMessage="Link click post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.linkClick.tooltip"
        defaultMessage="Link click post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.linkClick.subtitle"
        defaultMessage="Get more page views and hits"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.linkClick.description"
        defaultMessage='An advertising creation with text and a link redirecting to the website. Both clicking the picture and the CTA button will take you to the website. It is important that the content contains a direct call to action, which will show the recipient what we expect from him. The post should have a CTA button such as "Buy Now" for the offer pages or "Learn More" for the informational pages. Using "Learn More" and referring to a product or offer will cause cognitive dissonance in the customer, the impression of trying to cheat, which will result in withdrawal from the website.'
      />
    ),
  }, 
  { //Insta link social
    image: linkSocial,
    icon: faLink,
    type: ["post", "instagram-link-social"],
    isAd: true,
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkSocial.title"
        defaultMessage="Instagram Social Ad"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkSocial.tooltip"
        defaultMessage="Instagram Social Ad"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkSocial.subtitle"
        defaultMessage="Get more page views and hits"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkSocial.description"
        defaultMessage='An advertising creation with text and a link redirecting to the website. Both clicking the picture and the CTA button will take you to the website. It is important that the content contains a direct call to action, which will show the recipient what we expect from him. The post should have a CTA button such as "Buy Now" for the offer pages or "Learn More" for the informational pages. Using "Learn More" and referring to a product or offer will cause cognitive dissonance in the customer, the impression of trying to cheat, which will result in withdrawal from the website.'
      />
    ),
  }, 
  { //Insta link website
    image: linkWebsite,
    icon: faLink,
    isAd: true,
    type: ["post", "instagram-link-website"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkWebsite.title"
        defaultMessage="Instagram Website Ad"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkWebsite.tooltip"
        defaultMessage="Instagram Website Ad"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkWebsite.subtitle"
        defaultMessage="Get more page views and hits"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkWebsite.description"
        defaultMessage='An advertising creation with text and a link redirecting to the website. Both clicking the picture and the CTA button will take you to the website. It is important that the content contains a direct call to action, which will show the recipient what we expect from him. The post should have a CTA button such as "Buy Now" for the offer pages or "Learn More" for the informational pages. Using "Learn More" and referring to a product or offer will cause cognitive dissonance in the customer, the impression of trying to cheat, which will result in withdrawal from the website.'
      />
    ),
  },
  { //Insta link message
    image: linkMessage,
    icon: faLink,
    isAd: true,
    type: ["post", "instagram-link-message"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkMessage.title"
        defaultMessage="Instagram Message Ad"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkMessage.tooltip"
        defaultMessage="Instagram Message Ad"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkMessage.subtitle"
        defaultMessage="Get more page views and hits"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkMessage.description"
        defaultMessage='An advertising creation with text and a link redirecting to the website. Both clicking the picture and the CTA button will take you to the website. It is important that the content contains a direct call to action, which will show the recipient what we expect from him. The post should have a CTA button such as "Buy Now" for the offer pages or "Learn More" for the informational pages. Using "Learn More" and referring to a product or offer will cause cognitive dissonance in the customer, the impression of trying to cheat, which will result in withdrawal from the website.'
      />
    ),
  },
  { //Insta link call
    image: linkCall,
    icon: faLink,
    isAd: true,
    type: ["post", "instagram-link-call"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkCall.title"
        defaultMessage="Instagram Call Ad"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkCall.tooltip"
        defaultMessage="Instagram Call Ad"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkCall.subtitle"
        defaultMessage="Get more page views and hits"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instaLinkCall.description"
        defaultMessage='An advertising creation with text and a link redirecting to the website. Both clicking the picture and the CTA button will take you to the website. It is important that the content contains a direct call to action, which will show the recipient what we expect from him. The post should have a CTA button such as "Buy Now" for the offer pages or "Learn More" for the informational pages. Using "Learn More" and referring to a product or offer will cause cognitive dissonance in the customer, the impression of trying to cheat, which will result in withdrawal from the website.'
      />
    ),
  }, 
  { //Messenger
    image: messenger,
    icon: faCommentAlt,
    type: ["post", "messenger"],
    permission: "use creations type post",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.messenger.title"
        defaultMessage="Messenger post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.messenger.tooltip"
        defaultMessage="Messenger post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.messenger.subtitle"
        defaultMessage="Stay in touch with your clients"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.messenger.description"
        defaultMessage='An advertising creative with a Send message button that directs recipients to Messenger. This is a creation whose purpose is to start a chat discussion. This is a great way to "Lead Generation", which is getting contact data from interested people who leave it in the result of a conversation with a company representative (trust). When using this type of post, you need to give recipients a reason to click and write a message. You can do it like this: "Find out how to reduce your electricity bills by 68%. Talk online with our solar expert, click the button: Send Message"'
      />
    ),
  },
  { //Carousel
    image: carousel,
    icon: faImages,
    type: ["post", "carousel"],
    permission: "use creations type post",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.carousel.title"
        defaultMessage="Carousel post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.carousel.tooltip"
        defaultMessage="Carousel post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.carousel.subtitle"
        defaultMessage="Show the variety of your products"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.carousel.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    publicationSuccessMessage(creation) {

      try {
        const hasVideos = creation?.content?.carousel?.some((c: any) => c.media && c.media.some((m: any) => m.type === "video"));

        if (hasVideos) return (
          <FormattedMessage
            id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
            defaultMessage="Your videos are being processed. Creation will be published shortly."
          />
        )
      } catch (e) {

      }

      return GenericPublicationSuccessMessage(this)
    },
  },
  { //Instagram carousel
    image: instagramCarousel,
    icon: faImages,
    type: ["post", "instagram-carousel"],
    permission: "use creations type instagram",
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramCarousel.title"
        defaultMessage="Instagram Carousel post"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramCarousel.tooltip"
        defaultMessage="Instagram Carousel post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramCarousel.subtitle"
        defaultMessage="Show the variety of your products"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramCarousel.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    publicationSuccessMessage(creation) {

      try {
        const hasVideos = creation?.content?.carousel?.some((c: any) => c.media && c.media.some((m: any) => m.type === "video"));

        if (hasVideos) return (
          <FormattedMessage
            id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
            defaultMessage="Your videos are being processed. Creation will be published shortly."
          />
        )
      } catch (e) {

      }

      return GenericPublicationSuccessMessage(this)
    },
  },
  { //AOA
    image: aoa,
    icon: faBullseye,
    type: ["aoa", "aoa"],
    permission: "use creations type aoa",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa.title"
        defaultMessage="E-commerce AOA"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa.tooltip"
        defaultMessage="E-commerce AOA"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    publicationSuccessMessage(creation) {

      if (creation?.content?.["media-type"] === "video") return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )

    },
  },
  { //Event AOA
    image: eventAOA,
    type: ["aoa", "event_aoa"],
    icon: faCalendarCheck,
    permission: "use creations type aoa",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoaEvent.title"
        defaultMessage="Event AOA"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoaEvent.tooltip"
        defaultMessage="Event AOA"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoaEvent.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoaEvent.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    
    publicationSuccessMessage(creation) {

      if (creation?.content?.["media-type"] === "video") return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )

    },
  },
  { //W2L Traffic (w2l_aoa)
    image: w2lTraffic,
    icon: faBullseye,
    type: ["aoa", "w2l_aoa"],
    permission: "use creations type w2laoa",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_traffic.title"
        defaultMessage="Web to Learn AOA {br}Traffic"
        values={{
          br: <br />,
        }}
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_traffic.tooltip"
        defaultMessage="Web to Learn AOA {br}Traffic"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_traffic.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_traffic.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    
    publicationSuccessMessage(creation) {

      if (creation?.content?.["media-type"] === "video") return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )

    },
  },
  { //W2L Conversion (w2l_event)
    image: w2lConversion,
    type: ["aoa", "w2l_event"],
    icon: faBullseye,
    permission: "use creations type w2laoa",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_conversion.title"
        defaultMessage="Web to Learn AOA{br}Conversion"
        values={{
          br: <br />,
        }}
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_conversion.tooltip"
        defaultMessage="Web to Learn AOA{br}Conversion"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_conversion.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.aoa_w2l_conversion.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    
    publicationSuccessMessage(creation) {

      if (creation?.content?.["media-type"] === "video") return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )

    },
  },
  { //Lead ads
    image: leadAds,
    type: ["creative", "leads_ad"],
    icon: faBullseye,
    permission: "use creations type leadads",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.leadAds.title"
        defaultMessage="Lead ads"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.leadAds.tooltip"
        defaultMessage="Lead ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.leadAds.subtitle"
        defaultMessage="Acquire and processhot leads"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.leadAds.description"
        defaultMessage='Advertising creation aimed at gaining contact with potential customers. In the lead ads campaign, we obtain telephone numbers or e-mail addresses or other data from ad audience. All you need to do is click "Next" and Facebook will automatically provide you with the customers contact details. The most difficult campaign and considered by many an art in itself, thanks to FastTony and Forsant, is 100% self-made. Additionally, leads are automatically sent to our CRM and other partners, including FreshMail, to keep everything automated.'
      />
    ),
  },
  { //Instagram AOA
    image: instaAOA,
    type: ["aoa", "instagram_aoa"],
    icon: faBullseye,
    permission: "use creations type instagram",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramAOA.title"
        defaultMessage="Instagram AOA"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramAOA.tooltip"
        defaultMessage="Instagram AOA"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramAOA.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramAOA.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />
    ),
    publicationSuccessMessage(creation) {

      if (creation?.content?.["media-type"] === "video") return (
        <FormattedMessage
          id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
          defaultMessage="Your videos are being processed. Creation will be published shortly."
        />
      )

    },
  },
  { //Instagram event aoa
    image: instaEventAOA,
    type: ["aoa", "instagram_event_aoa"],
    icon: faCalendarCheck,
    permission: "use creations type instagram",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramEventAOA.title"
        defaultMessage="Instagram Event AOA"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramEventAOA.tooltip"
        defaultMessage="Instagram Event AOA"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramEventAOA.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramEventAOA.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
        />
      ),
    publicationSuccessMessage(creation) {
        if (creation?.content?.["media-type"] === "video") return (
          <FormattedMessage
            id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
            defaultMessage="Your videos are being processed. Creation will be published shortly."
          />
        )
      }
  },
  { //Instagram w2l AOA
    image: instaW2LTraffic,
    type: ["aoa", "instagram_w2l_aoa"],
    icon: faBullseye,
    permission: "use creations type instagram",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LTraffic.title"
        defaultMessage="Instagram Web to Learn AOA {br}Traffic"
        values={{
          br: <br />,
        }}
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LTraffic.tooltip"
        defaultMessage="Instagram Web to Learn AOA {br}Traffic"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LTraffic.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LTraffic.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />),
      publicationSuccessMessage(creation) {

        if (creation?.content?.["media-type"] === "video") return (
          <FormattedMessage
            id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
            defaultMessage="Your videos are being processed. Creation will be published shortly."
          />
        )

      },
  },
  { //Instagram w2l event
    image: instaW2LConversion,
    type: ["aoa", "instagram_w2l_event"],
    icon: faBullseye,
    permission: "use creations type instagram",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LConversion.title"
        defaultMessage="Instagram Web to Learn AOA {br}Conversion"
        values={{
          br: <br />,
        }}
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LConversion.tooltip"
        defaultMessage="Instagram Web to Learn AOA {br}Conversion"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LConversion.subtitle"
        defaultMessage="Build an active and loyal community"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramW2LConversion.description"
        defaultMessage="A type of dynamic ad that adjusts contextually and in terms of the creation to the recipient of the ad in such a way as to best personalize the displayed ads. People who click blue ads will be shown a blue creative, people who have recently clicked ads with e.g. mowers and on one of the prepared graphics there is a mower we will show you an advertisement with a lawn mower. AOA is a campaign that optimizes itself for conversions (events that you define). A solution dedicated to stores and websites for collecting records or obtaining contact data."
      />),
      publicationSuccessMessage(creation) {

        if (creation?.content?.["media-type"] === "video") return (
          <FormattedMessage
            id="tools.creationEditor.actions.creationPublication.result.success.creationWithVideo"
            defaultMessage="Your videos are being processed. Creation will be published shortly."
          />
        )

      },
  },
  { //Instagram lead ads
    image: instaLead,
    type: ["creative", "instagram-lead_ads"],
    icon: faBullseye,
    permission: "use creations type instagram",
    isAd: true,
    title: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramLeadAds.title"
        defaultMessage="Instagram Lead ads"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramLeadAds.tooltip"
        defaultMessage="Instagram Lead ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramLeadAds.subtitle"
        defaultMessage="Acquire and processhot leads"
      />
    ),
    description: (
      <FormattedMessage
        id="smart.containers.createPostInfo.types.instagramLeadAds.description"
        defaultMessage='Advertising creation aimed at gaining contact with potential customers. In the lead ads campaign, we obtain telephone numbers or e-mail addresses or other data from ad audience. All you need to do is click "Next" and Facebook will automatically provide you with the customers contact details. The most difficult campaign and considered by many an art in itself, thanks to FastTony and Forsant, is 100% self-made. Additionally, leads are automatically sent to our CRM and other partners, including FreshMail, to keep everything automated.'
      />),
  }
]

export const CreationTypeConfigByType = (type: BaseCreationType, subtype: CreationSubtype): CreationTypeConfig | null => {
  const config = CreationTypes.find((c) => c.type[0] === type && c.type[1] === subtype);
  return config || null;
}

export const GenericPublicationSuccessMessage = (type?: CreationTypeConfig) => {

  if (!type) return (
    <FormattedMessage
      id="tools.creationEditor.actions.creationPublication.result.success"
      defaultMessage="Creation has been successfully published!"
    />
  )

  if (type.isAd) return (
    <FormattedMessage
      id="tools.creationEditor.actions.creationPublication.result.adSuccess"
      defaultMessage="Ad has been successfully published!"
    />
  )

  return (
    <FormattedMessage
      id="tools.creationEditor.actions.creationPublication.result.postSuccess"
      defaultMessage="Post has been successfully published!"
    />
  )

}


export const GenericPublicationErrorMessage = (type?: CreationTypeConfig) => {



  return (
    <FormattedMessage
      id="tools.creationEditor.actions.creationPublication.result.error"
      defaultMessage="There was an error sending your post for publication. Please try again later."
    />
  )

}

export const CreationListAdvancedSettings = [
  {
    key: "filterType",
    label: (
      <FormattedMessage
        id="posts.list.filtering.advancedSettings.filtering.type.label"
        defaultMessage="Filter by type" />
    ),
    popupClassName: "post-list-advanced-settings-dropdown",
    children: [
      {
        key: "showPosts",
        label: (
          <InfinityListSettingsCheckbox
            message={
              <FormattedMessage
                id="creationList.list.filtering.advancedSettings.showPosts.label"
                defaultMessage="Show Posts" />
            }
            path={["creationList", "showPosts"]}
          />
        )

      },
      {
        key: "showAOA",
        label: (
          <InfinityListSettingsCheckbox
            message={
              <FormattedMessage
                id="creationList.list.filtering.advancedSettings.showAOA.label"
                defaultMessage="Show AOA" />
            }
            path={["creationList", "showAOA"]}
          />
        )
      },
    ]
  },
  {
    key: "filterState",
    label: (
      <FormattedMessage
        id="creations.list.filtering.advancedSettings.filtering.state.label"
        defaultMessage="Filter by state" />
    ),
    popupClassName: "post-list-advanced-settings-dropdown",
    children: [
      {
        key: "draft",
        label: (
          <InfinityListSettingsCheckbox
            message={
              <FormattedMessage
                id="creations.list.filtering.advancedSettings.statusFilter.draft.label"
                defaultMessage="Draft" />
            }
            path={["creationList", "statusFilter", "draft"]}
          />
        )
      },
      {
        key: "scheduled",
        label: (
          <InfinityListSettingsCheckbox
            message={
              <FormattedMessage
                id="creations.list.filtering.advancedSettings.statusFilter.scheduled.label"
                defaultMessage="Scheduled" />
            }
            path={["creationList", "statusFilter", "scheduled"]}
          />
        )
      },
      {
        key: "published",
        label: (
          <InfinityListSettingsCheckbox
            message={
              <FormattedMessage
                id="creations.list.filtering.advancedSettings.statusFilter.published.label"
                defaultMessage="Published" />
            }
            path={["creationList", "statusFilter", "published"]}
          />
        )
      },
      {
        key: "removed",
        label: (
          <InfinityListSettingsCheckbox
            message={
              <FormattedMessage
                id="creations.list.filtering.advancedSettings.statusFilter.removed.label"
                defaultMessage="Removed" />
            }
            path={["creationList", "statusFilter", "removed"]}
          />
        )
      }
    ]
  }

]

