import React, { useEffect, useMemo, useState } from 'react'
import useAPI from 'services/hooks/useAPI'
import useAPIWithIncrementsNoPages from 'services/hooks/useAPIWithIncrementsNoPages'
import useSelectorWithParams from 'services/hooks/useSelectorWithParams'
import { useDispatch } from 'react-redux'
import { HorizontalCenter } from 'components/common/Center'
import { Spin } from 'antd'
import FormattedMessage from 'components/common/FormattedMessage'
import TemplateRow from 'tools/infinityList/TemplateRow'
import { TransactionColumns, TransactionColumnsToExport, TransactionListHeaders, TransactionsListAdvancedSettings } from './SellerConfig'
import Toolbar from 'components/toolbar/Toolbar'
import InfinityListQuery from 'tools/infinityList/infinityListQuery'
import InfinityListAdvancedSettingsCheckboxs from 'tools/infinityList/infinityListAdvancedSettingsCheckboxs'
import InfinityList from 'tools/infinityList/infinityList'
import { InfinityListResetFilters } from 'tools/infinityList/infinityListResetFilters'
import InfinityListAdvancedSettings from 'tools/infinityList/infinityListAdvancedSettings'
import { ExportButton } from 'services/hooks/useDataExport'
import { GetSeller, GetSellerCalculations, SortListByDate } from './state/sellerEndpoints'
import { filteredTransactionsCountSelector, TransactionsSelector } from './state/sellerSelectors'
import { sellerSlice } from './state/sellerSilce'
import { TransactionProps } from './state/sellerTypes'

export default function SellerCalculations() {
  const [query, setQuery] = useState<any>()
  const getCalculations = useAPI(GetSellerCalculations(), true)
  const seller = useAPI(GetSeller, true)

  const calculations = useSelectorWithParams([TransactionsSelector]);

  const filteredTransactionsCount = useSelectorWithParams(filteredTransactionsCountSelector)

  const dispatch = useDispatch();
  const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
    callAPI: GetSellerCalculations,
    query: query
  })

  useEffect(() => {
    reloadList();
  }, [query]);

  const reloadList = () => {
    dispatch(sellerSlice.actions.clearCalculations)
    ListGetCalculations();
  }

  const onQueryChange = (query: any) => {
    const normalizedQuery: any = {
      "date[from]": query.start.format("YYYY-MM-DD"),
      "date[to]": query.end.format("YYYY-MM-DD"),
      "sort": query.sort || "created_at",

    }

    if (query.search) {
      normalizedQuery["search"] = query.search;
    }
    reloadList();
    setQuery(normalizedQuery);
  }

  const GetMore = () => {
    getNextIncrement((q) => {
      if (!q) {
        return;
      }
      const oldQ = q;
      const previousFrom = new Date(oldQ["date[from]"]);
      const previousTo = new Date(oldQ["date[to]"]);

      let newFrom: Date;
      let newTo: Date;

      if (query?.sort === "created_at") {
        newFrom = new Date(previousTo.getTime());
        newTo = new Date(newFrom.getTime() + 1000 * 60 * 60 * 24 * 7);
      } else {
        newFrom = new Date(previousFrom.getTime() - 1000 * 60 * 60 * 24 * 7);
        newTo = new Date(previousFrom.getTime());
      }

      return {
        ...oldQ,
        "date[from]": newFrom.toISOString().split("T")[0],
        "date[to]": newTo.toISOString().split("T")[0]
      };
    })
  }

  const handleLoadNextPage = () => {
    if (emptyResponses < 3) GetMore();
  }


  const ListGetCalculations = () => {
    if (!query) return;
    startWithNewQuery(query);
  }

  const sortedCalculations = useMemo(() => {

    if (!calculations) return [];

    const filteredCalculations = calculations;

    if (!query || !query.sort) return calculations;
    if (query.sort === "created_at") {
      return SortListByDate(filteredCalculations, false);
    }
    if (query.sort === "-created_at") {
      return SortListByDate(filteredCalculations, true);
    }
    return filteredCalculations;

  }, [calculations])

  const renderCalculation = (calculation: TransactionProps) => {
    return (
      <div
        className="infinity-item-wrapper standard-border with-standard-border"
      >
        <TemplateRow size="medium" item={calculation} columns={TransactionColumns} />
      </div>
    )
  }

  const extra = useMemo(() => {

    if (calculations && calculations.length > 0 && loading) return (
      <HorizontalCenter style={{ padding: "30px" }} key="loading">
        <Spin />
      </HorizontalCenter>
    )
    if (emptyResponses >= 3) return (
      <HorizontalCenter style={{ padding: "30px" }} key="nothing">
        <h3>
          <FormattedMessage
            id="seller.calculations.list.error.nothingMore"
            defaultMessage="Nothing more to show. Please change the date range."
          />
        </h3>
      </HorizontalCenter>
    )
    return null
  }, [emptyResponses, loading])

  return (
    <>
      <div className="infinity-wrapper">
        <Toolbar
          width="280px"
          title={(
            <FormattedMessage
              id="panel.components.seller.list.calulations"
              defaultMessage="Calulations"
            />
          )}
          smallToolbar={(
            <>
              <Toolbar.Spacer />
              <InfinityListQuery
                onChange={onQueryChange}
                onReload={reloadList}
                sort={false}
                date={false}
                refresh={true}
              >
                <ExportButton
                  filename="transactions"
                  type="csv"
                  columns={TransactionColumnsToExport}
                  items={calculations}
                />
              </InfinityListQuery>
            </>
          )}
          largeToolbar={(<>
            <Toolbar.Spacer />
            <InfinityListQuery
              onChange={onQueryChange}
              onReload={reloadList}
              sort={true}
              date={true}
              refresh={true}
            >
              <ExportButton
                  filename="transactions"
                  type="csv"
                  columns={TransactionColumnsToExport}
                  items={calculations}
                />
              <InfinityListResetFilters filteredItems={filteredTransactionsCount} resetSettings={'calculationsList'} />
              <InfinityListAdvancedSettings settings={TransactionsListAdvancedSettings} />
            </InfinityListQuery>
          </>
          )}
          drawerToolbar={(<>
            <InfinityListQuery
              onChange={onQueryChange}
              onReload={reloadList}
              sort={true}
              date={true}
              refresh={true}
            >
              <InfinityListAdvancedSettingsCheckboxs settings={TransactionsListAdvancedSettings} />
            </InfinityListQuery>
          </>
          )}
        />

        <InfinityList
          headers={TransactionListHeaders}
          items={sortedCalculations}
          loading={getCalculations.loading}
          itemSkeleton={() => "skeleton"}
          renderItem={renderCalculation}
          loadMoreData={handleLoadNextPage}
          additionalItems={[
            extra
          ]}
        />
      </div>
    </>
  )
}
