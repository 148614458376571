import { faCode, faPlus, faQrcode } from "@fortawesome/pro-light-svg-icons";
import { Button, Input, Space, Table, Typography } from "antd";
import CopyToClipboard from "components/common/CopyToClipboard";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import CodeDisplay from "components/displays/CodeDisplay";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import useAPI from "services/hooks/useAPI";
import useDownloadSVG from "services/hooks/useDownloadSVG";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { GetReviewLinks, NewReviewLink, ProjectReviewLinksSelector } from "state/callAppSlice";
import RelaxField from "tools/relaxForm/RelaxField";

const NewReviewLinkModal = () => {

    const [ref, open, close] = useStandardModal();
    const [name, setName] = useState("");
    const newLinkCall = useAPI({});

    return (
        <>
            <StandardButton onClick={open} type="primary" icon={faPlus}>
                <FormattedMessage
                    id="modules.call.reviewsConfiguration.links.newLink"
                    defaultMessage="New Review Link"
                />
            </StandardButton>
            <StandardModal
                ref={ref}
                onCancel={close}
                onOk={() => {
                    newLinkCall.call(NewReviewLink(name))
                    close()
                }}
                title={<FormattedMessage
                    id="modules.call.reviewsConfiguration.links.newLink.modal.title"
                    defaultMessage="New Review Link"
                />}
            >
                <RelaxField
                    name="name"
                    submitOnChange
                    hideStatus={true}
                    submitOverride={(v) => setName(v?.name)}
                    label={<FormattedMessage
                        id="modules.call.reviewsConfiguration.links.newLink.modal.name"
                        defaultMessage="Name"
                    />}
                >
                    <Input />
                </RelaxField>
            </StandardModal>
        </>
    )

}

const ReviewLinkQRCode = ({ reviewLink }: any) => {

    const [ref, open, close] = useStandardModal();
    const { downloadAsSVG, downloadAsPNG } = useDownloadSVG("linkQRCode");

    return (
        <>
            <StandardButton icon={faQrcode} onClick={open} />
            <StandardModal
                ref={ref}
                onCancel={close}
                title={<FormattedMessage
                    id="modules.call.reviewsConfiguration.links.qrCode.modal.title"
                    defaultMessage="Your link: {name}"
                    values={{
                        name: reviewLink.name
                    }}
                />}
                modalProps={{
                    width: 900,
                    footer: null,
                }}
            >
                <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center"}}>
                    <Typography.Link href={reviewLink.review_url} target="_blank" style={{padding: "20px 0px"}}>
                        {reviewLink.review_url}
                    </Typography.Link>
                    <QRCode value={reviewLink.review_url} id="linkQRCode"/>
                    <FormattedMessage
                        id="modules.call.reviewsConfiguration.links.qrCode.modal.download"
                        defaultMessage="Download as:"
                    />
                    <Button.Group>
                        <StandardButton onClick={() => downloadAsPNG(512)}>PNG</StandardButton>
                        <StandardButton onClick={downloadAsSVG}>SVG</StandardButton>
                    </Button.Group>
                </div>
            </StandardModal>
        </>
    )

}

const ReviewLinkIframe = ({ reviewLink }: any) => {

    const [ref, open, close] = useStandardModal();

    return (
        <>
        <StandardButton icon={faCode} onClick={open} />
        <StandardModal
            ref={ref}
            onCancel={close}
            title={<FormattedMessage
                id="modules.call.reviewsConfiguration.links.iframe.modal.title"
                defaultMessage="iFrame"
            />}
            modalProps={{
                width: 900,
                footer: null,
            }}
        >
            <CodeDisplay code={`<iframe src="${reviewLink.review_url}" width="100%" height="100%"></iframe>`} />
        </StandardModal>
        </>
    )

}

const ReviewLinks = () => {

    const getReviewLinksCall = useAPI(GetReviewLinks, true)
    const reviewLinks = useSelectorWithParams(ProjectReviewLinksSelector)

    return (
        <div>
            <Table
                dataSource={Object.values(reviewLinks || {})}
                pagination={false}
                title={() => <NewReviewLinkModal />}
                columns={[
                    {
                        title: <FormattedMessage
                            id="modules.call.reviewsConfiguration.links.table.name"
                            defaultMessage="Name"
                        />,
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: <FormattedMessage
                            id="modules.call.reviewsConfiguration.links.table.callCount"
                            defaultMessage="Call Count"
                        />,
                        dataIndex: "call_count",
                        key: "call_count",
                    },
                    {
                        //link
                        title: <FormattedMessage
                            id="modules.call.reviewsConfiguration.links.table.link"
                            defaultMessage="Link"
                        />,
                        key: "link",
                        render: (record) => <Space>{record.review_url}<CopyToClipboard text={record.review_url}/></Space>
                    },
                    {
                        title: <FormattedMessage
                            id="modules.call.reviewsConfiguration.links.table.qrCode"
                            defaultMessage="QR Code"
                        />,
                        key: "qrCode",
                        render: (record) => <ReviewLinkQRCode reviewLink={record} />
                    },
                    {
                        title: <FormattedMessage
                            id="modules.call.reviewsConfiguration.links.table.iframe"
                            defaultMessage="iFrame"
                        />,
                        key: "iframe",
                        render: (record) => <ReviewLinkIframe reviewLink={record} />
                    }

                ]} />
        </div>
    )

}

export default ReviewLinks;