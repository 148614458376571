import StandardButton from "components/common/StandardButton"
import { useSubscriptionContext } from "./SubscriptionContext";
import FormattedMessage from "components/common/FormattedMessage";
import { FormattedNumber } from "react-intl";
import Payment from "components/purchases/Payment";
import PaymentMethodSelect from "components/purchases/PaymentMenthodSelect";
import { Alert } from "antd";
import { callAPIProps } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { subscriptionsArraySelector, subscriptionsArrayWithFilterSelector } from "state/subscriptions/subscriptionsSelectors";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { useMemo } from "react";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";

export const PaySubscriptionDebtButton = () => {

    const { subscription, openDetails } = useSubscriptionContext();

    if (!subscription) return null;
    if (!subscription?._generated?.fullyPaid)
        return (
            <StandardButton
                size="small"
                style={{
                    padding: "2px 10px",
                    minHeight: "0px",
                    borderRadius: "100px",
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    openDetails("pay");
                }}
            >
                <FormattedMessage
                    id="panel.subscriptions.actions.pay"
                    defaultMessage="Pay"
                />
            </StandardButton>
        )

    return null;

}

export const SubscriptionDebtPayment = () => {


    const { subscription } = useSubscriptionContext();


    if (!subscription) return null;
    if (!subscription?._generated) return null;

    if (subscription._generated.fullyPaid) return (
        <FormattedMessage
            id="panel.subscriptions.payment.debt.fullyPaid"
            defaultMessage="Fully paid"
        />
    )

    const paymentCall = (): callAPIProps => {
        return {
            url: ({ getApiUrl, projectId }) => getApiUrl(`user/pay-subscription`),
            method: "POST",
            body: {
                subscription_id: subscription.id + ""
            }
        }
    }

    const handleError = (error: any) => {
        console.log("error", error)
    }

    const handleSuccess = () => {
        console.log("Success")
    }

    return (
        <div>
            <Alert
                type="error"
                style={{
                    padding: "20px"
                }}
                message={
                    <div>
                        <FormattedMessage
                            id="panel.subscriptions.payment.debt.warning"
                            defaultMessage="Your subscription is overdue. Please pay to avoid service interruption."
                        />
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}>
                            <span>
                                <FormattedMessage
                                    id="panel.subscriptions.payment.debt.summary"
                                    defaultMessage="Unpaid amount:"
                                />
                            </span>
                            <span>
                                <FormattedNumber
                                    value={subscription._generated?.totalDue}
                                    style="currency"
                                    currency={subscription._generated?.currency}
                                />
                            </span>
                        </div>
                    </div>
                }
            />
            <Payment
                canPay={true}
                paymentCall={paymentCall()}
                onComplete={handleSuccess}
                onError={handleError}
                paymentButtonLabel={
                    <FormattedMessage
                        id="panel.subscriptions.payment.debt.pay"
                        defaultMessage="Pay"
                    />
                }
            >
                <PaymentMethodSelect />
            </Payment>
        </div>
    )

}


export const AllSubscriptionsPaymentDueAlert = () => {

    const subscriptions = useSelectorWithParams([subscriptionsArraySelector]);
    const [drawerRef, open, close] = useStandardDrawer();

    const unpaidSum = useMemo(() => {
        if (!subscriptions) return 0;
        if (!subscriptions.length) return 0;
        return subscriptions.reduce((acc, sub) => {
            return acc + sub._generated?.totalDue;
        }, 0)
    }, [subscriptions])


    const alertProps = useMemo(() => {
        if (unpaidSum <= 0) return {};
        if (!subscriptions) return {};
        return {
            type: "error",
            message: <FormattedMessage
                id="panel.subscriptions.allSubscriptionsPayment.message"
                defaultMessage="You have unpaid subscriptions."
            />,
            description: <>
                <FormattedMessage
                    id="panel.subscriptions.allSubscriptionsPayment.description"
                    defaultMessage="Total unpaid amount:"
                />
                {" "}
                <b>
                    <FormattedNumber
                        value={unpaidSum}
                        style="currency"
                        currency={subscriptions[0]._generated?.currency}
                    />
                </b>
            </>,
            showIcon: true,
            icon: <AwesomeIcon icon={faExclamationCircle} />
        }
    }, [subscriptions, unpaidSum])

    
    if (unpaidSum <= 0) return null;
    if (!subscriptions) return null;

    return (
        <>
            <Alert
                {...alertProps}
                action={(<StandardButton
                    onClick={open}
                >
                    <FormattedMessage
                        id="common.payNow"
                        defaultMessage="Pay now"
                    />
                </StandardButton>)}
            />
            <StandardDrawer
                ref={drawerRef}
                title={<FormattedMessage
                    id="panel.subscriptions.allSubscriptionsPayment.drwerTitle"
                    defaultMessage="Pay for all subscriptions"
                />}
                width={600}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}>
                    <Alert
                        {...alertProps}
                    />
                    <Payment
                        canPay={true}
                        paymentCall={{
                            url: ({ getApiUrl, projectId }) => getApiUrl(`user/debts/collect`),
                            method: "POST",
                            body: {}
                        }}
                        onComplete={() => {
                            close();
                        }}
                        onError={() => {
                            //close();
                        }}
                        paymentButtonLabel={
                            <FormattedMessage
                                id="common.payNow"
                                defaultMessage="Pay now"
                            />
                        }
                    >
                        <PaymentMethodSelect />
                    </Payment>
                </div>
            </StandardDrawer>
        </>
    )


}