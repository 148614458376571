import useAPI from "services/hooks/useAPI";
import FormattedMessage from "components/common/FormattedMessage";
import { getApiUrl } from "services/helpers/api-helpers";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import Tabs from "_components/tabs/tabs";
import { useMemo } from "react";
import DateTimeInput from "components/inputs/DateTimeInput";
import dayjs from 'dayjs';
import Switch from "_components/forms/switch";
import { Form, Input, Slider } from "antd";
import DatePicker from "components/inputs/DatePicker";
import { GetCallApp, ProjectCallAppSelector, callAppSlice } from "state/callAppSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const labels = {
    "active": <FormattedMessage
        id="modules.smartcall.containers.config.active"
        defaultMessage="Active"
    />,
    "message_content": <FormattedMessage
        id="modules.smartcall.containers.config.messageContent"
        defaultMessage="Message"
    />,
    "discount": <FormattedMessage
        id="modules.smartcall.containers.config.discount"
        defaultMessage="Discount"
    />,
    "discount_percentage": <FormattedMessage
        id="modules.smartcall.containers.config.discountPercentage"
        defaultMessage="Discount Percentage"
    />,
}

const DiscountSlider = (props: any) => {

    return (
        <Slider
            tooltip={{ formatter: (value) => `${value}%` }}
            marks={{ 0: "0%", 100: "100%" }}
            style={{ margin: "0px 20px" }}
            {...props} />
    )

}


export default function CallConfiguration() {


    const panes = [
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.massCampaign"
                defaultMessage="Mass Campaign"
            />,
            key: "massCampaign",
            content: (
                <div>
                    <FormattedMessage
                        id="modules.smartcall.containers.config.massCampaign.description"
                        defaultMessage="Mass Campaign description"
                    />
                    <RelaxSingleField
                        name={["configurations", "mass", "active"]}
                        label={labels["active"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.massCampaign.active.tooltip"
                            defaultMessage="Mass campaign active tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "message_content"]}
                        label={labels["message_content"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.massCampaign.messageContent.tooltip"
                            defaultMessage="Mass campaign message content tooltip"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "start_date"]}
                        label={<FormattedMessage
                            id="modules.smartcall.containers.config.mass.calledWithin"
                            defaultMessage="Mass campaign start date"
                        />}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.massCampaign.startDate.tooltip"
                            defaultMessage="Mass campaign start date tooltip"
                        />}
                    >
                        <DateTimeInput
                            disabledDate={true}
                            allowedPeriodStart={dayjs()}
                            allowedPeriodEnd={dayjs().add(100, "day")}
                        />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "discount"]}
                        label={labels["discount"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.massCampaign.discount.tooltip"
                            defaultMessage="Mass campaign discount tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "discount_percentage"]}
                        label={labels["discount_percentage"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.massCampaign.discountPercentage.tooltip"
                            defaultMessage="Mass campaign discount percentage tooltip"
                        />}
                    >
                        <DiscountSlider />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "sms_per_hour"]}
                        label="SMS per hour"
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.massCampaign.smsPerHour.tooltip"
                            defaultMessage="Mass campaign SMS per hour tooltip"
                        />}
                    >
                        <Slider
                            marks={{ 0: "0", 100: "100" }}
                            style={{ margin: "0 20px" }}
                        />
                    </RelaxSingleField>
                </div>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.messageWithConsent"
                defaultMessage="Message with Consent"
            />,
            key: "messageWithConcent",
            content: (
                <>
                    <FormattedMessage
                        id="modules.smartcall.containers.config.messageWithConsent.description"
                        defaultMessage="Message with Consent description"
                    />
                    <RelaxSingleField
                        name={["configurations", "consent", "facebook_targeting"]}
                        label="Facebook Targeting"
                        initialValues={{ configurations: { consent: { facebook_targeting: true } } }}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.messageWithConcent.facebookTargeting.tooltip"
                            defaultMessage="Facebook Targeting tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "consent", "marketing_messages"]}
                        label="Marketing Messages"
                        initialValues={{ configurations: { consent: { marketing_messages: true } } }}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.messageWithConcent.marketingMessages.tooltip"
                            defaultMessage="Marketing Messages tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "consent", "consent_content"]}
                        label="Content"
                        submitOnChange={false}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.messageWithConcent.content.tooltip"
                            defaultMessage="Content tooltip"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "consent", "message_content"]}
                        label="Message"
                        submitOnChange={false}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.messageWithConcent.message.tooltip"
                            defaultMessage="Message tooltip"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.welcomeMessage"
                defaultMessage="Welcome Message"
            />,
            key: "welcomeMessage",
            content: (
                <>
                    <FormattedMessage
                        id="modules.smartcall.containers.config.welcomeMessage.description"
                        defaultMessage="Welcome Message description"
                    />
                    <RelaxSingleField
                        name={["configurations", "welcome", "active"]}
                        label={labels["active"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.welcomeMessage.active.tooltip"
                            defaultMessage="Welcome message active tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "welcome", "message_content"]}
                        label={labels["message_content"]}
                        submitOnChange={false}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.welcomeMessage.messageContent.tooltip"
                            defaultMessage="Welcome message content tooltip"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "welcome", "discount"]}
                        label={labels["discount"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.welcomeMessage.discount.tooltip"
                            defaultMessage="Welcome message discount tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "welcome", "discount_percentage"]}
                        label={labels["discount_percentage"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.welcomeMessage.discountPercentage.tooltip"
                            defaultMessage="Welcome message discount percentage tooltip"
                        />}
                    >
                        <DiscountSlider />
                    </RelaxSingleField>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.thanks"
                defaultMessage="Thanks"
            />,
            key: "thanks",
            content: (<>
                <FormattedMessage
                    id="modules.smartcall.containers.config.thanks.description"
                    defaultMessage="Thanks description"
                />
                <RelaxSingleField
                    name={["configurations", "thanks", "active"]}
                    label={labels["active"]}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.thanks.active.tooltip"
                        defaultMessage="Thanks active tooltip"
                    />}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "thanks", "message_content"]}
                    label={labels["message_content"]}
                    submitOnChange={false}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.thanks.messageContent.tooltip"
                        defaultMessage="Thanks message content tooltip"
                    />}
                >
                    <Input />
                </RelaxSingleField>
            </>)
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.reminder"
                defaultMessage="Reminder"
            />,
            key: "reminder",
            content: (
                <>
                    <FormattedMessage
                        id="modules.smartcall.containers.config.reminder.description"
                        defaultMessage="Reminder description"
                    />
                    <RelaxSingleField
                        name={["configurations", "reminder", "active"]}
                        label={labels["active"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.active.tooltip"
                            defaultMessage="Reminder active tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "message_content"]}
                        label={labels["message_content"]}
                        submitOnChange={false}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.messageContent.tooltip"
                            defaultMessage="Reminder message content tooltip"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "called_within"]}
                        label={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.calledWithin"
                            defaultMessage="Not called within"
                        />}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.calledWithin.tooltip"
                            defaultMessage="Not called within tooltip"
                        />}
                    >
                        <DatePicker />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "discount"]}
                        label={labels["discount"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.discount.tooltip"
                            defaultMessage="Reminder discount tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "discount_percentage"]}
                        label={labels["discount_percentage"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.discountPercentage.tooltip"
                            defaultMessage="Reminder discount percentage tooltip"
                        />}
                    >
                        <DiscountSlider />
                    </RelaxSingleField>
                </>
            )
        },

        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.bestClient"
                defaultMessage="Best Client"
            />,
            key: "bestClient",
            content: (<>
                <FormattedMessage
                    id="modules.smartcall.containers.config.bestClient.description"
                    defaultMessage="Best Client description"
                />
                <RelaxSingleField
                    name={["configurations", "best_client", "active"]}
                    label={labels["active"]}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.active.tooltip"
                        defaultMessage="Best Client active tooltip"
                    />}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "message_content"]}
                    label={labels["message_content"]}
                    submitOnChange={false}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.messageContent.tooltip"
                        defaultMessage="Best Client message content tooltip"
                    />}
                >
                    <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "send_outgoing_calls"]}
                    label={"Send outgoing calls"}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.sendOutgoingCalls.tooltip"
                        defaultMessage="Best Client send outgoing calls tooltip"
                    />}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "discount"]}
                    label={labels["discount"]}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.discount.tooltip"
                        defaultMessage="Best Client discount tooltip"
                    />}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "discount_percentage"]}
                    label={labels["discount_percentage"]}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.discountPercentage.tooltip"
                        defaultMessage="Best Client discount percentage tooltip"
                    />}
                >
                    <DiscountSlider />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "called_within"]}
                    label={"Called within"}
                    submitOnChange={false}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.calledWithin.tooltip"
                        defaultMessage="Best Client called within tooltip"
                    />}
                >
                    <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "called_min"]}
                    label={"Called min"}
                    submitOnChange={false}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.bestClient.calledMin.tooltip"
                        defaultMessage="Best Client called min tooltip"
                    />}
                >
                    <Input />
                </RelaxSingleField>
            </>)
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.automaticPresence"
                defaultMessage="Automatic Presence"
            />,
            key: "automaticPresence",
            content: (
                <>
                    <FormattedMessage
                        id="modules.smartcall.containers.config.automaticPresence.description"
                        defaultMessage="Automatic Presence description"
                    />
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "active"]}
                        label={labels["active"]}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.automaticPresence.active.tooltip"
                            defaultMessage="Automatic Presence active tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "message_content"]}
                        label={labels["message_content"]}
                        submitOnChange={false}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.automaticPresence.messageContent.tooltip"
                            defaultMessage="Automatic Presence message content tooltip"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "working_hours_integration"]}
                        label={"Working hours integrations"}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.automaticPresence.workingHoursIntegration.tooltip"
                            defaultMessage="Automatic Presence working hours integration tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "sms_only_confirmed_numbers"]}
                        label={"Sms only confirmed numbers"}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.automaticPresence.smsOnlyConfirmedNumbers.tooltip"
                            defaultMessage="Automatic Presence sms only confirmed numbers tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.orderHandling"
                defaultMessage="Order Handling"
            />,
            key: "messages",
            content: (
                <>
                    <div>
                        <h3>
                            <FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderAdded.title"
                                defaultMessage="Order Added"
                            />
                        </h3>
                        <FormattedMessage
                            id="modules.smartcall.containers.config.orderHandling.orderAdded.description"
                            defaultMessage="Order Added description"
                        />
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_added", "active"]}
                            label={labels["active"]}
                            tooltip={<FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderAdded.active.tooltip"
                                defaultMessage="Order Added active tooltip"
                            />}
                        >
                            <Switch />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_added", "message_content"]}
                            label={labels["message_content"]}
                            tooltip={<FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderAdded.messageContent.tooltip"
                                defaultMessage="Order Added message content tooltip"
                            />}
                        >
                            <Input />
                        </RelaxSingleField>
                    </div>
                    <div>
                        <h3>
                            <FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderMiddle.title"
                                defaultMessage="Order Middle"
                            />
                        </h3>
                        <FormattedMessage
                            id="modules.smartcall.containers.config.orderHandling.orderMiddle.description"
                            defaultMessage="Order Middle description"
                        />
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_middle", "active"]}
                            label={labels["active"]}
                            tooltip={<FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderMiddle.active.tooltip"
                                defaultMessage="Order Middle active tooltip"
                            />}
                        >
                            <Switch />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_middle", "message_content"]}
                            label={labels["message_content"]}
                            submitOnChange={false}
                            tooltip={<FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderMiddle.messageContent.tooltip"
                                defaultMessage="Order Middle message content tooltip"
                            />}
                        >
                            <Input />
                        </RelaxSingleField>
                    </div>
                    <div>
                        <h3>
                            <FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderComplete.title"
                                defaultMessage="Order Complete"
                            />
                        </h3>
                        <FormattedMessage
                            id="modules.smartcall.containers.config.orderHandling.orderComplete.description"
                            defaultMessage="Order Complete description"
                        />
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_complete", "active"]}
                            label={labels["active"]}
                            tooltip={<FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderComplete.active.tooltip"
                                defaultMessage="Order Complete active tooltip"
                            />}
                        >
                            <Switch />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_complete", "message_content"]}
                            label={labels["message_content"]}
                            tooltip={<FormattedMessage
                                id="modules.smartcall.containers.config.orderHandling.orderComplete.messageContent.tooltip"
                                defaultMessage="Order Complete message content tooltip"
                            />}
                        >
                            <Input />
                        </RelaxSingleField>
                    </div>
                    
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.locker"
                defaultMessage="Locker"
            />,
            key: "locker",
            content: (<>
                <FormattedMessage
                    id="modules.smartcall.containers.config.locker.description"
                    defaultMessage="Locker description"
                />
                <RelaxSingleField
                    name={["configurations", "locker", "active"]}
                    label={labels["active"]}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.locker.active.tooltip"
                        defaultMessage="Locker active tooltip"
                    />}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "locker", "pin"]}
                    label={"Pin"}
                    submitOnChange={false}
                    tooltip={<FormattedMessage
                        id="modules.smartcall.containers.config.locker.pin.tooltip"
                        defaultMessage="Locker pin tooltip"
                        />}
                >
                    <Input />
                </RelaxSingleField>
            </>)
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.advancedSettings"
                defaultMessage="Advanced Settings"
            />,
            key: "advancedSettings",
            content: (
                <>
                    <FormattedMessage
                        id="modules.smartcall.containers.config.advancedSettings.description"
                        defaultMessage="Advanced Settings description"
                    />
                    <RelaxSingleField
                        name={["configurations", "sent_outgoing_calls_to_fb", "active"]}
                        label={"Send outgoing calls to FB"}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.advancedSettings.sendOutgoingCallsToFB.tooltip"
                            defaultMessage="Advanced Settings send outgoing calls to FB tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "update_settings", "active"]}
                        label={"Update Settings"}
                        tooltip={<FormattedMessage
                            id="modules.smartcall.containers.config.advancedSettings.updateSettings.tooltip"
                            defaultMessage="Advanced Settings update settings tooltip"
                        />}
                    >
                        <Switch />
                    </RelaxSingleField>
                </>
            )
        },
    ];

    const { } = useAPI(GetCallApp, true);

    const config = useSelectorWithParams(ProjectCallAppSelector)

    const url = useMemo(() => {
        if (config) {
            return ({ projectId }: any) => getApiUrl(`projects/${projectId}/call-app/configuration/${config.id}`)
        } else {
            return ({ projectId }: any) => getApiUrl(`projects/${projectId}/call-app/configuration`)
        }
    }, [config])

    return (
        <RelaxMultiform
            name="multiform"
            initialValues={{ configurations: config && config.configurations }}
            callAPI={(a: any, call) => ({
                url: url,
                method: () => (config as any) ? "PATCH" : "POST",
                successDispatch: callAppSlice.actions.update,
                auth: true,
                ...call
            })}
            submitOnChange
        >
            <Tabs
                panes={panes} />
        </RelaxMultiform>
    )
}