import { createSlice } from "@reduxjs/toolkit";
import { SellerProps } from "./sellerTypes";

const initialState: Partial<SellerProps> = {};

export const sellerSlice = createSlice({
    name: "seller",
    initialState,
    reducers: {
        getSeller: (state, action) => {
            return { ...state, ...action.payload.data };
        },
        getCalculations: (state, action) => {
            state.calculations = action.payload.data;
        },
        clearCalculations: (state, action) => {
            state.calculations = []
        },
        getCoupons: (state, action) => {
            state.coupons = action.payload.data;
        },
        getSubscriptions: (state, action) => {
            state.subscriptions = [...(state.subscriptions ?? []), ...action.payload.data];
        },        
        clearSubscriptions: (state, action) => {
            state.subscriptions = []
        },
    },
});