import React from "react";
import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import { Layout } from "providers/routing/layouts/templates/templates";
import FormattedMessage from "components/common/FormattedMessage";
import Helper from "providers/routing/routes/helper";
import YouCanDownloadAppHereOnboarding from "modules/call/containers/onboarding/download-here";
import CallConfiguration from "../components/CallConfiguration";
import GetResponseConfig from "../components/GetResponse/GetResponseConfig";
import AppDownloadInstructions from "../components/AppDownloadInstructions";
import PhoneList from "modules/panel/components/projects/phones/PhoneList";
import CallMeConfig from "../components/callMe/CallMeConfig";
import { faDownload, faGear, faPhone, faStarSharp, faStarSharpHalfStroke } from "@fortawesome/pro-light-svg-icons";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import ReviewsConfiguration from "../components/Reviews/ReviewsConfiguration";

export const INSTRUCTION = "instruction",
  ONBOARDING_INSTRUCTION = "onboardingInstruction",
  PROJECT_PHONES = "projectPhones",
  CONFIGURATION = "configuration",
  GETRESPONSE = "getResponse",
  CALL_ME_CONFIG = "callMeConfig",
  REVIEWS = "reviews";

export const APP_PATH = "smartcall";

const routes: LayoutRouteConfig[] = [
  {
    key: PROJECT_PHONES,
    path: "/project/phones",
    icon: <AwesomeLarge icon={faPhone} />,
    name: (
      <FormattedMessage
        id="apps.smartcall.routing.projectPhones"
        defaultMessage="Phones"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smartcall.routing.projectPhones"
        defaultMessage="Phones"
      />
    ),
    content: PhoneList,
  },
  {
    key: INSTRUCTION,
    path: "/instruction",
    icon: <AwesomeLarge icon={faDownload} />,
    name: (
      <FormattedMessage
        id="apps.smartcall.routing.downloadApp"
        defaultMessage="Download app"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smartcall.routing.downloadApp"
        defaultMessage="Download app"
      />
    ),
    content: AppDownloadInstructions,
  },
  {
    key: CONFIGURATION,
    path: "/configuration",
    icon: <AwesomeLarge icon={faGear} />,
    name: (
      <FormattedMessage
        id="apps.smartcall.routing.configuration"
        defaultMessage="Configuration"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smartcall.routing.configuration"
        defaultMessage="Configuration"
      />
    ),
    content: CallConfiguration,
    mainHasBackground: false,
  },
  {
    key: REVIEWS,
    path: "/reviews",
    icon: <AwesomeLarge icon={faStarSharpHalfStroke} />,
    name: (
      <FormattedMessage
        id="apps.smartcall.routing.reviews"
        defaultMessage="Reviews"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smartcall.routing.reviews"
        defaultMessage="Reviews"
      />
    ),
    content: ReviewsConfiguration,
    mainHasBackground: false,
  },
  {
    key: CALL_ME_CONFIG,
    path: "/callMe",
    icon: <AwesomeLarge icon={faGear} />,
    name: (
      <FormattedMessage
        id="apps.smartcall.routing.configuration"
        defaultMessage="Configuration"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smartcall.routing.configuration"
        defaultMessage="Configuration"
      />
    ),
    content: CallMeConfig,
    mainHasBackground: false,
  },
  {
    key: GETRESPONSE,
    path: "/getResponse",
    icon: <AwesomeLarge icon={faGear} />,
    name: (
      <FormattedMessage
        id="apps.smartcall.routing.getResponse"
        defaultMessage="GetResponse"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smartcall.routing.getResponse"
        defaultMessage="GetResponse"
      />
    ),
    content: GetResponseConfig,
    mainHasBackground: true,
  },
  {
    key: ONBOARDING_INSTRUCTION,
    path: "/onboarding/instruction",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: YouCanDownloadAppHereOnboarding,
  },
];

export default routes;
export const getUrl = (routeKey: string) =>
  Helper.getPath(APP_PATH, routes, routeKey);
