
import { ProductConfig } from "products";
import logo from "products/betaTesterProject/betaTesterProject.svg";


const config: ProductConfig = {
  name: "BetaTesterProject",
  logo,
  requirements: [],
  providers: ["facebook"],
};

export default config;
