import { ReactNode } from "react";
import { KeywordsConfig } from "modules/panel/config/KeywordsConfig";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import StandardButton from "components/common/StandardButton";
import { AllAdsAndRemarkettingKeywords } from "modules/smart/smartSlice";

const Kwd = ({keyword, onSelect}:{keyword: ReactNode, onSelect: (keyword: ReactNode) => void}) => {

    return (
        <StandardButton
            onClick={() => onSelect(keyword)}
        >
            {keyword}
        </StandardButton>
    )

}

const InsertSingleKeyword = (props: any) => {
    const config = KeywordsConfig.get(props.keyword);
    const keywords = useSelectorWithParams([AllAdsAndRemarkettingKeywords])

    if (!config || !keywords || !keywords.initial_keywords) return null;

    const kw = keywords.initial_keywords[config.keyword.name];
    const rkw = config.remarketing && keywords.initial_keywords[config.remarketing.name];

    return (
        <>
        <div style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "10px",
            gridAutoRows: "60px",
            justifyItems: "stretch",
            alignItems: "center"
        }}>
            {config.keyword.displayName}
            {kw ? <Kwd keyword={kw} onSelect={props.onSelect} /> : <div></div>}
        </div>
        <div style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "10px",
            gridAutoRows: "60px",
            justifyItems: "stretch",
            alignItems: "center"
        }}>
                {rkw && (
                    <>
                        <div>
                        {config.remarketing?.displayName}      
                        </div>           
                        {rkw ? <Kwd keyword={rkw} onSelect={props.onSelect} /> : <div></div>}
                    </>
                )}
        </div>
        {(kw || rkw) && <div style={{height:"30px"}}></div>}
        </>
    )
}

const InsertKeyword = (props: any) => {

    const handleInsert = (keyword: string) => {
        props.onPick && props.onPick(" " + keyword);
    }

    return (
        <div style={{ 
            display: "flex",
            flexDirection: "column"
            }}>
                <InsertSingleKeyword onSelect={handleInsert} keyword="initial" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="employment" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="credit" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="housing" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="politics" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="gambling" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="forceInterest" />
                <InsertSingleKeyword onSelect={handleInsert} keyword="advantagePlus" />
        </div>
    )

}

export default InsertKeyword;