import FormattedMessage from "components/common/FormattedMessage";
import ReviewLinks from "./ReviewLinks";
import Tabs from "_components/tabs/tabs";
import ReviewsCustomization from "./ReviewsCustomization";
import QRCodeGallery from "./QRCodeGallery";
import Reviews from "./Reviews";

const appDownloadLinks: any = {
    callApp: import.meta.env.VITE_CALL_APP_DOWNLOAD_LINK,
    SmartCall: import.meta.env.VITE_CALL_APP_DOWNLOAD_LINK,
    callMe: import.meta.env.VITE_CALL_ME_DOWNLOAD_LINK,
}


const ReviewsConfiguration = () => {

    const panes = [
        {
            title: <FormattedMessage
                id="modules.call.reviewsConfiguration.panes.customization"
                defaultMessage="Customization"
            />,
            key: "customization",
            content: <ReviewsCustomization />
        },
        {
            title: <FormattedMessage
                id="modules.call.reviewsConfiguration.panes.links"
                defaultMessage="Review Links"
            />,
            key: "reviewLinks",
            content: <ReviewLinks />
        },
        {
            title: <FormattedMessage
                id="modules.call.reviewsConfiguration.panes.qrCodeGallery"
                defaultMessage="QR Code Gallery"
            />,
            key: "qrCodeGallery",
            content: <QRCodeGallery />
        },
        {
            title: <FormattedMessage
                id="modules.call.reviewsConfiguration.panes.reviews"
                defaultMessage="Reviews"
            />,
            key: "reviews",
            content: <Reviews />
        }
    ]

    return (
        <Tabs
            panes={panes}
        />
    )

}

export default ReviewsConfiguration;