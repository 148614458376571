import { ReactNode } from "react";
import { Requirement, Providers } from "modules";
import bot from "products/bot";
import call from "products/call";
import crm from "products/crm";
import smart from "products/smart";
import forsant, { orgClient, orgDirector, orgExternalPartner, orgInternalPartner, orgOwner, orgPostManager } from "products/forsant";
import carservice from "products/carservice";
import platformsAuthorization from "products/auth-user";
import freshads from "products/freshads";
import tourism from "products/tourism";
import leadAds from "products/leadAds";
import callMe from "products/callMe";
import web2learn from "products/web2learn";
import local3 from "./local3";
import lead3 from "./lead3";
import siteGenerator from "products/siteGenerator";
import { FizjoAcademyMini, FizjoAcademyOptimum, FizjoAcademySmart } from "./fizjoAcademy/fizjoAcademy";
import local2 from "./local2";
import { configPost, configPostLite } from "products/post";
import creator from "products/creator";
import insta from "products/insta";
import callCenter from "products/callCenter";
import localMax from "products/localMax";
import leadMax from "products/leadMax";
import { hospital1Config, hospital2Config } from "./hospital";
import { BobbyBurgerConfig } from "./bobbyBurger/bobbyBurger";
import faceMasterConfig from "./faceMaster/faceMaster";
import adChampionConfig from "./adChampion/adChampion";
import autositeConfig from "./autosite/autosite";
import iCallConfig from "./iCall/iCall";
import betaTesterProjectConfig from "./betaTesterProject/betaTesterProjectConfig";
export type ProductConfig = {
  name: ReactNode | string;
  logo: string;
  requirements: Requirement[];
  project?: boolean;
  providers?: Providers;
  index?: string;
};


const products = {
  BOT: bot,
  SmartCall: call,
  CRM: crm,
  FastTonyPl: smart,
  FastTonyWorld: smart,
  forsant,
  FastTonyCarservice: carservice,
  platformsAuthorization,
  freshads,
  tourism: tourism,
  CallMe: callMe,
  leadAds: leadAds,
  LeadAds: leadAds,
  Web2learn: web2learn,
  "org-client": orgClient,
  "org-external-partner": orgExternalPartner,
  "org-internal-partner": orgInternalPartner,
  "org-post-manager": orgPostManager,
  "org-director": orgDirector,
  "org-owner": orgOwner, 
  "PacketLocal3": local3,
  "PakietLocal3": local3,
  "PacketLocal2": local2,
  "PakietLead3": lead3,
  "PacketLead3": lead3,
  SiteGenerator: siteGenerator,
  "FizjoAcademyMini": FizjoAcademyMini,
  "FizjoAcademyOptimum": FizjoAcademyOptimum, 
  "FizjoAcademySmart": FizjoAcademySmart,
  post: configPost,
  Post: configPost,
  PostLite: configPostLite,
  creator: creator,
  Creator: creator,
  Insta: insta,
  CallCenter: callCenter,
  LocalMax: localMax,
  LeadMax: leadMax,
  Szpitale1: hospital1Config,
  Szpitale2: hospital2Config,
  Insta4Review: insta,
  FastTony: smart,
  BobbyBurger: BobbyBurgerConfig,
  Facemaster: faceMasterConfig,
  AdChampion: adChampionConfig,
  Autosite: autositeConfig,
  iCall: iCallConfig,
  SmartLite: smart,
  betaTesterProject: betaTesterProjectConfig,
};

export const isValidProduct = (product: any) =>
  Object.keys(products).includes(product);

export type Product = keyof typeof products;

export default products;
