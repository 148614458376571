import { pickNFromArray, randomFromArray } from 'services/helpers/fake-asset-generators';
import { fakeDates } from './fakePosts';
import dayjs, { Dayjs } from 'dayjs';

export type FakePostParams = {
    id: string;
    dateFrom: Date;
    dateTo?: Date;
    popularity?: number;
    maxNumberOfAds?: number;
}

type Provider = "facebook" | "instagram" | "google";

const SpecialType = [
    "none",
    "housing",
    "employment",
    "credit",
    "issues_elections_politics",
    "online_gambling_and_gaming"
]

const sources = [
    "facebook",
    "google",
    "promote_button"
]

const adStatuses = [
    "active",
    "active",
    "active",
    "inactive",
    "error",
    "pending_review",
    "pending_billing_info",
    "disapproved",
    "archived",
    "deleted",
    "paused",
    "adset_paused",
    "campaign_paused",
    
]



// const postStatuses = [
//     "published",
//     "published",
//     "draft",
//     "error",
//     "deleted",
// ]

const postStatuses = [
    "published",
    //"deleted",
]


const conversions = [
    'AddToCart',
    'initiate_checkout',
    'AddPaymentInfo',
    'Purchase',
    'Lead',
    'CompleteRegistration',
    'ContentView',
]

const actions = [
    "lead",
    "landing_page_view",
    "post_engagement",
    "video_view",
    "link_click",
    ...conversions
]

const randomErrors = [
    null, 
    "this-error-is-a-string",
    ["this-error-is-an-array", "this-error-is-an-array"],
    {thisErrorIsAnObject: "this-error-is-an-object"}
]

const RandomReaction = (popularity?:number) =>
Math.floor(Math.random() * 100 * (popularity || 1))

const RandomConversion = (c?: string, popularity?: number) => {
    return {
        action_type: c || randomFromArray(conversions),
        value: RandomReaction(popularity),
        cost_per_result: RandomReaction(popularity) * 0.1,
    }
}

const getInstagramAd = (startDate: Dayjs) => {

    const id = Math.floor(Math.random() * 1000000);
    const adsetId = Math.floor(Math.random() * 1000000);
    const external_ad_id = Math.floor(Math.random() * 1000000);

    return {
        ad_set_id: adsetId,
        adset: {
            id: adsetId,
            status: "ACTIVE",
            error: null,
        },
        created_at: startDate.toISOString(),
        error: "this is a debug error message",
        external_ad_id: external_ad_id,
        external_creation_id: null, 
        external_id: null,
        insights: {
            comments: RandomReaction(),
            impressions: RandomReaction(),
            likes: RandomReaction(),
            organic_reach: RandomReaction(),
            reach: RandomReaction(),
            saved: RandomReaction(),
            shares: RandomReaction(),
            total_interactions: RandomReaction(),
            video_views: RandomReaction(),
        },
        name: "ad",
        only_remarketing: 0,
        special_type: randomFromArray(SpecialType),
        status: randomFromArray(["active", "error"]),
        id: id
    }

}

const GenerateConversions = () => {
    const n = Math.floor(Math.random() * 5);
    return pickNFromArray(conversions, n).map(conversion => RandomConversion(conversion));
}

export const GenerateFakePost = (props: FakePostParams) => {


    const fbID = Math.floor(Math.random() * 1000000);
    const gID = Math.floor(Math.random() * 1000000);
    const igID = Math.floor(Math.random() * 1000000);
    const end_time = props.dateTo || new Date(props.dateFrom.getTime() + Math.random() * 30 * 24 * 60 * 60 * 1000);


    const RandomAd = (provider?: Provider) => {

        if (provider === "instagram") return getInstagramAd(dayjs(props.dateFrom));

        const adId = Math.floor(Math.random() * 1000000);
        const adsetId = Math.floor(Math.random() * 1000000);
        const campaignId = Math.floor(Math.random() * 1000000);
        const end_time = new Date(props.dateFrom.getTime() + Math.random() * 30 * 24 * 60 * 60 * 1000);
        const daily_budget = 2000;
        const special_type = randomFromArray(SpecialType);
        const status = "active";
        
        return {
            id: adId,
            created_time: props.dateFrom,
            name: `Random Ad ${adId}`,
            status: status,
            effective_status: status,
            configured_status: status,
            special_type: special_type,
            adset: {
                id: adsetId,
                name: `Random Adset ${adsetId}`,
                //random end_time at most 30 days after created_at
                end_time: end_time,
                status: "active",
                daily_budget: daily_budget,
            },
            campaign: {
                id: campaignId,
                objective: "POST_ENGAGEMENT",
            },
            insights: {
                clicks: RandomReaction(),
                impressions: RandomReaction(),
                post_engagement: 1000,//RandomReaction(),
                reach: RandomReaction(),
                spend: Math.random() * 20,
                date_start: props.dateFrom,
                date_stop: end_time,
                actions: Math.floor(Math.random() * 200),
                action_type: randomFromArray(actions),
                cost_per_action_type: Math.random() * 10,
                conversions: GenerateConversions(),
            },
            ad_review_feedback: null,
            // ad_review_feedback: {
            //     "global": {
            //       "Obchodzenie systemów": randomFromArray(["A", "B"])
            //     },
            //     "placement_specific": {
            //       "facebook": {
            //         "Obchodzenie systemów": randomFromArray(["A", "B"])
            //       },
            //       "instagram": {
            //         "Obchodzenie systemów": randomFromArray(["A", "B"])
            //       }
            //     }
            //   },
           // error_reason: randomFromArray(randomErrors),
            source: randomFromArray(sources),
        }
    }


    const RandomAds = (provider?: Provider) => {
        const ads = [];
        const numberOfAds = Math.floor(Math.random() * (props.maxNumberOfAds || 4));
        for (let i = 0; i < numberOfAds; i++) {
            ads.push(RandomAd(provider));
        }
        return ads;
    }

    const picsumRandomId = Math.floor(Math.random() * 100);

    return {
        id: props.id,
        //type: "post",
        //type: "post_facebook",
        //type: "post_instagram",
        //type: randomFromArray(["post", "aoa"]),
        type: "reel",
        attributes: {
            //id: null,
            id: props.id,
            source: sources[Math.floor(Math.random() * sources.length)],
            image: `https://picsum.photos/id/${picsumRandomId}/200`,
            full_image: `https://picsum.photos/id/${picsumRandomId}/200`,
            //image: `https://picsum.photos/id/${props.id}/200`,
            //full_image: `https://picsum.photos/id/${props.id}/200`,
            message: `This is a random post with ID ${props.id}. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
            //post_type: "image",
            post_type: "video_reel",
            calculated_post_insights: {
                reactions: {
                    like: RandomReaction(),
                    love: RandomReaction(),
                    haha: RandomReaction(),
                    wow: RandomReaction(),
                    sad: RandomReaction(),
                    angry: RandomReaction(),
                    takeCare: RandomReaction(),
                },
                clicks: RandomReaction(),
                reach: RandomReaction(),
                paid_reach: RandomReaction(),
                interactions: RandomReaction(),
                organic_reach: RandomReaction(),
            },
            created_at: props.dateFrom,
            end_time: end_time,
            providers: {
                facebook: {
                    external_id: fbID,
                    status: randomFromArray(postStatuses),
                    //error: randomFromArray(randomErrors),
                   // error_reason: randomFromArray(randomErrors),
                    is_eligible_for_promotion: false,
                    is_published: true,
                    ads: RandomAds("facebook"),
                    insights: {
                        reactions: {
                            like: RandomReaction(),
                            love: RandomReaction(),
                            haha: RandomReaction(),
                            wow: RandomReaction(),
                            sad: RandomReaction(),
                            angry: RandomReaction(),
                            takeCare: RandomReaction(),
                        },
                        clicks: RandomReaction()*1000,
                        reach: RandomReaction()*1000,
                        actions: RandomReaction()*1000,
                        paid_reach: RandomReaction(),
                        interactions: RandomReaction()*1000,
                        organic_reach: RandomReaction()*1000,
                    },
                },
                instagram: {
                    external_id: igID,
                    status: "published",
                    ads: RandomAds("instagram"),
                    insights: {
                        engagement: Math.floor(Math.random() * 1000),
                        video_vides: Math.floor(Math.random() * 1000),
                        reach: Math.floor(Math.random() * 1000),
                        impressions: Math.floor(Math.random() * 1000),
                        likes: Math.floor(Math.random() * 1000),
                        comments: Math.floor(Math.random() * 1000),
                        total_interactions: Math.floor(Math.random() * 1000),
                        organic_reach: Math.floor(Math.random() * 1000),
                        saved: Math.floor(Math.random() * 1000),
                        shares: Math.floor(Math.random() * 1000),
                    }
                },
                google: {
                    external_id: gID,
                    status: randomFromArray(postStatuses),
                    error_reason: [],
                    is_eligible_for_promotion: false,
                    is_published: true,
                    ads: RandomAds(),
                    insights: {
                        reactions: {
                            like: RandomReaction(),
                            love: RandomReaction(),
                            haha: RandomReaction(),
                            wow: RandomReaction(),
                            sad: RandomReaction(),
                            angry: RandomReaction(),
                            takeCare: RandomReaction(),
                        },
                        clicks: RandomReaction(),
                        reach: RandomReaction(),
                        actions: RandomReaction(),
                        paid_reach: RandomReaction(),
                        interactions: RandomReaction(),
                        organic_reach: RandomReaction(),
                    },
                }
            }

        }

    }
}


export const GenerateRandomPosts = (count: number, params: FakePostParams) => {
    const posts = [];
    for (let i = 0; i < count; i++) {
        posts.push(GenerateFakePost({ ...params, id: i + "" }));
    }
    return posts.sort((a, b) => a.attributes.created_at.getTime() - b.attributes.created_at.getTime());
}

export const GeneratePostsFromDateSeed = (from: Date, to: Date, provider?: string) => {
   // console.log("Generating posts, number of dates in seed:", fakeDates.length, "from:", from, "to:", to);
    //filter fakeDates to be in range
    const datesAsObj = fakeDates.map((date: any) => ({ id: date.id, created_at: new Date(date.created_at) }));

    const datesInRange = datesAsObj.filter((date: any) => date.created_at.getTime() >= from.getTime() && date.created_at.getTime() <= to.getTime());
    const posts = datesInRange.map(({ created_at, id }) => GenerateFakePost({
        id: id + "",
        dateFrom: created_at,
        maxNumberOfAds: 4
    }));

    

    if (provider) {
        posts.forEach(post => {
            post.attributes.providers = {
                [provider]: post.attributes.providers[provider]
            }
        })
    }


    return posts.sort((a, b) => a.attributes.created_at.getTime() - b.attributes.created_at.getTime());

}