import { Alert } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { useState } from "react";

type PhoneErrorAlertProps = {
  phoneError: any,
  verifyError: any,
  handlePhone: () => void,
  handlePhoneFocus: () => void
  visibleAlert: boolean
  setVisibleAlert: (data: boolean) => void
}

const TryToExtractErrorMessage = (error: any) => {
  if (!error) return null;
  try {
    if (error.exception) return error.exception;
    if (error.errors) {
      if (Array.isArray(error.errors)) {
        return error.errors[0].message || error.errors[0].detail;
      }
      return error.errors.message || error.errors.detail;
    }
  } catch (e) {
    return "Unknown error";
  }
 
}

const PhoneErrorAlert = (props: PhoneErrorAlertProps) => {

  const phoneError = TryToExtractErrorMessage(props.phoneError);
  const verifyError = TryToExtractErrorMessage(props.verifyError);
  const errorMessages = phoneError || verifyError;

  if (!props.visibleAlert) return null;

  const handleCloseAlert = () =>{
    props.setVisibleAlert(false)
    props.handlePhoneFocus()
  }

  return (
    <Alert
      message={errorMessages}
      description={
        <>
          {props.verifyError ? (
            <>
              <span>
                <FormattedMessage
                  id="panel.containers.register.verificationCode.error.changeCode"
                  defaultMessage="Change code"
                />
              </span>
              {' '}
              <FormattedMessage
                id="panel.containers.register.verificationCode.error.or"
                defaultMessage=", or "
              />
            </>
          ) : props.phoneError?.errors && !props.verifyError ? (
            <>
              <span
                className="link"
                onClick={props.handlePhone}
              >
                <FormattedMessage
                  id="panel.containers.register.verificationCode.error.tryAgain"
                  defaultMessage="Try again"
                />
              </span>
              {' '}
              <FormattedMessage
                id="panel.containers.register.verificationCode.error.or"
                defaultMessage=", or "
              />
            </>
          ) : ('')}
          {' '}
          <span
            className="link"
            onClick={handleCloseAlert}
          >
            <FormattedMessage
              id="panel.containers.register.verificationCode.error.changeNumber"
              defaultMessage="Change number"
            />
          </span>
          .
        </>
      }
      type="error"
      showIcon
      style={{ marginBottom: "20px" }}
      closable
      onClose={() => props.setVisibleAlert}
    />
  );
};

export default PhoneErrorAlert;