import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { callAPIProps } from 'services/hooks/useAPI';
import _ from 'lodash';

export type GallryState = {
    at_work: imageObject[],
    inside: imageObject[],
    logo: imageObject[],
    outside: imageObject[],
    team: imageObject[],
    certificate: imageObject[]
};

type imageObject = {
    position: number
    created_at: string
    attributes: string | null
    updated_at: string
    id: number
    file: string
    extension: string
    group: keyof GallryState
    website_id: number
    media: Object[]
    thumbnail_url: string
    url: string
    website: { id: number }
}

const initialState: GallryState = {
    at_work: [],
    inside: [],
    logo: [],
    outside: [],
    team: [],
    certificate: []
}

export const gallerySlice = createSlice({
    name: "gallerySlice",
    initialState,
    reducers: {
        get: (state, action: PayloadAction<{ data: imageObject[] }>) => {
            state.at_work = [];
            state.inside = [];
            state.logo = [];
            state.outside = [];
            state.team = [];
            state.certificate = [];
            
            if (action.payload.data.length === 0) return;
            action.payload.data.forEach((image: imageObject) => {
                if (image.group in state) {
                    state[image.group].push(image?.media?.[0]);
                }
            });
        },
        add: (state, action: PayloadAction<{ data: imageObject }>) => {
            if (action.payload.data.group in state) {
                state[action.payload.data.group].push(action.payload.data);
            }
        },
        update: (state, action: PayloadAction<{ projectId: keyof GallryState, data: { websites_gallery_images: imageObject[] } }>) => {
            const group = action.payload.request.group;
            if (!group) return;
            state[group] = action.payload;
        },
    }
})

export const GetGalleryObject: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/website/gallery/images`, 2),
    method: "GET",
    successDispatch: gallerySlice.actions.get,
}

export const AddGalleryObject = (): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/website/gallery/images`, 3),
    method: "POST",
    successDispatch: gallerySlice.actions.add,
})

export const UpdateGalleryGroup = (group: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/website/gallery/${group}`, 3),
    method: "PATCH",
    successDispatch: gallerySlice.actions.update,
})

export const DeleteGalleryObject = (imageId: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/website/gallery/images/${imageId}`, 3),
    method: "DELETE",
    successDispatch: gallerySlice.actions.get,
})


export const GallerySelector = createSelector([
    (state: any) => state?.siteGenerator?.gallery,
],
    (gallery: GallryState) => {
        return gallery
    }
);

export default gallerySlice.reducer;