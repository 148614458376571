import { fromJS } from "immutable";
import { LOAD_USER_SUCCESS } from "modules/panel/actions/user/login";
import {
  USER_DATA_SET_SUCCESS,
  updateUserPhoneNumber,
} from "modules/panel/actions/user/user";
import { GET_HIDDEN_EMAIL_SUCCESS } from "modules/panel/actions/user/password";
import { LanguageHelper } from "providers/languages/helper";
import { setUserStatement } from "modules/panel/actions/user/user";
import {
  getTermsOfServiceStatement,
  getMarketingConsents,
} from "modules/panel/actions/statements";
import { mapArrayToObjectByProperty } from "services/store/middlewares/api/json-api-normalizer/normalizer";
import * as Sentry from "@sentry/browser";
import facebookAdAccounts from "src/icons/providers/FacebookAdAccount.svg"

let initialState = fromJS({
  email: null,
  first_name: null,
  last_name: null,
  phone: null,
  changePasswordHiddenEmail: null,
  language: LanguageHelper.getInitialLanguage(),
  statements: {},
  statementsContent: {},
  emails: {},
});

function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_SUCCESS: {

      //console.log("USERACTION", action)

      // let langFromApi = action.payload.user.language;
      // if (langFromApi !== LanguageLocalStorage.get())
      //   LanguageLocalStorage.set(langFromApi);

      const id = action?.payload?.user?.id || "UNKNOWN"

        Sentry.setUser({id: id, panelURL: `https://panel.fasttony.com/nova/resources/users/${id}`})

        const e = action?.payload?.user?.email_change_required ? "" : action?.payload?.user?.email

        if (window.FreshworksWidget){

          window.FreshworksWidget('prefill', 'ticketForm', {
            name: action?.payload?.user?.first_name + " " + action?.payload?.user?.last_name,
            email: e,
            id: id,
          })

          window.FreshworksWidget('prefill', 'ticketForm', {
            custom_fields: {
              cf_user_id: id,
              cf_panel_url: `${import.meta.env.VITE_PANEL_URL}/nova/resources/users/${id}`
            }
          })

          // window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_user_id', 'custom_fields.cf_panel_url'], {
          // });

        } else {
          console.warn("FreshworksWidget not available")
        }

      const user = prepareUserData(action.payload.user) 
        
      return fromJS({
        ...user,
        accessTokens: action.payload.access_tokens,
        statements: action.payload.statements,
        legalContents: action.payload.legal_contents,
        integrations: action.payload.integrations,
        emails: action.payload.emails,
        statementsContent: {},
        permissions: action.payload.permissions,
      });
    }

    case USER_DATA_SET_SUCCESS:
      if(action.payload.user) return state.merge(action.payload.user);
      if(action.payload.data) return state.merge(action.payload.data);

    case updateUserPhoneNumber.type.success:
      return state
        .set("phone", action.payload.phone)
        .set("phone_verified_at", action.payload.phone_verified_at);

    case "USER_UPDATE_PHONE":
      return state
        .set("phone", action.payload.phone)
        .set("phone_verified_at", action.payload.phone_verified_at || null);

    case "USER_RESET_PHONE":
      return state
        .set("phone", null)
        .set("phone_verified_at", null);
    
    case GET_HIDDEN_EMAIL_SUCCESS:
      return state.set("changePasswordHiddenEmail", action.payload.success);

    case setUserStatement.type.success:
      return state.setIn(
        ["statements", action.payload.code],
        fromJS(action.payload)
      );

    case getTermsOfServiceStatement.type.success:
      return state.setIn(
        ["statementsContent", "termsOfService"],
        action.payload.content
      );

    case getMarketingConsents.type.success:
      return state.setIn(
        ["statementsContent", "marketingConsents"],
        action.payload.content
      );

    case "SUBMIT_STATEMENT_SUCCESS":
        return state.setIn(["statements", action.payload.code], fromJS(action.payload))

    case "GET_USER_EMAILS_SUCCESS": 
    return state.setIn(["emails"],fromJS(mapArrayToObjectByProperty(action.payload)));

    case "PATCH_USER_CUSTOM_EMAIL_SUCCESS":
      return state.setIn(["emails",String(action.payload.id)],fromJS(action.payload));

    case "POST_USER_CUSTOM_EMAIL_SUCCESS":
      return state.setIn(["emails",String(action.payload.id)],fromJS(action.payload));

    case "DELETE_USER_CUSTOM_EMAIL_SUCCESS":
      return state.removeIn(["emails",String(action.additional.id)])

    default:
      return state;
  }
}

const prepareUserData = (user) => {
  let newUserData;

  if (localStorage.getItem("anonymizeData") === "true") {
    newUserData = setAnonymizeUserData(user);
  }

  if (newUserData !== undefined) {
    return { ...user, ...newUserData };
  }
  return user;
};

const setAnonymizeUserData = (user) => {
  if (user) {
    user.first_name = "Jan";
    user.last_name = "Kowalski";
    user.email = "johnDoe@example.com"
    user.image = facebookAdAccounts
  }
  return user;
};


export default userReducer;
