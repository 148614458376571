import { useEffect, useState, useMemo } from "react";
import InfinityList from "../../tools/infinityList/infinityList";
import InfinityListQuery from "../../tools/infinityList/infinityListQuery";
import { PostListHeaders } from "tools/infinityList/infinityTemplates";
import Post from "models/post";
import FormattedMessage from "components/common/FormattedMessage";
import { postsSlice, GetPosts, PostsSelector, SortPostsByDate, filteredPostsCountSelector } from "modules/posts/state/postsSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import PostContextProvider from "modules/posts/postContext";
import { useDispatch } from "react-redux";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import useAPIWithIncrementsNoPages from "services/hooks/useAPIWithIncrementsNoPages";
import { HorizontalCenter } from "components/common/Center";
import "themes/base/components/infinity-list.less";
import { Spin } from "antd";
import FeatureErrorBoundry from "providers/routing/FeatureErrorBoundry";
import InfinityListAdvancedSettings from "tools/infinityList/infinityListAdvancedSettings";
import { PostListAdvancedSettings } from "./PostConfig";
import _ from "lodash";
import dayjs from "dayjs";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { InfinityListResetFilters } from "tools/infinityList/infinityListResetFilters";
import Toolbar from "components/toolbar/Toolbar";
import InfinityListAdvancedSettingsCheckboxs from "tools/infinityList/infinityListAdvancedSettingsCheckboxs";


export type PostListProps = {
    provider?: string;
}

const postTypesByGroup: Record<string, string[]> = {
    facebook: ["fbpost", "fbad", "aoa", "leadad", "fbreel"],
    google: ["gpost", "gad"],
    instagram: ["igpost", "igad", "igstory", "aoa", "leadad"],
    bot: ["bot"],
}

const firstRequestOnly = ["igstory"];

const CombineGroups = (groups: string[], firstRequest?: boolean) => {
    const combinedTypes = groups.map(g => postTypesByGroup[g]).flat();
    let withoutDuplicates = combinedTypes.filter((item, index) => combinedTypes.indexOf(item) === index);

    if (!firstRequest) {
        withoutDuplicates = withoutDuplicates.filter(t => !firstRequestOnly.includes(t));
    }

    const combined: { [key: string]: string } = {};
    withoutDuplicates.forEach((t, i) => combined[`included[${i}]`] = t);

    return combined;
}


export default function InfinityPostList(props: PostListProps) {


    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);
    const [query, setQuery] = useState<any>();
    const posts = useSelectorWithParams([PostsSelector]);
    const filteredPostsCount = useSelectorWithParams(filteredPostsCountSelector)
    const dispatch = useDispatch();
    const bp = useSimpleBreakpoint();

    const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
        callAPI: GetPosts,
        query: query
    })

    const provider = (() => {

        if (!permissions) return "all";
        const platforms = [];
        if (permissions.includes("use postlist facebook")) platforms.push("facebook");
        if (permissions.includes("use postlist google")) platforms.push("google");
        if (permissions.includes("use postlist instagram")) platforms.push("instagram");

        if (platforms.length === 1) return platforms[0];

        return "all";
    })()

    useEffect(() => {
        reloadList();
    }, [query, projectId]);

    const reloadList = () => {
        dispatch(postsSlice.actions.clearPosts({ projectId }))
        if (!query) return;
        startWithNewQuery({ ...query });
    }

    const getCombinedGroups = (firstRequest?: boolean) => {
        const groups: string[] = [];
        if (!permissions) return {};
        if (permissions.includes("use postlist facebook")) groups.push("facebook");
        if (permissions.includes("use postlist google")) groups.push("google");
        if (permissions.includes("use postlist instagram")) groups.push("instagram");
        const combined = CombineGroups(groups, firstRequest);
        return combined;
    }

    const onQueryChange = (query: any) => {
        const normalizedQuery: any = {
            "date[from]": query.start.format("YYYY-MM-DD"),
            "date[to]": query.end.format("YYYY-MM-DD"),
            "sort": query.sort || "created_at",
            ...getCombinedGroups(true),
            "withBot": "true"
        }
        if (props.provider) {
            normalizedQuery["provider"] = props.provider;
        }

        if (query.search) {
            normalizedQuery["search"] = query.search;
        }
        reloadList();
        setQuery(normalizedQuery);
    }

    const GetMore = () => {
        getNextIncrement((q) => {
            if (!q) {
                return;
            }

            const oldQ = _.cloneDeep(q);

            const previousFrom = dayjs(oldQ["date[from]"]);
            const previousTo = dayjs(oldQ["date[to]"]);

            let newFrom: dayjs.Dayjs;
            let newTo: dayjs.Dayjs;

            if (query?.sort === "created_at") {
                newFrom = previousTo;
                newTo = newFrom.add(7, 'day');
            } else {
                newFrom = previousFrom.subtract(7, 'day');
                newTo = previousFrom;
            }

            //remove all includes from old query
            Object.keys(oldQ).forEach(k => {
                if (k.startsWith("included")) {
                    delete oldQ[k];
                }
            })

            return {
                ...oldQ,
                ...getCombinedGroups(false),
                "date[from]": newFrom.toISOString().split("T")[0],
                "date[to]": newTo.toISOString().split("T")[0]
            };
        })
    }

    const handleLoadNextPage = () => {
        if (emptyResponses < 3) GetMore();
    }

    const sortedPosts = useMemo(() => {

        if (!posts) return [];

        //const filteredPosts = FilterPosts(posts, interfaceSettings?.postList)
        const filteredPosts = posts;

        if (!query || !query.sort) return posts;
        if (query.sort === "created_at") {
            return SortPostsByDate(filteredPosts, false);
        }
        if (query.sort === "-created_at") {
            return SortPostsByDate(filteredPosts, true);
        }
        return filteredPosts;

    }, [posts])

    const renderPost = (post: Post) => {
        return (
            <PostContextProvider key={post.local_id} postId={post.local_id} options={{ provider: provider }} reloadList={reloadList} />
        )
    }

    const extra = useMemo(() => {

        if (posts && posts.length > 0 && loading) return (
            <HorizontalCenter style={{ padding: "30px" }} key="loading">
                <Spin />
            </HorizontalCenter>
        )
        if (emptyResponses >= 3) return (
            <HorizontalCenter style={{ padding: "30px" }} key="nothing">
                <h3>
                    <FormattedMessage
                        id="posts.list.error.nothingMore"
                        defaultMessage="Nothing more to show. Please change the date range."
                    />
                </h3>
            </HorizontalCenter>
        )
        return null
    }, [emptyResponses, loading])

    const queryInput = useMemo(() => {
        return (
            <InfinityListQuery
                onChange={onQueryChange}
                onReload={reloadList}
                sort={true}
                date={true}
            >
                <InfinityListResetFilters filteredItems={filteredPostsCount} resetSettings={'postListv2'}/>
                <InfinityListAdvancedSettings settings={PostListAdvancedSettings}/>
            </InfinityListQuery>
        )
    }, [query, filteredPostsCount])

    return (

        <div className="infinity-wrapper">
            <Toolbar
                width="280px"
                title={(
                    <FormattedMessage
                        id="panel.components.post.list.posts"
                        defaultMessage="Posts"
                    />
                )}
                smallToolbar={(
                    <>
                        <Toolbar.Spacer />
                        <InfinityListQuery
                            onChange={() => null}
                            onReload={reloadList}
                            sort={false}
                            date={true}
                            refresh={false}
                        />
                    </>
                )}
                largeToolbar={(<>
                    <Toolbar.Spacer />
                    {queryInput}
                </>
                )}
                drawerToolbar={(<>
                    <InfinityListQuery
                        onChange={onQueryChange}
                        onReload={reloadList}
                        sort={true}
                        date={true}
                        refresh={false}
                    >
                        <InfinityListAdvancedSettingsCheckboxs settings={PostListAdvancedSettings} />
                    </InfinityListQuery>
                </>
                )}
            />

            <InfinityList
                headers={PostListHeaders}
                items={sortedPosts}
                loading={loading}
                loadMoreData={handleLoadNextPage}
                itemSkeleton={() => "skeleton"}
                renderItem={renderPost}
                additionalItems={[
                    extra
                ]}
            />
        </div >
    )

}


export const InfinitePostListWithErrorBoundry = () => {
    return (
        <FeatureErrorBoundry feature="InfinitePostList">
            {/* <CrashButton /> */}
            <InfinityPostList />
        </FeatureErrorBoundry>
    )
}