import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIFunction, callAPIProps } from "services/hooks/useAPI";
import { currentProjectIdSelector } from "services/store/scopeSlice";

export type CallAppState = { [id: number]: CallAppProps };

export type CallAppReviewLink = {
  id: string,
  name: string,
  call_count: number,
  review_url: string,
  review: any[],
}

export type CallAppProps = {
  configurations: {};
  reviewLinks: Record<string, CallAppReviewLink>;
  customization: any,
  keywords: any,
  created_at: string;
  id: number;
  reviews: Record<string, CallAppReview>;
  project_id: number;
  updated_at: string;
};

export type CallAppReview = {
  button_review_url: string;
  created_at: string;
  device_id: string;
  email: string;
  id: string;
  int_stars: number;
  name: string;
  new_review_url: string;
  phone: string;
  review: string | null;
  review_url: string;
  stars: string;
  valid_until: string;
}

const initialForProject = {
  configurations: {},
  reviewLinks: {},
  customization: {},
  keywords: {},
  reviews: [],
  created_at: "",
}

const initialState: CallAppState = {
};

export const callAppSlice = createSlice({
  name: "callApp",
  initialState,
  reducers: {
    get: (state, action) => {
      state[action.payload.projectId] = action.payload.data;
    },
    update: (state, action) => {
      state[action.payload.projectId] = action.payload.data;
    },
    getReviewLinks: (state, action) => {
        if (!state[action.payload.projectId]) state[action.payload.projectId] = initialForProject;
        for (const revLink of action.payload.data) {
          state[action.payload.projectId].reviewLinks[revLink.id] = revLink;
        }
    },
    getReviewLink: (state, action) => {
      if (!state[action.payload.projectId]) state[action.payload.projectId] = initialForProject;
      state[action.payload.projectId].reviewLinks[action.payload.data.id] = action.payload.data;
    },
    getCustomization: (state, action) => {
      if (!state[action.payload.projectId]) state[action.payload.projectId] = initialForProject;
      state[action.payload.projectId].customization = action.payload.data;
    },
    getKeywords: (state, action) => {
      if (!state[action.payload.projectId]) state[action.payload.projectId] = initialForProject;
      state[action.payload.projectId].keywords = action.payload.data;
    },
    getReviews: (state, action) => {
      if (!state[action.payload.projectId]) state[action.payload.projectId] = initialForProject;
      state[action.payload.projectId].reviews = action.payload.data;
    }
  },
});

export const GetCallApp: callAPIProps = {
  url: ({ getApiUrl, projectId }: any) =>
    getApiUrl(`projects/${projectId}/call-app/configuration`, 1),
  method: "GET",
  successDispatch: callAppSlice.actions.get,
};

export const UpdateCallApp = (callAppId: string): callAPIProps => ({
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/call-me/${callAppId}`, 1),
  method: "PATCH",
  successDispatch: callAppSlice.actions.update,
});

export const GetReviewLinks: callAPIProps = {
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/project-review-links`, 1),
  method: "GET",
  successDispatch: callAppSlice.actions.getReviewLinks,
}

export const NewReviewLink = (name: string): callAPIProps => ({
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/project-review-links`, 1),
  method: "POST",
  successDispatch: callAppSlice.actions.getReviewLink,
  body: { name },
})

export const UpdateReviewLink = (data: any): callAPIProps => ({
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/project-review-links`, 1),
  method: "PATCH",
  successDispatch: callAppSlice.actions.getReviewLinks,
})

export const GetReviews: callAPIProps = {
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/reviews`, 1),
  method: "GET",
  successDispatch: callAppSlice.actions.getReviews,
}


export const GetCustomization: callAPIProps = {
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/review/customization`, 1),
  method: "GET",
  successDispatch: callAppSlice.actions.getCustomization,
}

export const UpdateCustomization: callAPIProps = {
  url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/review/customization`, 1),
  method: "PATCH",
  successDispatch: callAppSlice.actions.getCustomization,
}

export const ProjectReviewLinksSelector = createSelector(
  [(state: any) => state?.callApp, currentProjectIdSelector],
  (state: any, projectId: any) => state && state[projectId]?.reviewLinks
);


export const ProjectCallAppSelector = createSelector(
  [(state: any) => state?.callApp, currentProjectIdSelector],
  (state: any, projectId: any) => state && state[projectId]
);


export const ProjectReviewsCustomizationSelector = createSelector(
  [(state: any) => state?.callApp, currentProjectIdSelector],
  (state: any, projectId: any) => state && state[projectId]?.customization
);

export const GetProjectKeywords:callAPIProps = {
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/keywords`),
  method: "GET",
  successDispatch: callAppSlice.actions.getKeywords,
}

export const PostProjectKeywords:callAPIProps = {
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/keywords`),
  method: "POST",
  successDispatch: callAppSlice.actions.getKeywords,
}

export const GenerateProjectKeywords = (type: string):callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/keywords/generate`),
  method: "POST",
  body: {
      keyword_type: type
  },
  successDispatch: callAppSlice.actions.getKeywords,
}
)

export const ProjectKeywordsSelector = createSelector(
  [(state: any) => state?.callApp, currentProjectIdSelector],
  (state: any, projectId: any) => state && state[projectId]?.keywords
);

export const ProjectKeywordsSelectorByType = (type: "seo" | "opinion") => createSelector(
  [ProjectKeywordsSelector],
  (keywords: any) => {
    if (!keywords) return {};
    if (type === "seo") return {
      all: keywords.all_seo_keywords,
      selected: keywords.selected_seo_keywords,
    }
    if (type === "opinion") return {
      all: keywords.all_opinion_keywords,
      selected: keywords.selected_opinion_keywords,
    }
    return {};
  }
);

export const UpdateProjectKeywords:callAPIProps = {
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/keywords`),
  method: "PATCH",
  successDispatch: callAppSlice.actions.getKeywords,
}

export const ProjectReviewsSelector = createSelector(
  [(state: any) => state?.callApp, currentProjectIdSelector],
  (state: any, projectId: any) => state && state[projectId]?.reviews
);

