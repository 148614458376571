import { Tooltip } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { forwardRef, useState } from "react";
import useAPI from "services/hooks/useAPI";
import {
  StandardModal,
  useStandardModal,
} from "services/hooks/useModal";
import { SubscriptionAttachedManager } from "./SubscriptionAttachedManager";
import StandardAvatar from "components/common/Avatar/StandardAvatar";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectWithSubscriptionsSelector } from "state/projects/projectsSelectors";
import { attachSubscriptionToProject } from "state/subscriptions/subscriptionsEndpoints";

type AttachSubscriptionModalProps = {
  product?: string;
  projectId?: string;
  onClose:() => void
};

type AttachSubscriptionItemProps = {
  projectId: string;
  product: string;
};

export const AttachSubscriptionModal = forwardRef((props:AttachSubscriptionModalProps, ref:any) => {
  const [projectId, setProjectId] = useState(
    props.projectId !== undefined ? props.projectId : undefined
  );
  const [subscriptionId,setSubscriptionId] = useState<string>()

  const { call } = useAPI({});

  return (
    <>
      <StandardModal
        ref={ref}
        key={`${projectId}-${props.product}`}
        title={
          <FormattedMessage
            id="panel.containers.companySettings.attachSubscriptionModal.title"
            defaultMessage="Attach Subscription"
          />
        }
        onOk={() => {
          call(attachSubscriptionToProject(subscriptionId, projectId))
          setSubscriptionId(undefined)
          props.onClose();
        }}
      >
        <SubscriptionAttachedManager
          product={props.product}
          projectId={projectId}
          setProjectId={setProjectId}
          setSubscriptionId={setSubscriptionId}
        />
      </StandardModal>
    </>
  );
})

export const AttachSubscriptionItem = (props: AttachSubscriptionItemProps) => {
  
  const [ref, open, close] = useStandardModal();
  const project = useSelectorWithParams(projectWithSubscriptionsSelector(props.projectId))

  const product = props.product;

  if (!project) return null;

  return (
    <>
      <Tooltip
        key={props.product}
        placement="left"
        title={
          <FormattedMessage
            id="panel.containers.companySettings.attachSubscriptionItemTooltip"
            defaultMessage="Click to attach {product} to {project} project"
            values={{
              product: product,
              project: project.name,
            }}
          />
        }
      >
        <div>
        <StandardAvatar 
            size="large"
            image={getProductLogo(product)} 
            overlay={<AwesomeIcon icon={faPlusCircle} size="2xl"/>}
            onClick={() => {
              open();
            }}
          />
        </div>
      </Tooltip>
      <AttachSubscriptionModal
        product={product}
        projectId={project.id}
        ref={ref}
        onClose={close}
      />
      
    </>
  );
};
