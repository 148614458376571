import { createSelector } from "@reduxjs/toolkit";
import { TransactionProps } from "./sellerTypes";
import { userSettingsInterfaceCalculationListSelector } from "state/user/userSettingsSlice";

export const SellerSelector = (state: any) => state.seller

export const CouponsSelector = createSelector(
    SellerSelector,
    (seller) => seller.coupons
);

export const SellerSubscriptionsSelector = createSelector(
    SellerSelector,
    (seller) => seller.subscriptions
);

export const CalculationsSelector = createSelector(
    SellerSelector,
    (seller) => seller.calculations
);


export const TransactionsSelector = createSelector(
    CalculationsSelector,
    (calculations): TransactionProps[] => {
        if (!calculations || !Array.isArray(calculations)) return [];

        return calculations.flatMap((calculation) => {
            const type = calculation.type as "calculations" | "team-calculations";

            const paidTransactions = calculation.attributes.paid.data.map((transaction: TransactionProps) => ({
                ...transaction,
                status: "paid" as const,
                type,
            }));

            const unpaidTransactions = calculation.attributes.unpaid.data.map((transaction: TransactionProps) => ({
                ...transaction,
                status: "unpaid" as const,
                type,
            }));

            return [...paidTransactions, ...unpaidTransactions];
        });
    }
);
export const filteredTransactionsCountSelector = createSelector([
    TransactionsSelector,
    userSettingsInterfaceCalculationListSelector,
],
    (transactions, filters) => {
        const filteredTransactions = transactions.filter((transaction: TransactionProps) => {
            return FilterTransactions(transaction, filters)
        });
        return transactions.length - filteredTransactions.length;
    })

export const FilterTransactions = (transaction: TransactionProps, filter: any) => {
    if (!filter) return true;

    if (transaction) {

        if (filter?.type.calculations === false && transaction?.type === "calculations") return false;
        if (filter?.type.teamCalculations === false && transaction?.type === "team-calculations") return false;

        if (filter?.status.paid === false && transaction?.status === "paid") return false;
        if (filter?.status.unpaid === false && transaction?.status === "unpaid") return false;

    }
    return true;
}