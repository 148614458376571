export function roundToDecimalPlaces(num:number, decimalPlaces:number) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
}

export function delay(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function trimString(str: string, min: number, max: number) {

    if (!str) return null;
    if (!min || !max) return str;

    if (str.length <= max) {
        return str;
    }

    let trimmed = str.slice(0, max);
    const lastSpaceIndex = trimmed.lastIndexOf(" ");

    if (lastSpaceIndex !== -1 && lastSpaceIndex >= min) {
        trimmed = trimmed.slice(0, lastSpaceIndex);
    } else if (lastSpaceIndex < min) {
        trimmed = str.slice(0, min);
    }

    return trimmed;
}

interface SortableAsset {
    [key: string]: Date | string | number;
}

export const sortAssetsByDate = <T extends SortableAsset>(
    assets: T[],
    newestFirst: boolean,
    field: string = "created_at"
): T[] => {
    return assets.sort((a, b) => {
        const aDate = new Date(a[field]);
        const bDate = new Date(b[field]);
        return newestFirst ? bDate.getTime() - aDate.getTime() : aDate.getTime() - bDate.getTime();
    });
};

export const getValueFromPossibleFunction = (value: (...args: any[]) => boolean | boolean, ...args: any[]) => {
    if (!value) return false;
    return typeof value === "function" ? value(...args) : value;
};

export const safeIntReadFromLocalStorage = (key: string): number | null => {

    if (!key) return null;
    const storedValue = localStorage.getItem(key);
    
    if (storedValue !== null) {
      const parsedValue = parseInt(storedValue, 10);
      if (!isNaN(parsedValue)) {
        return parsedValue;
      }
    }
  
    return null;
  }

export const safeToInt = (value: any): number | null => {
    if (value === null || value === undefined) return null;
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? null : parsed;
}

export const extractIds = (arr: (string | number | {id: string | number})[]): string[] => {
    return arr.map((item) => {
        if (typeof item === "object") return String(item.id);
        return String(item);
    });
}

export const debugElementProps = (o:any):React.HTMLAttributes<HTMLDivElement> => {
    
    return {
        onContextMenu: (e: React.MouseEvent) => {
            console.log(o);
        }
    }

}