import Calendar from "tools/calendar/Calendar";
import useCalendarPromoEntries from "tools/calendar/useCalendarPromoEntries";

function PromoCalendar() {
    return (
        <Calendar 
            entriesManager={useCalendarPromoEntries}
            fitOnScreen={false}
        />
    );
}

export default PromoCalendar;