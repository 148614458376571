import { faCircleCheck, faPencil } from "@fortawesome/pro-light-svg-icons";
import Asset from "./asset";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";

export type CreationType = {

}

export default class Creation extends Asset implements CreationType {

    constructor(data: any) {

        super(data);

        this.rawData = data;

        for (const [key, value] of Object.entries(data)) {
            (this as any)[key] = _.cloneDeep(value);
        }
    }

    getCalendarInfo() {

        const s = this.status || "draft"

        return {
            typeIcon: faPencil,
            status: (this as any).status,
            type: "Creation",
            date: dayjs.utc((this as any).content?.publication_date),
            color: "white",
            description: (this as any).content?.message || (this as any).content?.title,
            image: (this as any).content?.media?.[0]?.thumbnails?.[500] || (this as any).content?.media?.[0]?.url || "",
            canBePickedUp: s.toLowerCase() !== "published",
            moveInFutureOnly: (s.toLowerCase() === "planned" || s.toLowerCase() === "scheduled") ? true : false,
        }
    }


}