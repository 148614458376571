/// <reference types="vite-plugin-svgr/client" />

import { Collapse, Input, Row, Select, Spin } from "antd";
import CardSelect from "components/inputs/CardSelect";
import React, { useRef, useState } from "react";
import { Suspense, useEffect } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import ImgFormatCarousel from "src/icons/editor/media/formatCarousel.svg?react";
import ImgFormatStandard from "src/icons/editor/media/formatStandard.svg?react";
import ImgMediaVideo from "src/icons/editor/media/typeVideo.svg?react";
import ImgMediaImage from "src/icons/editor/media/typePhoto.svg?react";
import useEffectNotFirst from "services/hooks/useEffectNotFirst";
import CreationBudgetInput from "../inputs/CreationBudgetInput";
import useDataPrefill from "services/hooks/useDataPrefill";
import StandardButton from "components/common/StandardButton";
import { creationContentSelector, patchCreation, patchCreationWithSmartAction } from "../creationsSlice";
import FieldAOAMediaWithTypeAndFormat from "../inputs/FieldAOAMediaWithTypeAndFormat";
import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import useAPI from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { EditorFacebookCampaign, EditorSectionBudget, EditorSectionCTAOnly, EditorSectionCTAWithMultipleLinks, EditorSectionEvents, EditorSectionLeadForm, EditorSectionListOfBodies, EditorSectionListOfDescriptions, EditorSectionListOfMedia, EditorSectionListOfTitles, EditorSectionName, EditorSectionPublication } from "../editorSections/EditorSections";
import EditorSectionsCollapse from "../editorSections/EditorSectionsCollapse";


export const AOAMainTypeSelect = (props: any) => {

    return (
        <RelaxSingleField
            name={"type"}
            required
        >
            <CardSelect
                cards={[
                    {
                        value: "aoa",
                        title:
                            <FormattedMessage
                                id="tools.creationEditor.editors.title.aoa"
                                defaultMessage="AOA" />,
                        description:
                            <FormattedMessage
                                id="tools.creationEditor.editors.description.aoa"
                                defaultMessage="Standard optimization." />,
                    },
                    {
                        value: "event_aoa",
                        title: <FormattedMessage
                            id="tools.creationEditor.editors.title.aoaEvent"
                            defaultMessage="Event AOA" />,
                        description:
                            <FormattedMessage
                                id="tools.creationEditor.editors.description.aoaEvent"
                                defaultMessage="Optimized for events." />,
                    }
                ]}
            />
        </RelaxSingleField>
    )
}



export const AOAMediaTypeSelect = (props: any) => {


    const { creation, creationId } = useCreationEditor();
    const updateCreation = useAPI(patchCreationWithSmartAction(creationId, "ce_media_type"));
    const [ref, open, close] = useStandardModal();
    const selectedValue = useRef();

    const mediaType = creation?.content?.["media-type"];
    const format = creation?.content?.format;

    const handleChange = (v: any) => {
        if (!v) return;

        if (!mediaType || !creation.content.media || creation.content.media.length === 0) {
            updateCreation.call({
                body: {
                    content: {
                        ["media-type"]: v
                    }
                }
            })
            return;
        }

        if (v === mediaType) return;
        selectedValue.current = v;
        open();
    }

    const handleConfirmReset = () => {
        updateCreation.call({
            body: {
                content: {
                    media: null,
                    "media-type": selectedValue.current,
                    format: creation?.content?.["format"],
                }
            }
        }).then((res:any) => {
            if (res?.status === 200) {
                props.onMediaReset && props.onMediaReset();
            }
        });
        close();
    }


    return (
        <>
            <CardSelect
                onChange={handleChange}
                value={mediaType}
                cards={[
                    {
                        value: "image",
                        title:
                            <FormattedMessage
                                id="tools.creationEditor.editors.title.image"
                                defaultMessage="Image" />,
                        description: "",

                        image: ImgMediaImage
                    },
                    {
                        value: "video",
                        title:
                            <FormattedMessage
                                id="tools.creationEditor.editors.title.video"
                                defaultMessage="Video" />,
                        description: "",
                        image: ImgMediaVideo
                    }
                ]}
            />
            <StandardModal
                ref={ref}
                title={
                    <FormattedMessage
                        id="tools.creationEditor.editors.mediaType.actions.change"
                        defaultMessage="Change Media Type" />
                }
                onOk={handleConfirmReset}
            >
                <div>
                    <FormattedMessage
                        id="tools.creationEditor.editors.media.changeConfirm"
                        defaultMessage="Changing media type and format will clear currently selected media. Are you sure you want to continue?" />
                </div>
            </StandardModal>
        </>

    )
}

export const AOAFormatSelect = (props: any) => {

    const { creation, creationId } = useCreationEditor();
    const updateCreation = useAPI(patchCreationWithSmartAction(creationId, "ce_media_format"));
    const [ref, open, close] = useStandardModal();
    const selectedValue = useRef();

    const mediaType = creation?.content?.["media-type"];
    const format = creation?.content?.format;

    const handleChange = (v: any) => {
        if (!v) return;

        if (!format || !creation.content.media || creation.content.media.length === 0) {
            updateCreation.call({
                body: {
                    content: {
                        format: v
                    }
                }
            })
            return;
        }

        if (v === format) return;

        selectedValue.current = v;
        open();
    }

    const handleConfirmReset = () => {
        updateCreation.call({
            body: {
                content: {
                    media: null,
                    "media-type": creation?.content?.["media-type"],
                    format: selectedValue.current
                }
            }
        }).then((res:any) => {
            if (res?.status === 200) {
                props.onMediaReset && props.onMediaReset();
            }
        });
        close();
    }


    return (
        <>
            <CardSelect
                onChange={handleChange}
                value={format}
                cards={[
                    {
                        value: "standard",
                        title:
                            <FormattedMessage
                                id="tools.creationEditor.editors.title.standard"
                                defaultMessage="Standard" />,
                        tooltip:
                            <FormattedMessage
                                id="tools.creationEditor.editors.toolTip.standard"
                                defaultMessage="Images or videos in 1.91:1 or 1:1 aspect ratio." />,
                        image: ImgFormatStandard
                    },
                    {
                        value: "carousel",
                        title:
                            <FormattedMessage
                                id="tools.creationEditor.editors.title.carousel"
                                defaultMessage="Carousel" />,
                        tooltip:
                            <FormattedMessage
                                id="tools.creationEditor.editors.toolTip.carousel"
                                defaultMessage="Images or videos in 1:1 aspect ratio." />,
                        image: ImgFormatCarousel
                    }
                ]}
            />
            <StandardModal
                ref={ref}
                title={
                    <FormattedMessage
                        id="tools.creationEditor.editors.mediaType.actions.change"
                        defaultMessage="Change Media Type" />
                }
                onOk={handleConfirmReset}
            >
                <div>
                    <FormattedMessage
                        id="tools.creationEditor.editors.media.changeConfirm"
                        defaultMessage="Changing media type and format will clear currently selected media. Are you sure you want to continue?" />
                </div>
            </StandardModal>
        </>
    )
}

export const AOAMediaAndFormatSelect = (props: any) => {

    const { creation, creationId } = useCreationEditor();

    const mediaType = creation?.content?.["media-type"];
    const hasMediaType = mediaType && mediaType !== "none";
    const format = creation?.content?.format;
    const hasFormat = format && format !== "none";

    const [panel, setPanel] = useState<"media-format" | "media-type" | "media" | undefined>();

    useEffect(() => {

        if (!hasFormat) setPanel("media-format");
        else if (!hasMediaType) setPanel("media-type");
        else setPanel("media");

    }, [mediaType, format]);

    const handleMediaReset = () => {
        props.onMediaReset && props.onMediaReset();
    }


    const mediaTypeCollapsible = hasFormat ? {} : {
        collapsible: "disabled"
    }

    const mediaCollapsible = (hasFormat && hasMediaType) ? {} : {
        collapsible: "disabled"
    }

    return (
        <>
            <Collapse
                onChange={(k) => setPanel(k as any)}
                activeKey={panel}
                accordion={true}
            >
                <Collapse.Panel
                    header={
                        <div style={{width: "100%"}}>
                        <FormattedMessage
                            id="tools.creationEditor.editors.mediaFormat.title"
                            defaultMessage="Media Format" />
                        </div>
                    }
                    key="media-format">
                    <AOAFormatSelect 
                        onMediaReset={handleMediaReset}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    header={
                        <div style={{width: "100%"}}>
                        <FormattedMessage
                            id="tools.creationEditor.editors.mediaType.title"
                            defaultMessage="Media Type" />
                        </div>
                    }
                    key="media-type"
                    {...mediaTypeCollapsible}
                >
                    <AOAMediaTypeSelect 
                        onMediaReset={handleMediaReset}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    forceRender={true}
                    header={
                        <div style={{width: "100%"}}>
                        <FormattedMessage
                            id="tools.creationEditor.editors.media.title"
                            defaultMessage="Media" />
                        </div>
                    }
                    key="media"
                    {...mediaCollapsible}
                >
                    {props.children}
                </Collapse.Panel>
            </Collapse>
        </>
    )

}

export const EditorAOA = (props: any) => {

    const { creation, type, subtype, creationId } = useCreationEditor();
    const { call } = useDataPrefill({
        dataSelector: (state: any) => creationContentSelector(state, creationId),
        callAPI: patchCreation(creationId),
        checkFunction: (data: any) => {
            if (!data) return false;
            const md: any = {};
            if (!data.provider) md.provider = ["facebook"];

            if (Object.getOwnPropertyNames(md).length > 0) return { content: md };
            return false;
        }
    })

    return (
        <div>
            <EditorSectionsCollapse>
                <EditorSectionName />
                <EditorSectionListOfMedia />
                <EditorSectionListOfBodies />
                <EditorSectionListOfTitles />
                <EditorSectionListOfDescriptions />
                <EditorSectionCTAWithMultipleLinks actions={['SUBSCRIBE', 'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'SHOP_NOW', 'BOOK_TRAVEL']} />
                <EditorSectionEvents />
                <EditorFacebookCampaign />
                <EditorSectionBudget />
                <EditorSectionPublication />
            </EditorSectionsCollapse>
        </div>
    )

}

export const EditorLeadAd = (props: any) => {

    const { creation, type, subtype, creationId } = useCreationEditor();
    const { call } = useDataPrefill({
        dataSelector: (state: any) => creationContentSelector(state, creationId),
        callAPI: patchCreation(creationId),
        checkFunction: (data: any) => {
            if (!data) return false;
            const md: any = {};
            if (!data.provider) md.provider = ["facebook"];

            if (Object.getOwnPropertyNames(md).length > 0) return { content: md };
            return false;
        }
    })

    return (
        <div>
            <EditorSectionsCollapse>
                <EditorSectionName />
                <EditorSectionListOfMedia />
                <EditorSectionListOfBodies />
                <EditorSectionListOfTitles />
                <EditorSectionListOfDescriptions />
                <EditorSectionCTAOnly />
                {/* <EditorSectionCTAWithMultipleLinks actions={['SUBSCRIBE', 'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'SHOP_NOW', 'BOOK_TRAVEL']}/> */}
                <EditorSectionLeadForm />
                <EditorSectionBudget />
                <EditorSectionPublication />
            </EditorSectionsCollapse>
        </div>
    )
}

// export const EditorAOAWithStages = (props: any) => {

//     return (
//         <EditorWithStages
//             stages={[
//                 {
//                     name: "type",
//                     content: <AOAMainTypeSelect />,
//                     fulfilled: (creation: any) => {
//                         if (!creation) return false;
//                         if (!creation.content) return false;
//                         if (creation.content.type) return true;
//                         return false
//                     }
//                 },
//                 {
//                     name: "format",
//                     content: <AOAFormatSelect />,
//                     fulfilled: (creation: any) => {
//                         if (creation.content.format) return true;
//                         return false;
//                     }
//                 },
//                 {
//                     name: "mediaType",
//                     content: <AOAMediaTypeSelect />,
//                     fulfilled: (creation: any) => {
//                         if (creation.content["media-type"]) return true;
//                         return false;
//                     }
//                 },
//                 {
//                     name: "editor",
//                     content: <EditorAOA />,
//                     fulfilled: (creation: any) => false
//                 }
//             ]}
//         />
//     )

// }

// export const EditorLeadAdWithStages = (props: any) => {
//     return (
//         <EditorWithStages
//             stages={[
//                 {
//                     name: "type",
//                     content: <AOAMainTypeSelect />,
//                     fulfilled: (creation: any) => {
//                         if (!creation) return false;
//                         if (!creation.content) return false;
//                         if (creation.content.type) return true;
//                         return false
//                     }
//                 },
//                 {
//                     name: "format",
//                     content: <AOAFormatSelect />,
//                     fulfilled: (creation: any) => {
//                         if (creation.content.format) return true;
//                         return false;
//                     }
//                 },
//                 {
//                     name: "mediaType",
//                     content: <AOAMediaTypeSelect />,
//                     fulfilled: (creation: any) => {
//                         if (creation.content["media-type"]) return true;
//                         return false;
//                     }
//                 },
//                 {
//                     name: "editor",
//                     content: <EditorLeadAd />,
//                     fulfilled: (creation: any) => false
//                 }
//             ]}
//         />
//     )
// }
