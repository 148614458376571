import { Input, Space } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton"
import { Text } from "components/common/StandardText";

import { cloneDeep, set } from "lodash";

import { GenerateTextFromMedia } from "modules/panel/state/AISlice";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import { creationSelector, creationsSlice, patchCreation } from "tools/creationEditor/creationsSlice";

type GenerateFromMediaProps = {
    creationId: string,
    onSelect?: (text: string) => void,
}


const debugOptions =   [{
    "title": "Odkryj, jak catering human grade zmienia życie Twojego pupila",
    "description": "🚀 Zaskakujące korzyści zdrowego żywienia dla psów, o których prawdopodobnie nie miałeś pojęcia! \n\n✅ Zdrowie Twojego psa na pierwszym miejscu – catering human grade to gwarancja najwyższej jakości składników, które wspierają zdrowie i kondycję Twojego pupila.\n\n✅ Wygoda i oszczędność czasu – nie musisz się martwić o codzienne przygotowywanie posiłków, my zrobimy to za Ciebie.\n\n✅ Personalizacja diety – dostosowujemy posiłki do indywidualnych potrzeb Twojego psa, uwzględniając alergie i preferencje smakowe.\n\nOdkryj, jak catering human grade może zrewolucjonizować sposób, w jaki dbasz o swojego psa. \n\n🔽 Nie czekaj – kliknij i dołącz do grona zadowolonych właścicieli psów!",
    "comments": [
        {
            "text": "🚀 Zaskakujące korzyści zdrowego żywienia dla psów, o których prawdopodobnie nie miałeś pojęcia!",
            "explanation": "🔍 [Aspiracje klientów: zdrowy i szczęśliwy pies] – Nagłówek przyciąga uwagę, podkreślając nieoczywiste korzyści, co odpowiada aspiracji klientów do zapewnienia zdrowia swojemu psu."
        },
        {
            "text": "✅ Zdrowie Twojego psa na pierwszym miejscu – catering human grade to gwarancja najwyższej jakości składników, które wspierają zdrowie i kondycję Twojego pupila.",
            "explanation": "🔍 [Potrzeby klientów: zdrowie psa] – Fragment odpowiada na kluczową potrzebę klientów, wskazując na jakość i korzyści zdrowotne cateringu human grade."
        },
        {
            "text": "✅ Wygoda i oszczędność czasu – nie musisz się martwić o codzienne przygotowywanie posiłków, my zrobimy to za Ciebie.",
            "explanation": "🔍 [Potrzeby klientów: wygoda] – Odpowiada na potrzebę klientów dotyczącą oszczędności czasu i wygody, co jest istotnym elementem oferty."
        },
        {
            "text": "✅ Personalizacja diety – dostosowujemy posiłki do indywidualnych potrzeb Twojego psa, uwzględniając alergie i preferencje smakowe.",
            "explanation": "🔍 [Potrzeby klientów: personalizacja diety] – Fragment wskazuje na możliwość dostosowania diety do indywidualnych potrzeb psa, co jest ważne dla właścicieli."
        },
        {
            "text": "Odkryj, jak catering human grade może zrewolucjonizować sposób, w jaki dbasz o swojego psa.",
            "explanation": "🔍 [Zachowania klientów: nowe potrzeby dietetyczne] – Zachęta do odkrycia nowego podejścia do żywienia psa, odpowiadająca na zmieniające się potrzeby klientów."
        },
        {
            "text": "🔽 Nie czekaj – kliknij i dołącz do grona zadowolonych właścicieli psów!",
            "explanation": "🔍 [Call to Action] – Wezwanie do działania, które ma na celu zachęcenie klientów do skorzystania z oferty, poprzez kliknięcie i dołączenie do społeczności zadowolonych klientów."
        }
    ]
}]

function textToJSX(text:string) {
    // Split the text into lines using line breaks
    if (!text) return null;
    if (typeof text !== "string") return null;
    if (text.length === 0) return null;

    const lines = text.split('\n');

    // Return an array of JSX elements
    return lines.map((line, index) => {
        const trimmedLine = line.trim();
        if (trimmedLine) {
            return <p key={index}>{trimmedLine}</p>;
        }
        return null;
    });
}
const GenerateFromMedia = (props: any) => {

    const generateCall = useAPI(GenerateTextFromMedia(props.creationId))
    const creation = useSelectorWithParams([creationSelector, props.creationId])
    const patchCreationCall = useAPI(patchCreation(creation.id))

    const handleGenerate = () => {
        generateCall.call()
    }

    const handleSelect = (suggestion: any) => {
        props.onSelect && props.onSelect(suggestion.description);
        patchCreationCall.call({
            body: {
                content: {...cloneDeep(creation.content), comment: suggestion.comments},
            }
        })
    }

    const mappedOptions = useMemo(() => {

        if (!creation) return null;
        if (!creation.content?.aiSuggestions) return null;

        //return debugOptions.map((suggestion: any) => ( 
        return creation?.content?.aiSuggestions?.map((suggestion: any) => (
        <div style={{
                padding: "5px",
                position: "relative",
            }}
                className="standard-border"
            >
                <div>
                    {textToJSX(suggestion.description)}
                </div>
                <div style={{
                    position: "absolute",
                    bottom: "-4px",
                    right: "-4px",
                }}>
                    <StandardButton 
                        type={"primary"}
                        size="small"
                        style={{
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                        }}
                        onClick={() => handleSelect(suggestion)} 
                        >
                            <FormattedMessage 
                                id={"tools.textEditor.replaceFromLexicon.useButton.label"} 
                                defaultMessage={"Use"}                    
                            />
                    </StandardButton>
                </div>
            </div>
        ))


    },[creation])

    return (
            <Space direction="vertical">
            <Text>
                <FormattedMessage
                    id="tools.textEditor.generateFromMedia.description"
                    defaultMessage="Click generate to get suggestions for this creation from the AI, based on the media used to create it."
                />
            </Text>
            {mappedOptions}
            <StandardButton 
                onClick={handleGenerate}
                loading={generateCall.loading}
                type="primary"
            >
                <FormattedMessage
                    id="tools.textEditor.generateFromMedia.useButton.label"
                    defaultMessage="Generate from Media"
                />
            </StandardButton>
            </Space>
    )

}

export default GenerateFromMedia;