import { useEffect } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CallAppReview, GetReviews, ProjectReviewsSelector } from "state/callAppSlice";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import FormattedMessage from "components/common/FormattedMessage";


const Reviews = () => {
    const reviewsCall = useAPI(GetReviews, true);
    const reviews = useSelectorWithParams<CallAppReview[]>(ProjectReviewsSelector);

    const columns: ColumnsType<CallAppReview> = [
        {
            title: <FormattedMessage id="modules.call.reviews.columns.name" defaultMessage="Name" />,
            dataIndex: 'name',
            key: 'name',
            width: 150,
            sorter: (a, b) => (a?.name || '').localeCompare(b?.name || '')
        },
        {
            title: <FormattedMessage id="modules.call.reviews.columns.rating" defaultMessage="Rating" />,
            dataIndex: 'int_stars',
            key: 'rating',
            width: 100,
            render: (stars: number) => "⭐".repeat(stars),
            sorter: (a, b) => (a?.int_stars || 0) - (b?.int_stars || 0)
        },
        {
            title: <FormattedMessage id="modules.call.reviews.columns.review" defaultMessage="Review" />,
            dataIndex: 'review',
            key: 'review',
            width: 400,
            render: (text: string | null) => text || '-'
        },
        {
            title: <FormattedMessage id="modules.call.reviews.columns.phone" defaultMessage="Phone" />,
            dataIndex: 'phone',
            key: 'phone', 
            width: 120,
            ellipsis: true
        },
        {
            title: <FormattedMessage id="modules.call.reviews.columns.email" defaultMessage="Email" />,
            dataIndex: 'email',
            key: 'email',
            width: 120,
            ellipsis: true
        },
        {
            title: <FormattedMessage id="modules.call.reviews.columns.date" defaultMessage="Date" />,
            dataIndex: 'created_at',
            key: 'created_at',
            width: 120,
            render: (date: string) => new Date(date).toLocaleDateString(),
            sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        }
    ];

    const tableData = reviews ? Object.values(reviews).map(review => ({
        ...review,
        key: review.id
    })) : [];

    return (
        <Table 
            columns={columns}
            dataSource={tableData}
            loading={reviewsCall.loading}
            pagination={{
                pageSize: 50,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} reviews`
            }}
            scroll={{ x: 890 }}
        />
    );
}

export default Reviews;
