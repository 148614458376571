import { Tabs, Input } from 'antd';
import { type TabsProps } from 'antd';
import './translation-input.less';

interface TranslationInputProps {
  languages: string[];
  value?: Record<string, string>;
  onChange?: (value: Record<string, string>) => void;
  onBlur?: (value: Record<string, string>) => void;
}

const TranslationInput = ({ languages, value = {}, onChange, onBlur }: TranslationInputProps) => {
  const handleChange = (lang: string, text: string) => {
    const newValue = { ...value, [lang]: text };
    onChange?.(newValue);
  };

  const handleBlur = (lang: string) => {
    onBlur?.(value);
  };

  const items: TabsProps['items'] = languages.map((lang) => ({
    key: lang,
    label: lang.toUpperCase(),
    children: (
      <Input.TextArea
        value={value?.[lang] || ''}
        onChange={(e) => handleChange(lang, e.target.value)}
        onBlur={() => handleBlur(lang)}
        rows={4}
      />
    ),
  }));

  return <Tabs items={items} className='translation-input' />;
};

export default TranslationInput;
