import { createSelector, createSlice } from "@reduxjs/toolkit";
import { Keyword } from "components/keywords/KeywordsConfig";
import { callAPIProps } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { RootState } from "services/store/redux-store";
import { currentProjectIdSelector } from "services/store/scopeSlice";

export type Smart = {
    id: number;
    project_id: number;
    require_interests: string;
    website: string;
    initial_keywords: {
        initial_keyword: string;
        remarketing_keyword: string;
        force_interests_keyword: string;
        housing_keyword: string;
        housing_remarketing_keyword: string;
        employment_keyword: string;
        employment_remarketing_keyword: string;
        credit_keyword: string;
        credit_remarketing_keyword: string;
        politics_keyword: string;
        politics_remarketing_keyword: string;
        gambling_keyword: string;
        gambling_remarketing_keyword: string;
        google_republish: string;
        auto_promotion: [];
    };
    remarketing_time: number;
    currency: string;
    created_at: string;
    updated_at: string;
    logo_url: string;
    smb: {
        status: string;
        is_tos: number;
        status_reason: string;
        tos_links: {
            [key: number]: string;
        };
    }
    link_daily_advertising_budget: number;
    link_period: number;
    link_optimization?: string;
    message_daily_advertising_budget: number;
    message_period: number;
    standard_daily_advertising_budget: number;
    standard_period: number;
    standard_optimization?: string;
    video_daily_advertising_budget: number;
    video_period: number;
    video_optimization?: string;
    google_display_ad_daily_budget: number;
    google_display_ad_period: number;
    has_facebook_pixel?: string;
    has_fans?: string;
    has_website?: string;
    page_exists?: string;
    pixel_exists?: string;
    search_keywords: string[];
    gender: string;
    age_from: number;
    age_to: number;
    status: string;
    initial_keyword: string;
    remarketing_keyword?: string;
    housing_keyword?: string;
    employment_keyword?: string;
    credit_keyword?: string;
    politics_keyword?: string;
    auto_promotion: [];
    smarts_geo_points: SmartGeoPoint[];
    google_ads_settings: {
        search_interests?: {}
        gender: string;
        age_from: number;
        age_to: number;
        display_ad_period: number;
        display_ad_daily_budget: number;
        search_ad_default_daily_budget: number;
        search_keywords: [];
        location_targeting: {
            country_code?: string
            geo_points: SmartGeoPoint[]
        }
        google_keywords: [];
    };
    google_smart_settings: {
        id: number;
        website_url?: string;
        reply_message?: string;
        creation_date?: string;
        negative_keywords_resource_name?: string;
        negative_keywords_to_add?: string;
        last_ai_run?: string;
        keywords_seed?: string[];
        semrush_competitive_domains?: [];
        headlines?: string[];
        pinned_headline?: string;
        descriptions?: string;
        short_descriptions?: string;
        square_images?: [];
        horizontal_images?: [];
        videos?: [];
        shotstack_id?: string;
        accepted_invitation: number;
        configured_payment: number;
        valid_console: string;
        industry_id?: number;
        smart_id: number;
        created_at: string;
        updated_at: string;
        updated?: any;
        currency?: string;
        initialized: number;
        changed?: boolean;
        use_ad_schedule: number;
        company_category?: [];
        working_hours: [];
        logo_url: string;
        start_date?: string;
        phones: [];
        keywords: [];
        competitors_websites?: string[];
        general_budget: number;
        smart_settings_phones: [];
        industry?: string;
    };
    facebook_ads_settings: {
        locales?: number[];
        has_fans?: string;
        has_website?: string;
        has_facebook_pixel?: string;
        new_market: string;
        page_exists?: string;
        pixel_exists?: string;
        audience_interest: {
            type: string;
            label: string;
            id: string;
            group: string;
            name: string;
        }[];
        gender: string;
        age_from: number;
        age_to: number;
        standard_period: number;
        standard_optimization?: string;
        link_period: number;
        link_optimization?: string;
        video_period: number;
        video_optimization?: string;
        message_period: number;
        campaign_name_template: string;
        standard_daily_advertising_budget: number;
        link_daily_advertising_budget: number;
        video_daily_advertising_budget: number;
        message_daily_advertising_budget: number;
        location_targeting: {
            country_code?: string
            geo_points: SmartGeoPoint[]
        }
    }
}

export type SmartGeoPoint = {
    id: number;
    smart_id: number;
    type: string;
    provider: string;
    data: {
        attributes?: {
            key: string;
            name: string;
            type: string;
            country_code: string;
            country_name: string;
            region: string;
            region_id: number;
            supports_region: true;
            supports_city: true;
            id: string;
        };
        address_component?: {
            long_name: string;
            short_name: string;
            types: string[];
        }[];
        formatted_address?: string;
        geometry?: {}
        place_id?: string,
        types?: string[]
    }
    area: number;
    address: string;
    city: string;
    country: string;
    facebook_key: string;
    facebook_name: string;
    latitude: number;
    longitude: number;
    radius: number;
    created_at: string;
    updated_at: string;
}

type AudienceEstimate = {
    estimate_dau: number,
    estimate_mau: number,
    estimate_mau_lower_bound: number,
    estimate_mau_upper_bound: number,
}

type SmartState = {
    list: Record<string, Smart>,
    estimate: Record<string, AudienceEstimate>,
    interestEstimates: Record<string, AudienceEstimate>,
    keywords: {
        behaviors: Keyword[],
        interests: Keyword[],
    }
};

const initialState: SmartState = {
    list: {},
    estimate: {},
    interestEstimates: {},
    keywords: {
        behaviors: [],
        interests: [],
    }
}

export const smartSlice = createSlice({
    name: "smart",
    initialState,
    reducers: {
        get: (state, action) => {
            state.list[action.payload.projectId] = { ...state.list[action.payload.projectId], ...action.payload.data };
        },
        updateFacebookAdsSettings: (state, action) => {
            state.list[action.payload.projectId].facebook_ads_settings = action.payload.data;
        },
        updateGoogleAdsSettings: (state, action) => {
            state.list[action.payload.projectId].google_ads_settings = action.payload.data;
        },
        updateGoogleSmartSettings: (state, action) => {
            state.list[action.payload.projectId].google_smart_settings = action.payload.data;
        },
        getBehaviors: (state, action) => {
            state.keywords.behaviors = action.payload.data && action.payload.data.map((b: any) => ({
                ...b,
                lower_bound: b.audience_size_lower_bound,
                upper_bound: b.audience_size_upper_bound,
            }));
        },
        getInterests: (state, action) => {
        },
        getEstimate: (state, action) => {
            state.estimate[action.payload.projectId] = action.payload.data;
        },
        updateForsantBooleanParameter: (state, action) => {
            if (action.payload.additional?.projectId) {
                state.list[action.payload.additional.projectId].google_smart_settings[action.payload.additional.paremeter] = true
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase("LOAD_USER_SUCCESS", (state, action) => {
                if (action?.payload?.projects) {
                    Object.values(action.payload.projects).forEach((p: any) => {
                        if (p.smart) {
                            delete p.smart.project;

                            state.list[p.id] = p.smart;
                        }
                    })
                }
            })
    }
});


export const UpdateSmart: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}`, 2),
    method: "PATCH",
    successDispatch: smartSlice.actions.get,
}

export const UpdateFacebookAdsSettings: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/facebook-ads-settings`, 2),
    method: "PATCH",
    successDispatch: smartSlice.actions.updateFacebookAdsSettings,
}

export const UpdateGoogleAdsSettings: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/google-ads-settings`, 2),
    method: "PATCH",
    successDispatch: smartSlice.actions.updateGoogleAdsSettings,
}

export const UpdateGoogleSmartSettings: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/google-smart-settings`, 2),
    method: "PATCH",
    successDispatch: smartSlice.actions.updateGoogleSmartSettings,
};

export const RecreateGoogleSmartSettings: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/recreate`, 2),
    method: "POST",
};

export const VerifyAdsInvitation = (): callAPIProps => {
    const projectId = useSelectorWithParams(currentProjectIdSelector);
    return {
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/check-role`, 2),
        method: "GET",
        successDispatch: smartSlice.actions.updateForsantBooleanParameter,
        passToDispatcher: {
            projectId: projectId,
            paremeter: "accepted_invitation",
        },
        // fakeResponse: async (request) => ({
        //     status: 200,
        //     json: () => ({
        //         data: [

        //         ],
        //     }),
        // }),
    }
}

export const VerifyPaymentMethod = (): callAPIProps => {
    const projectId = useSelectorWithParams(currentProjectIdSelector);
    return {
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/check-payment`, 2),
        method: "GET",
        successDispatch: smartSlice.actions.updateForsantBooleanParameter,
        passToDispatcher: {
            projectId: projectId,
            paremeter: "configured_payment",
        },
        // fakeResponse: async (request) => ({
        //     status: 200,
        //     json: () => ({
        //         data: [

        //         ],
        //     }),
        // }),
    }
}

export const VerifyTrackingCode = (): callAPIProps => {
    const projectId = useSelectorWithParams(currentProjectIdSelector);
    return {
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/sites/verify`, 1),
        method: "GET",
        successDispatch: smartSlice.actions.updateForsantBooleanParameter,
        passToDispatcher: {
            projectId: projectId,
            paremeter: "valid_console",
        },
        // fakeResponse: async (request) => ({
        //     status: 200,
        //     json: () => ({
        //         data: [

        //         ],
        //     }),
        // }),
    }
}

export const GetInterests = (q: string): callAPIProps => {

    const query = {
        q: q,
        type: "query",
        group: "interests",
        ["page[size]"]: 150
    }

    return {
        url: ({ getApiUrl, serializeQuery }) => getApiUrl(`services/facebook/interests?${serializeQuery(query)}`),
        successDispatch: smartSlice.actions.getInterests,
    }
}

export const GetEstimate = (q?: any): callAPIProps => {

    const c: callAPIProps = {
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/estimate`, 2),
        method: "PUT",
        body: {
            ...q
        }
    }

    if (!q) {
        c.successDispatch = smartSlice.actions.getEstimate
    }

    return c;

}

export const GetBehaviors: callAPIProps = {
    url: ({ getApiUrl, serializeQuery }) => getApiUrl(`services/facebook/interests?${serializeQuery({ type: "query", group: "behaviors" })}`),
    successDispatch: smartSlice.actions.getBehaviors,
}

export const GetSmart: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`smart/${projectId}`, 2),
    successDispatch: smartSlice.actions.get,
}

export const GetFacebookAdsSettings: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/facebook-ads-settings`, 2),
    successDispatch: smartSlice.actions.get,
}


export const BehaviorsSelector = (state: RootState) => state?.smart?.keywords?.behaviors || [];

const SmartListSelector = (state: RootState) => state?.smart?.list || {};

export const SmartSelector = createSelector([
    currentProjectIdSelector,
    SmartListSelector
],
    (projectId, list) => {
        if (list === undefined) return null
        return list[projectId]
    }
)

export const EstimateSelector = createSelector([
    currentProjectIdSelector,
    (state) => state?.smart?.estimate || {}
],
    (projectId, estimate) => estimate[projectId] as AudienceEstimate || null
)

export const AllAdsAndRemarkettingKeywords = createSelector([
    SmartSelector
],
    (smart) => smart ? ({ initial_keywords: smart?.initial_keywords }) : null
)

export const CurrentAdsSettingsIdSelector = createSelector(
    SmartSelector,
    (smart) => (smart ? smart.id : null)
);
export const AdsSettingsCurrencySelector = createSelector(
    SmartSelector,
    (smart) => (smart ? smart.currency : null)
);

export const FacebookAdsSettingsSelector = createSelector(
    SmartSelector,
    (smart) => (smart ? smart.facebook_ads_settings : null)
);

export const GoogleAdsSettingsSelector = createSelector(
    SmartSelector,
    (smart) => {
        if (smart !== null && smart !== undefined) {
            return smart.google_ads_settings;
        } else {
            return null;
        }
    }
);

export const GoogleSmartSettingsSelector = createSelector(
    SmartSelector,
    (smart) => (smart ? smart.google_smart_settings : null)
);


export const FacebookKeywordsSelector = createSelector(
    FacebookAdsSettingsSelector,
    (facebookAdsSettings) => facebookAdsSettings ? facebookAdsSettings.audience_interest : false
);

export const AdsSettingsWebsite = createSelector(
    SmartSelector,
    (adsSettings) => (adsSettings ? adsSettings.website : null)
);

// requirements selectors

export const HasFacebookAudienceFilledSelector = createSelector(
    FacebookAdsSettingsSelector,
    (facebookAudience) => {
        if (
            facebookAudience &&
            facebookAudience.gender !== null &&
            facebookAudience.age_from !== null &&
            facebookAudience.age_to !== null &&
            facebookAudience.location_targeting.geo_points.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }
);

export const HasFacebookBudgetFilledSelector = createSelector(
    FacebookAdsSettingsSelector,
    (facebookBudget) => {
        if (
            facebookBudget &&
            facebookBudget.standard_daily_advertising_budget !== null &&
            facebookBudget.standard_period !== null &&
            facebookBudget.link_daily_advertising_budget !== null &&
            facebookBudget.link_period !== null &&
            facebookBudget.video_daily_advertising_budget !== null &&
            facebookBudget.video_period !== null &&
            facebookBudget.message_daily_advertising_budget !== null &&
            facebookBudget.message_period !== null
        ) {
            return true;
        } else {
            return false;
        }
    }
);

export const HasGoogleAudienceFilledSelector = createSelector(
    GoogleAdsSettingsSelector,
    (googleAudience) => {
        if (
            googleAudience &&
            googleAudience !== null &&
            googleAudience.gender !== null &&
            googleAudience.age_from !== null &&
            googleAudience.age_to !== null &&
            googleAudience.location_targeting.geo_points.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }
);

export const HasBusinessSurveyAdsSettingsFilledSelector = createSelector(
    SmartSelector,
    (adsSettings) => {
        if (adsSettings && adsSettings.website !== null && adsSettings.initial_keyword !== null) {
            return true
        } else {
            return false
        }
    }
);



export const HasGoogleBudgetFilledSelector = createSelector(
    GoogleAdsSettingsSelector,
    (googleBudget) => {
        if (
            googleBudget &&
            googleBudget.display_ad_daily_budget !== null &&
            googleBudget.display_ad_period !== null &&
            googleBudget.search_ad_default_daily_budget !== null
        ) {
            return true
        } else {
            return false
        }
    }
);

export const HasGoogleKeywordsFilledSelector = createSelector(
    GoogleAdsSettingsSelector,
    (googleAudience) => googleAudience ? googleAudience.search_keywords : false
);

export const HasWebsiteFilledSelector = createSelector(
    SmartSelector,
    (website) => website !== null && website !== ""
);

export const HasFilledKeywordsSeedSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.keywords_seed)
);

export const HasUserSmartProject = createSelector(
    SmartSelector,
    (adsSettings) => !!adsSettings
);


export const MediaSelector = createSelector(
    [
        GoogleSmartSettingsSelector,
        (state, field) => field
    ],
    (adsSettings, field) => adsSettings && adsSettings[field]
);

export const VideoSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => {
        if (adsSettings) return adsSettings.videos

    }
)

export const HasFilledBusinessDetailsSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) =>
        !!(adsSettings &&
            adsSettings.company_category &&
            adsSettings.website_url &&
            adsSettings.phones &&
            adsSettings.start_date)
);

export const HasFilledOpeningHoursSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.working_hours && adsSettings.working_hours.length >= 1)
);

// export const HasFilledKeywordsSeedSelector = createSelector(
//     GoogleSmartSettingsSelector,
//     (adsSettings) => !!(adsSettings && adsSettings.keywords_seed)
// );

export const HasFilledAdsAudienceSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) =>
        !!(adsSettings && adsSettings.keywords_seed && adsSettings.competitors_websites)
);

export const HasFilledCompetitorsWebsitesSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.competitors_websites && adsSettings.competitors_websites.length >= 1)
);

export const HasFilledKeywordIdeasSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.keywords && adsSettings.keywords.length >= 1)
);

export const HasFilledHeadlinesSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.headlines && adsSettings.headlines.length >= 6)
);

export const HasFilledShortDescriptionsSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.short_descriptions && adsSettings.short_descriptions.length >= 3)
);

export const HasFilledLongDescriptionsSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.descriptions && adsSettings.descriptions.length >= 3)
);

export const HasFilledDisplayAdsMediaHorizontalSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.horizontal_images)
);

export const HasFilledDisplayAdsMediaSquareSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.square_images)
);

export const HasFilledVideoAdsMediaSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.videos)
);

export const HasFilledBudgetSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) =>
        !!(adsSettings &&
            adsSettings.general_budget &&
            adsSettings.currency)
);

export const HasVerifiedAdsInvitationSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.accepted_invitation)
);

export const HasVerifiedPaymentMethodSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => !!(adsSettings && adsSettings.configured_payment)
);

export const HasVerifiedTrackingCodeSelector = createSelector(
    GoogleSmartSettingsSelector,
    (adsSettings) => {
        if (!adsSettings) return false;
        if (!adsSettings.valid_console) return false;
        let vc = adsSettings.valid_console;
        if (typeof vc === "boolean") return vc;
        if (vc === "valid" || vc === "skipped") return true;
        return false;
    }
);

