import useAPIWithIncrementsNoPages from "services/hooks/useAPIWithIncrementsNoPages";
import { Creation, FilterCreation, creationsAsArraySelector, creationsSelector, filteredCreationsAsArraySelector, filteredCreationsCountSelector, getCreationsWithQuery } from "./creationsSlice"
import { useEffect, useMemo, useState } from "react";
import InfinityListQuery, { InfinityListDateRangePicker } from "tools/infinityList/infinityListQuery";
import InfinityList from "tools/infinityList/infinityList";
import { InfinityColumnProps, columnSize } from "tools/infinityList/infinityTemplates";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import FormattedMessage from "components/common/FormattedMessage";
import TemplateRow from "tools/infinityList/TemplateRow";
import { CreationColumns, CreationHeaders } from "./CreationListConfig";
import { debugElementProps, sortAssetsByDate } from "services/helpers/js-helpers";
import CreationOpenQueue from "./draftQueue/CreationOpenQueue";
import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import { Col, Row, Select } from "antd";
import InfinityListAdvancedSettings from "tools/infinityList/infinityListAdvancedSettings";
import { userSettingsInterfaceCreationListSelector, userSettingsInterfaceSelector } from "state/user/userSettingsSlice";
import { CreationListAdvancedSettings } from "./CreationConfig";
import InfinityListSortSettings, { SortSettingsCombined, SortSettingsCreatedAt, SortSettingsPublishAt, SortSettingsUpdatedAt } from "tools/infinityList/InfinityListSortSettings";
import dayjs, { Dayjs } from "dayjs";
import { CreationActionNew } from "./editorTools/CreationMainActions";
import AICampaignGenerationModal from "modules/smart/components/AIGeneration/AICampaignGenerationForm";
import { InfinityListResetFilters } from "tools/infinityList/infinityListResetFilters";
import Toolbar from "components/toolbar/Toolbar";
import InfinityListAdvancedSettingsCheckboxs from "tools/infinityList/infinityListAdvancedSettingsCheckboxs";

type CreationsQuery = {
    status?: string,
    sorty: "-created_at" | "created_at",
    createdFrom?: string,
    createdTo?: string,
    updatedFrom?: string,
    updatedTo?: string,
}

const getQueryDatesBasedOnSort = (sort: string, start: Dayjs, end: Dayjs) => {
    switch (sort) {
        case "-created_at":
        case "created_at":
            return {
                createdFrom: start.format("YYYY-MM-DD"),
                createdTo: end.format("YYYY-MM-DD"),
            }
        case "-updated_at":
        case "updated_at":
            return {
                updatedFrom: start.format("YYYY-MM-DD"),
                updatedTo: end.format("YYYY-MM-DD"),
            }
        case "-publish_at":
        case "publish_at":
            return {
                publishFrom: start.format("YYYY-MM-DD"),
                publishTo: end.format("YYYY-MM-DD"),
            }
        case "-combined":
        case "combined":
            return {
                combinedFrom: start.format("YYYY-MM-DD"),
                combinedTo: end.format("YYYY-MM-DD"),
            }
        default:
            return {
                createdFrom: start.format("YYYY-MM-DD"),
                createdTo: end.format("YYYY-MM-DD"),
            }
    }
}

const CreationsInfinityList = () => {
    const [query, setQuery] = useState<any>();
    const creationSettings = useSelectorWithParams(userSettingsInterfaceCreationListSelector);
    const filteredCreationsCount = useSelectorWithParams(filteredCreationsCountSelector)
    const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
        callAPI: getCreationsWithQuery,
        query: query
    })
    const [dateRange, setDateRange] = useState<{
        from: Dayjs,
        to: Dayjs,
    }>({
        from: dayjs().subtract(1, "week"),
        to: dayjs().add(1, "week"),
    });
    const creations = useSelectorWithParams(filteredCreationsAsArraySelector({ ...creationSettings, from: dateRange?.from, to: dateRange?.to }));

    const GetCreations = () => {
        if (!query) return;
        if (Object.keys(query).length === 0) return;
        startWithNewQuery(query);
    }

    const StartOver = useCallAfterUpdate(GetCreations);

    useEffect(() => {
        if (!query) return;
        if (Object.keys(query).length === 0) return;
        StartOver();
    }, [query])

    useEffect(() => {
        //console.log('creationSettings', creationSettings);
        //console.log("dateRange", dateRange.from.format(), dateRange.to.format());

        const sort = creationSettings.sort || "created_at";

        const normalizedQuery: any = {
            ...getQueryDatesBasedOnSort(sort, dayjs(dateRange.from), dayjs(dateRange.to)),
            "sort": sort,
        }
        setQuery(normalizedQuery);
    }, [creationSettings, dateRange]);

    const renderCreation = (creation: Creation) => {
        return (
            <div
                className="infinity-item-wrapper standard-border with-standard-border"
                {...debugElementProps(creation)}
            >
                <TemplateRow size="medium" item={creation} columns={CreationColumns} />
            </div>
        )
    }

    const sortedAndFilteredCreations = useMemo(() => {
        if (!creations) return [];

        let filtered: any[] = [];

        creations.forEach((creation: Creation) => {
            const index = FilterCreation(creation, creationSettings);
            if (index !== null) filtered.push(index);
        });

        if (query?.sort) {
            const fieldName = query.sort.replace("-", "");
            const direction = (query.sort as string).startsWith("-") ? true : false
            return sortAssetsByDate<any>(filtered, direction, fieldName)
        }

        return filtered;
    }, [creations, creationSettings])

    return (
        <div className="infinity-wrapper">
            <Toolbar
                width="280px"
                title={(
                    <FormattedMessage
                        id="panel.components.creation.list.creations"
                        defaultMessage="Creations"
                    />
                )}
                smallToolbar={(
                    <>
                        <Toolbar.Spacer />
                        <InfinityListQuery
                            onChange={() => null}
                            onReload={StartOver}
                            sort={false}
                            date={false}
                            refresh={false}
                        >
                            <InfinityListDateRangePicker
                                value={[dateRange.from, dateRange.to]}
                                onChange={(dates) => setDateRange({ from: dates[0], to: dates[1] })}
                            />
                        </InfinityListQuery>
                    </>
                )}
                largeToolbar={(<>
                    <Toolbar.Spacer />
                    <InfinityListQuery
                        onChange={() => null}
                        onReload={StartOver}
                        sort={false}
                        date={false}
                    >
                        <InfinityListResetFilters filteredItems={filteredCreationsCount} resetSettings={'creationList'}/>
                        <CreationActionNew />
                        <InfinityListAdvancedSettings settings={CreationListAdvancedSettings} />
                        <DisplayIfHasPermission permission={"use module smart ai generate"}>
                            <AICampaignGenerationModal />
                        </DisplayIfHasPermission>
                        <DisplayIfHasPermission permission={"use module smart ai generate"}>
                            <CreationOpenQueue />
                        </DisplayIfHasPermission>
                        <InfinityListSortSettings
                            sortOptions={[...SortSettingsCombined, ...SortSettingsCreatedAt, ...SortSettingsUpdatedAt, ...SortSettingsPublishAt]}
                        />
                        <InfinityListDateRangePicker
                            value={[dateRange.from, dateRange.to]}
                            onChange={(dates) => setDateRange({ from: dates[0], to: dates[1] })}
                        />
                    </InfinityListQuery>
                </>
                )}
                drawerToolbar={(<>
                    <InfinityListQuery
                        onChange={() => null}
                        onReload={StartOver}
                        sort={false}
                        date={false}
                        refresh={false}
                    >
                        <Row style={{ width: "100%" }}>
                            <InfinityListAdvancedSettingsCheckboxs settings={CreationListAdvancedSettings} />
                        </Row>
                        <Row>
                            <CreationActionNew inDrawer={true}/>
                        </Row>
                        <DisplayIfHasPermission permission={"use module smart ai generate"}>
                            <AICampaignGenerationModal />
                        </DisplayIfHasPermission>

                        <DisplayIfHasPermission permission={"use module smart ai generate"}>
                            <CreationOpenQueue />
                        </DisplayIfHasPermission>

                        <InfinityListSortSettings
                            sortOptions={[...SortSettingsCreatedAt, ...SortSettingsUpdatedAt, ...SortSettingsPublishAt]}
                        />

                        <Row>
                            <InfinityListDateRangePicker
                                value={[dateRange.from, dateRange.to]}
                                onChange={(dates) => setDateRange({ from: dates[0], to: dates[1] })}
                            />
                        </Row>
                    </InfinityListQuery>
                </>
                )}
            />

            <InfinityList
                headers={CreationHeaders}
                items={creations}
                loading={loading}
                //loadMoreData={handleLoadNextPage}
                itemSkeleton={() => "skeleton"}
                renderItem={renderCreation}
            />
        </div >
    )


}

export default CreationsInfinityList;